<template>
  <!-- Contrat de travail -->
  <div
    id="input-group-contrat-travail"
    role="group"
    class="col-12 col-md-6"
  >
    <label
      class="form-label d-block"
      for="select-contrat-travail"
    >
      {{ $t('tenants.informations.workContractType') }} <span class="text-danger">*</span>
    </label>

    <select
      v-if="contratTravailOptions"
      id="select-contrat-travail"
      v-model="individu.contratTravail"
      class="form-select"
      name="contratTravail"
      required
    >
      <option
        v-for="contratTravail in contratTravailOptions"
        :key="contratTravail.id"
        :value="contratTravail.id"
      >
        {{ contratTravail.libelle }}
      </option>
    </select>
  </div>

  <!-- Situation socio-professionnelle -->
  <div
    id="input-group-sociopro"
    role="group"
    class="col-12 col-md-6"
  >
    <template
      v-if="situationSocioproOptions"
    >
      <label
        class="form-label d-block"
        for="select-sociopro"
      >
        {{ $t('tenants.informations.professionalSituation') }} <span class="text-danger">*</span>
      </label>

      <select
        id="select-situation-sociopro"
        v-model="individu.situationSociopro"
        class="form-select"
        name="situationSociopro"
        :disabled="!situationSocioproOptions"
        required
      >
        <option
          v-for="situationSociopro in situationSocioproOptions"
          :key="situationSociopro.id"
          :value="situationSociopro.id"
        >
          {{ situationSociopro.libelle }}
        </option>
      </select>
    </template>
  </div>

  <template
    v-if="
      getContratTravailById(individu?.contratTravail)
        ?.needEmployeur
    "
  >
    <!-- Employeur -->
    <div
      id="input-group-nom-employeur"
      class="col-12 col-md-6"
      role="group"
    >
      <label
        for="input-nom-employeur"
        class="form-label d-block"
      >
        {{ $t('tenants.informations.employer') }} <span class="text-danger">*</span>
      </label>

      <input
        id="input-nom-employeur"
        v-model.trim="individu.nomEmployeur"
        v-amsom-formater-upper-case
        class="form-control"
        name="nomEmployeur"
        type="text"
        maxlength="30"
        :placeholder="$t('tenants.informations.employerPlaceholder')"
        required
        aria-required="true"
      >
    </div>
  </template>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "LocataireSituationSocioProBlock",

  components: {
  },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },

  emits: ["update:modelValue"],

  data() {
    return {
      individu: this.modelValue,
    };
  },

  computed: {
    ...mapState(['utilities']),

    ...mapGetters({
      getContratTravailById: "utilities/getContratTravailById",
    }),

    contratTravailOptions() {
      return this.utilities.contratTravail;
    },

    situationSocioproOptions() {
      if (
        !this.utilities.situationSociopro ||
        !this.individu?.contratTravail
      )
        return null;

      return this.utilities.situationSociopro.filter((situation) => {
        return (
          situation.idSne ===
          this.getContratTravailById(this.individu?.contratTravail)
            ?.idSne
        );
      });
    },
  },

  watch: {
    individu: {
      handler(value) {
        this.$emit("update:modelValue", value);
      },

      deep: true,
    },

    "individu.contratTravail": function (newValue) {
      if (!this.getContratTravailById(newValue)?.needEmployeur) {
        this.individu.nomEmployeur = "";
      }
    },

    // modelValue: {
    //   handler(value) {
    //     this.individu = value;
    //   },

    //   deep: true,
    // },
  },
};
</script>
