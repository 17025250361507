<template>
  <div
    id="edition-locataire"
    class="ps-3"
  >
    <amsom-overlay :loading="locatairePanel.loading > 0">
      <form @submit.prevent="updateIndividu">
        <div class="row gy-3 gx-3 text-start justify-content-start">
          <locataire-etat-civil-block
            v-model="editedLocataire"
            :show-lieu-naissance="!locataire?.lieuNaissance || !locataire.idIndividu"
            :need-pj-etat-civil="isNeededPjEtatCivil(locataire, editedLocataire)"
            @file-loading="updateFileLoading"
          />

          <template v-if="isMajeur(editedLocataire)">
            <hr class="w-75 col-12">

            <locataire-contact-block
              v-model="editedLocataire"
            />

            <hr class="w-75 col-12">

            <locataire-situation-socio-pro-block
              v-model="editedLocataire"
            />
          </template>

          <hr class="w-75 col-12">

          <locataire-situation-familiale-block
            v-model="editedLocataire"
            :need-pj-lien-parente="isNeededPjLienParente(editedLocataire)"
            @file-loading="updateFileLoading"
            @conflict-lien-parente-situation="updateConflictLienParenteSituation"
          />

          <hr class="w-75 col-12">

          <locataire-cmi-block
            v-model="editedLocataire"
            :need-pj-cmi="editedLocataire.carteInvalidite"
            @file-loading="updateFileLoading"
          />

          <template v-if="getTypeLocataire(editedLocataire) === 'occupant'">
            <hr class="w-75 col-12">

            <locataire-responsabilite-block v-model="editedLocataire" />
          </template>

          <div class="col-12 text-end mt-4">
            <button
              id="btn-mettre-a-jour-individu"
              type="submit"
              class="btn btn-success rounded-5"
              :disabled="
                fileLoadingCount !== 0 || isConflictLienParenteSituation
              "
            >
              {{ locatairePanel.mode === "create" ? $t('common.create') : $t('common.update') }}
              <font-awesome-icon
                :icon="['fas', 'arrow-right']"
                class="ms-1"
              />
            </button>
          </div>
        </div>
      </form>
    </amsom-overlay>
  </div>
</template>

<script>
import { unixToDateString } from "@/js/text.js";
import { mapGetters, mapState } from "vuex";
import { toast } from "vue3-toastify";
import LocataireContactBlock from "@/components/Feat/IndividuFeeding/LocataireContactBlock.vue";
import LocataireEtatCivilBlock from "@/components/Feat/IndividuFeeding/LocataireEtatCivilBlock.vue";
import LocataireSituationSocioProBlock from "@/components/Feat/IndividuFeeding/LocataireSituationSocioProBlock.vue";
import LocataireSituationFamilialeBlock from "@/components/Feat/IndividuFeeding/LocataireSituationFamilialeBlock.vue";
import LocataireCmiBlock from "@/components/Feat/IndividuFeeding/LocataireCmiBlock.vue";
import LocataireResponsabiliteBlock from "@/components/Feat/IndividuFeeding/LocataireResponsabiliteBlock.vue";
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";

export default {
  name: "EditLocataireBlock",
  components: { LocataireContactBlock, LocataireEtatCivilBlock, LocataireSituationSocioProBlock, LocataireSituationFamilialeBlock, LocataireCmiBlock, LocataireResponsabiliteBlock, AmsomOverlay },

  props: {
    locataire: {
      type: Object,

      default: () => {
        return null;
      },
    },

    oldEditedLocataire: {
      type: Object,

      default: () => {
        return null;
      },
    },
  },

  data() {
    return {
      editedLocataire: {
        aCharge: true,
        dtgard: false,
        dtvis: false,
      },

      fileLoadingCount: 0,

      isConflictLienParenteSituation: false
    };
  },

  computed: {
    ...mapState(["locatairePanel", "contrat"]),

    ...mapGetters({
      isMajeur: "contrat/isMajeur",
      getTypeLocataire: "contrat/getTypeLocataire",
      isNeededPjEtatCivil: 'locataireFeeding/isNeededPjEtatCivil',
      isNeededPjLienParente: 'locataireFeeding/isNeededPjLienParente',
    }),

    currentContratInfos() {
      return this.contrat.currentContratInfos;
    },
  },

  mounted() {
    if (this.oldEditedLocataire) {
      Object.assign(this.editedLocataire, this.oldEditedLocataire);
    } else if (this.locataire) {
      this.editedLocataire = {};
      Object.assign(this.editedLocataire, this.locataire);
    }
  },

  created() {
    Object.assign(this.editedLocataire, this.locataire);
  },

  methods: {
    unixToDateString,

    updateFileLoading(value) {
      if (value) this.fileLoadingCount++;
      else this.fileLoadingCount--;
    },

    updateConflictLienParenteSituation(value){
      this.isConflictLienParenteSituation = value
    },

    // cancel() {
    //   if (this.locatairePanel.mode === 'create')
    //     this.$store.commit('locatairePanel/setMode', 'list')
    //   else
    //     this.$store.commit('locatairePanel/setMode', 'view')
    // },

    async updateIndividu() {
      let data = Object.assign({}, this.editedLocataire);
      if (!data.idContrat) data.idContrat = this.currentContratInfos.id;

      if (this.oldEditedLocataire?.idModification) {
        let cancelPayload = {
          data: {
            idModification: this.oldEditedLocataire.idModification,
          },
        };

        await this.$store.dispatch(
          "locatairePanel/cancelModificationIndividuContrat",
          cancelPayload
        );
      }

      this.$store
        .dispatch("locatairePanel/updateIndividuContrat", data)
        .then(() => {
          this.$store.dispatch(
            "contrat/loadIndividuInfosByContrat",
            data.idContrat
          );
          this.$store.commit("locatairePanel/setMode", "confirm");
        })
        .catch((error) => {
          console.log(error);
          toast.error(error);
        });
    },
  },
};
</script>
