<template>
  <!-- A charge -->
  <div class="d-inline-block col-12 px-3">
    <div class="form-check form-switch">
      <input
        id="checkbox-charge-fiscal"
        v-model="individu.aCharge"
        class="form-check-input"
        type="checkbox"
        name="aCharge"
      >

      <label
        for="checkbox-charge-fiscal"
        class="form-check-label active"
      >
        {{ $t('tenants.informations.isDependantDetailLong') }}
      </label>
    </div>

    <small class="text-muted form-text lh-sm d-block">
      {{ $t('tenants.informations.isDependantDetail') }}
    </small>
  </div>

  <template v-if="individu.lienParente === 'E'">
    <!-- Droit de garde -->
    <div class="d-inline-block col-12 px-3">
      <div class="form-check form-switch">
        <input
          id="checkbox-dtgarde"
          v-model="individu.dtgard"
          class="form-check-input"
          type="checkbox"
          name="dtgard"
        >

        <label
          for="checkbox-dtgarde"
          class="form-check-label active"
        >
          {{ $t('tenants.informations.isCustodyRightLong') }}
        </label>
      </div>

      <small class="text-muted form-text lh-sm d-block">
        {{ $t('tenants.informations.isCustodyRightDetail') }}
      </small>
    </div>

    <!-- Droit de visite -->
    <div class="d-inline-block col-12 px-3">
      <div class="form-check form-switch">
        <input
          id="checkbox-dtvis"
          v-model="individu.dtvis"
          class="form-check-input"
          type="checkbox"
          name="dtvis"
        >

        <label
          for="checkbox-dtvis"
          class="form-check-label active"
        >
          {{ $t('tenants.informations.isVisitingRightLong') }}
        </label>
      </div>

      <small class="text-muted form-text lh-sm d-block">
        {{ $t('tenants.informations.isVisitingRightDetail') }}
      </small>
    </div>
  </template>
</template>

<script>
export default {
  name: "LocataireResponsabiliteBlock",

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },

  emits: ["update:modelValue"],

  data() {
    return {
      individu: this.modelValue,
    };
  },

  watch: {
    individu: {
      handler(value) {
        this.$emit("update:modelValue", value);
      },

      deep: true,
    },

    // modelValue: {
    //   handler(value) {
    //     this.individu = value;
    //   },

    //   deep: true,
    // },


    "individu.dtvis": function (newValue) {
      if (newValue) {
        this.individu.dtgard = false;
        this.individu.aCharge = false;
      }
    },

    "individu.dtgard": function (newValue) {
      if (newValue) this.individu.dtvis = false;
    },

    "individu.aCharge": function (newValue) {
      if (newValue) this.individu.dtvis = false;
    }
  },
};
</script>
