<template>
  <div class="text-center">
    <img
      :src="logo"
      alt="Image représentant le logo de l'application"
      style="max-height: 100px"
      class="m-2 d-inline-block"
    >
  </div>

  <template v-if="maintenanceMode === 'update'">
    <template v-if="platform === 'ios' || platform === 'android'">
      <h1 class="text-success text-center">
        {{ platform === "ios" ? $t('maintenance.update.ios') : $t('maintenance.update.android') }}
      </h1>

      <h6 class="text-center text-primary">
        {{ $t('maintenance.update.goToWebsite') }}
        <a
          href="#"
          class="text-center text-secondary text-decoration-underline"
          @click.prevent="goToWebSite"
        >client.amsom-habitat.fr</a>
      </h6>

      <span
        v-if="platform === 'ios'"
        @click="goToAppStore"
      >{{ $t('update') }}</span>

      <span
        v-else-if="platform === 'android'"
        @click="goToPlayStore"
      >{{ $t('update') }}</span>
    </template>

    <template v-else>
      <h1 class="text-info text-center">
        {{ $t('maintenance.update.webTitle') }}
      </h1>

      <h6 class="text-center text-primary">
        {{ $t('maintenance.update.webSubTitle') }}
      </h6>
    </template>
  </template>

  <template v-else>
    <h1 class="text-success text-center mt-3">
      {{ $t('maintenance.comingSoon') }}
    </h1>
  </template>

  <div class="text-center mt-5">
    <button
      class="btn btn-secondary"
      @click="refreshPage"
    >
      {{ $t('common.retry') }}
    </button>
  </div>
</template>

<script>
import logo from "@/assets/logo.png";
export default {
  name: "MaintenanceBlock",

  props: {
    maintenanceMode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      logo: logo,
    };
  },

  computed: {
    platform() {
      return process.env.CORDOVA_PLATFORM;
    },
  },

  methods: {
    goToPlayStore() {
      if (window.cordova) {
        cordova.InAppBrowser.open(
          "https://play.google.com/store/apps/details?id=fr.amsomhabitat.amsometmoi",
          "_system"
        );
      } else {
        window.open(
          "https://play.google.com/store/apps/details?id=fr.amsomhabitat.amsometmoi",
          "_system"
        );
      }
    },

    goToWebSite() {
      if (window.cordova) {
        cordova.InAppBrowser.open("https://client.amsom-habitat.fr", "_system");
      } else {
        window.open("https://client.amsom-habitat.fr", "_system");
      }
    },

    goToAppStore() {
      if (window.cordova) {
        cordova.InAppBrowser.open(
          "https://apps.apple.com/fr/app/amsom-moi/id6449927674",
          "_system"
        );
      } else {
        window.open(
          "https://apps.apple.com/fr/app/amsom-moi/id6449927674",
          "_system"
        );
      }
    },

    refreshPage() {
      window.location.reload();
    },
  }
};
</script>
