<template>
  <amsom-overlay :loading="ged.loading > 0">
    <form
      @submit.prevent="onSubmit"
    >
      <div
        class="row justify-content-center gy-5"
      >
        <div class="col-12">
          <h1 class="page-heading text-center mt-1 mb-3 ">
            VALIDEZ VOTRE RECAPITULATIF
          </h1>
        </div>

        <div
          v-for="individu of listIndividus"
          :key="individu.id"
          class="col-12 col-md-10 text-center"
        >
          <div
            class="card position-relative border border-2"
          >
            <!-- Modif Antoine <span class="position-absolute top-0 start-0 fs-7 bg-gray-400 px-1 m-2 rounded rounded-pill"> -->
            <span class="position-absolute top-0 start-50 fs-6 text-primary bg-success bg-opacity-25 rounded rounded-pill translate-middle px-2 py-1 text-capitalize border border-1 border-success">
              {{ (individu.idIndividu ? '' : 'nouvel ') + getTypeLocataire(individu) }}
              <font-awesome-icon
                role="button"
                icon="fa-solid fa-pen"
                @click="goToIndividu(individu)"
              />
            </span>

            <div
              class="card-body card-text"
            >
              <div class="row justify-content-around">
                <div class="col-12">
                  <h5>{{ individu.titre }} {{ getNomPrenom(individu) }}</h5>
                </div>

                <template v-if="isMajeur(individu) && !individu.decede">
                  <div
                    v-if="individu.fixe"
                    class="col-12 col-md-auto"
                  >
                    <font-awesome-icon icon="fa-solid fa-phone" />
                    {{ individu.fixe }}
                  </div>

                  <div
                    v-if="individu.mobile"
                    class="col-12 col-md-auto"
                  >
                    <font-awesome-icon icon="fa-solid fa-mobile-screen" />
                    {{ individu.mobile }}
                  </div>

                  <div
                    v-if="individu.mail"
                    class="col-12 col-md-auto"
                  >
                    <font-awesome-icon icon="fa-solid fa-envelope" />
                    {{ individu.mail }}
                  </div>
                </template>

                <div class="col-12">
                  <hr class="w-75 mx-auto my-1">
                </div>

                <div class="col-12 d-flex justify-content-center">
                  <table
                    id="table-recap-enquete"
                    class="table table-borderless w-auto text-start"
                  >
                    <template v-if="individu.dateDepart">
                      <tr>
                        <th>
                          {{ individu.decede ? 'Décès' : 'Départ' }} déclaré le
                        </th>

                        <td>{{ unixToDateString(individu.dateDepart) }}</td>
                      </tr>

                      <template v-if="!individu.decede && getTypeLocataire(individu) !== 'occupant'">
                        <tr v-if="individu.appt">
                          <th>
                            Appartement
                          </th>

                          <td>{{ individu.appt }}</td>
                        </tr>

                        <tr>
                          <th>
                            Numéro de rue
                          </th>

                          <td>{{ individu.numRue }}</td>
                        </tr>

                        <tr>
                          <th>
                            Ligne adresse 1
                          </th>

                          <td>{{ individu.adr1 }}</td>
                        </tr>

                        <tr v-if="individu.adr2">
                          <th>
                            Ligne adresse 2
                          </th>

                          <td>{{ individu.adr2 }}</td>
                        </tr>

                        <tr v-if="individu.adr3">
                          <th>
                            Complément
                          </th>

                          <td>{{ individu.adr3 }}</td>
                        </tr>

                        <tr v-if="individu.lieuDit">
                          <th>
                            Lieu dit
                          </th>

                          <td>{{ individu.lieuDit }}</td>
                        </tr>

                        <tr>
                          <th>
                            Ville
                          </th>

                          <td>{{ individu.commune }}</td>
                        </tr>

                        <tr>
                          <th>
                            Code Postal
                          </th>

                          <td>{{ individu.cp }}</td>
                        </tr>

                        <tr>
                          <th>
                            Pays
                          </th>

                          <td>{{ getPaysById(individu.pays)?.nom }}</td>
                        </tr>
                      </template>
                    </template>

                    <template v-else>
                      <tr>
                        <th>
                          Né{{ individu.titre === 'Mme' ? 'e' : '' }} le
                        </th>

                        <td>{{ unixToDateString(individu.dateNaissance) }}</td>
                      </tr>

                      <tr>
                        <th>
                          Lieu de naissance
                        </th>

                        <td>{{ individu.lieuNaissance }}</td>
                      </tr>

                      <template v-if="isMajeur(individu)">
                        <tr>
                          <th>
                            Nature du contrat de travail
                          </th>

                          <td>{{ getContratTravailById(individu.contratTravail)?.libelle }}</td>
                        </tr>

                        <tr>
                          <th>
                            Situation professionnelle
                          </th>

                          <td>{{ getSituationSocioproById(individu.situationSociopro)?.libelle }}</td>
                        </tr>

                        <template
                          v-if="getContratTravailById(individu?.contratTravail)
                            ?.needEmployeur"
                        >
                          <tr>
                            <th>
                              Employeur
                            </th>

                            <td>{{ individu.nomEmployeur }}</td>
                          </tr>
                        </template>
                      </template>

                      <template
                        v-if="getTypeLocataire(individu) !== 'signataire'"
                      >
                        <tr>
                          <th>
                            Lien de parenté
                          </th>

                          <td>{{ getLienParenteById(individu.lienParente)?.libelle }}</td>
                        </tr>
                      </template>

                      <tr>
                        <th>
                          Possède une carte mobilité inclusion
                        </th>

                        <td>
                          <font-awesome-icon
                            v-if="individu.carteInvalidite"
                            icon="fa-solid fa-check"
                            class="text-success p-0"
                          />

                          <font-awesome-icon
                            v-else
                            icon="fa-solid fa-times"
                            class="text-danger p-0"
                          />
                        </td>
                      </tr>

                      <template v-if="getTypeLocataire(individu) === 'occupant'">
                        <tr>
                          <th>
                            Locataire à charge
                          </th>

                          <td>
                            <font-awesome-icon
                              v-if="individu.aCharge"
                              icon="fa-solid fa-check"
                              class="text-success p-0"
                            />

                            <font-awesome-icon
                              v-else
                              icon="fa-solid fa-times"
                              class="text-danger p-0"
                            />
                          </td>
                        </tr>

                        <template v-if="individu.lienParente === 'E'">
                          <tr>
                            <th>
                              En garde alternée
                            </th>

                            <td>
                              <font-awesome-icon
                                v-if="individu.dtgard"
                                icon="fa-solid fa-check"
                                class="text-success p-0"
                              />

                              <font-awesome-icon
                                v-else
                                icon="fa-solid fa-times"
                                class="text-danger p-0"
                              />
                            </td>
                          </tr>

                          <tr>
                            <th>
                              En droit de visite
                            </th>

                            <td>
                              <font-awesome-icon
                                v-if="individu.dtvis"
                                icon="fa-solid fa-check"
                                class="text-success p-0"
                              />

                              <font-awesome-icon
                                v-else
                                icon="fa-solid fa-times"
                                class="text-danger p-0"
                              />
                            </td>
                          </tr>
                        </template>
                      </template>
                    </template>
                  </table>
                </div>

                <div
                  v-if="individu.justificatifs?.length > 0"
                  class="col-12 text-start mt-2"
                >
                  <h5 class="text-decoration-underline">
                    Pièces justificatives
                  </h5>

                  <div
                    v-for="file in individu.justificatifs"
                    :key="file.id"
                  >
                    <span
                      class="hoverable"
                      @click="openFile(file.id)"
                    >
                      <font-awesome-icon icon="fa-solid fa-solid fa-paperclip" />
                      {{ file.libelle }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-12 col-md-10 text-center"
        >
          <div class="card">
            <div class="card-body card-text">
              <div class="row">
                <div class="col-12">
                  <h5>Revenus annuels</h5>

                  <hr class="w-75 mx-auto my-1">
                </div>

                <div class="col-12 d-flex justify-content-center">
                  <!--
                    <div
                    class="table table-borderless w-auto text-start"
                    >
                  -->
                  <table
                    id="table-recap-enquete"
                    class="table table-borderless w-auto text-start"
                  >
                    <tr
                      v-for="individu in fiscaliteListIndividu"
                      :key="individu.id"
                    >
                      <th>
                        {{ getNomPrenom(individu) }}
                      </th>

                      <td>
                        {{ individu.revenusAnnuels === null ? 'Non déclaré' : toCurrencyEUR(individu.revenusAnnuels, 0) }}
                      </td>
                    </tr>
                  </table>
                  <!-- </div> -->
                </div>

                <div class="col-12">
                  <h5>
                    Revenu fiscal de référence : {{ toCurrencyEUR((enqueteContrat?.rfrCorrige ? enqueteContrat?.rfrCorrige : enqueteContrat?.rfr), 0) }}
                  </h5>
                </div>

                <div
                  v-if="enqueteContrat.justificatifs?.length > 0"
                  class="col-12 text-start"
                >
                  <h5 class="text-decoration-underline">
                    Pièces justificatives
                  </h5>

                  <div
                    v-for="file in enqueteContrat.justificatifs"
                    :key="file.id"
                  >
                    <span
                      class="hoverable"
                      @click="openFile(file.id)"
                    >
                      <font-awesome-icon icon="fa-solid fa-solid fa-paperclip" />
                      {{ file.libelle }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 d-flex justify-content-center">
          <div class="form-check">
            <input
              id="input-certified-ok"
              v-model="certifiedOk"
              class="form-check-input"
              type="checkbox"
              required
            >

            <label
              class="form-check-label"
              for="input-certified-ok"
            >
              Je certifie sur l'honneur l'exactitude des renseignements fournis
            </label>
          </div>
        </div>

        <div class="col-12 col-md-10 d-flex justify-content-between">
          <button
            type="button"
            class="btn btn-secondary rounded-5"
            @click="goBackToBeginning()"
          >
            <font-awesome-icon icon="fa-solid fa-xmark" />
            Corriger
          </button>

          <button
            type="submit"
            class="btn btn-success rounded-5"
          >
            {{ $t("common.validate") }}
            <font-awesome-icon icon="fa-solid fa-check" />
          </button>
        </div>
      </div>
    </form>
  </amsom-overlay>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import {unixToDateString, toCurrencyEUR} from "@/js/text.js";
import fileUtils from "@/js/fileUtils.js";
import AmsomOverlay from "../UI/AmsomOverlay.vue";
import { toast } from "vue3-toastify";

export default {
  name: "RecapEnqueteBlock",
  components: {AmsomOverlay},
  emits: ['setCurrentStep', 'goToIndividu'],

  data() {
    return {
      certifiedOk: false,
    }
  },

  computed: {
    ...mapState(['enquete', 'ged']),

    ...mapGetters({
      getNomPrenom: 'enquete/getNomPrenom',
      getPaysById : 'utilities/getPaysById',
      getContratTravailById : "utilities/getContratTravailById",
      getSituationSocioproById: 'utilities/getSituationSocioproById',
      getLienParenteById : 'utilities/getLienParenteById',
      isMajeur: 'enquete/isMajeur',
      getTypeLocataire: 'enquete/getTypeLocataire',
      selectedContratId: "user/getSelectedContrat",
    }),

    enqueteContrat() {
      return this.enquete.enqueteContrat;
    },

    listIndividus() {
      if(!this.enquete.enqueteIndividus)
        return [];

      let individus = Array.from(this.enquete.enqueteIndividus)

      // Trier les locataires selon le type et, en cas d'égalité de type, selon l'ancienneté
      individus.sort((a, b) => {
        // Tri par type : signataire, cosignataire, occupant
        const typeOrder = {
          signataire: 1,
          cosignataire: 2,
          occupant: 3
        }


        if (typeOrder[a.type] !== typeOrder[b.type]) {
          return typeOrder[a.type] - typeOrder[b.type];
        } else {
          // Si les types sont identiques, tri par la propriété "debut"
          // if (a.debut !== b.debut) {
          //     return a.debut - b.debut;
          // }
          return b.idIndividu - a.idIndividu;
        }
      });

      return individus;
    },

    fiscaliteListIndividu(){
      if(!this.enquete.enqueteIndividus)
        return [];

      let individus = Array.from(this.enquete.enqueteIndividus).filter(individu => !individu.dateDepart)

      // Trier les locataires selon le type et, en cas d'égalité de type, selon l'ancienneté
      individus.sort((a, b) => {
        // Tri par type : signataire, cosignataire, occupant
        const typeOrder = {
          signataire: 1,
          cosignataire: 2,
          occupant: 3
        }


        if (typeOrder[a.type] !== typeOrder[b.type]) {
          return typeOrder[a.type] - typeOrder[b.type];
        } else {
          //order by dateNaissance
          if (a.dateNaissance !== b.dateNaissance) {
            return a.dateNaissance - b.dateNaissance;
          }
          return b.idIndividu - a.idIndividu;
        }
      });

      return individus
    }
  },

  mounted() {
    this.$store.dispatch(
      "enquete/loadEnqueteIndividus",
      {idContrat : this.enqueteContrat.idContrat, idEnquete: this.enqueteContrat.idEnquete}
    );
    this.$store.dispatch("enquete/loadEnqueteContrat", this.selectedContratId);
  },

  methods: {
    unixToDateString,
    toCurrencyEUR,

    onSubmit() {
      if (!this.certifiedOk) {
        return
      }
      this.valid = true;

      this.$store.dispatch('enquete/signerEnquete', {
        idEnquete: this.enqueteContrat.idEnquete,
        idContrat: this.enqueteContrat.idContrat
      })
        .then(() => {
          this.$router.push({name:"enqueteHome"})
        })
        .catch((error) => {
          toast.error(error);
        })

    },

    openFile(id) {
      this.loading ++
      this.$store.dispatch('ged/loadPdfById', id)
        .then((data) => {
          fileUtils.openFileBlob(data)
          this.loading --
        })
    },

    goBackToBeginning() {
      this.$emit('setCurrentStep', 1)
    },

    goToIndividu(individu){
      this.$emit('goToIndividu', individu)
    }
  },
}

</script>

<style>
td, th{
  padding: 0 1em;
}
</style>
