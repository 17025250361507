<template>
  <widget-block :bg-image="bgImage">
    <template #title>
      <h5>
        {{ $t("payment.cardHeader") }}
      </h5>
    </template>

    <template #content>
      <span
        class="card-text text-primary"
      >
        {{ $t("payment.cardContent") }}
      </span>
    </template>

    <template #btn-action>
      <router-link
        class="btn btn-success rounded-5 mt-3 mb-2 me-1"
        :to="{name : 'paiement'}"
      >
        {{ $t('payment.cardBtn') }}
        <font-awesome-icon
          icon="fa-solid fa-arrow-right"
          class="ms-1"
        />
      </router-link>
    </template>
  </widget-block>
</template>

<script>
import WidgetBlock from '../WidgetBlock.vue';
import bgImage from '@/assets/images/homePage/cb-widget.jpg';
export default{
  name: 'CbWidget',

  components: {
    WidgetBlock,
  },

  data() {
    return {
      bgImage: bgImage,
    }
  },
}
</script>
