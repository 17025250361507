<template>
  <div
    v-if="!storeNavHidden"
    class="w-100 bg-body border-bottom border-2 position-sticky sticky-top"
  >
    <div
      class="bg-gray bg-opacity-50 w-100 py-1 px-2 d-flex"
      style="z-index: 50"
    >
      <div class="text-center flex-shrink-1">
        <img
          :src="appLogo"
          alt="Image représentant le logo de l'application"
          style="max-height: 50px"
          class="m-2 d-inline-block"
        >
      </div>

      <div
        class="flex-grow-1 d-flex text-center align-items-center justify-content-center flex-column text-primary"
      >
        <span
          @click="goToStoreFromBrowser"
          v-html="getPlatforms().includes('android') ? $t('invitToApp.downloadAppAndroid') : $t('invitToApp.downloadAppIos')"
        />
      </div>

      <div class="potision-absolute">
        <span
          role="button"
          class="p-1"
          @click="storeNavHidden = !storeNavHidden"
        >
          <font-awesome-icon icon="fa-solid fa-xmark" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getPlatforms } from "@ionic/vue";
import appLogo from "@/assets/images/store_logo.png"

export default {
  name: "InvitToAppBlock",

  data() {
    return {
      appLogo: appLogo,
      storeNavHidden: false,
    };
  },

  methods: {
    getPlatforms,

    goToStoreFromBrowser() {
      if (getPlatforms().includes("android")) {
        window.open(
          "https://play.google.com/store/apps/details?id=fr.amsomhabitat.amsometmoi",
          "_system"
        );
      } else if (getPlatforms().includes("ios")) {
        window.open(
          "https://apps.apple.com/fr/app/amsom-moi/id6449927674",
          "_system"
        );
      }
    },
  },
};
</script>
