<template>
  <amsom-modal v-if="showNotification">
    <div
      v-if="showWelcome"
      class="text-center"
    >
      <img
        :src="logo"
        class="img-fluid w-50 my-5"
        style="max-width: 200px"
        alt="Logo application"
      >

      <h4 class="mb-3">
        Bienvenue sur votre Espace Client AMSOM&amp;Moi
      </h4>

      <div class="text-start ms-3">
        <div
          v-for="item in items"
          :key="item"
        >
          <font-awesome-icon
            :icon="['fas', 'check']"
            class="me-2 text-success"
          />
          {{ item }}
        </div>
      </div>

      <button
        class="btn btn-success rounded-5 mb-3 mt-5 btn-lg"
        @click="showWelcome = false"
      >
        {{ $t("common.next") }}
        <font-awesome-icon
          :icon="['fas', 'arrow-right']"
          class="ms-2"
        />
      </button>
    </div>

    <div
      v-else
      class="text-center"
    >
      <img
        :src="
          notificationImage"
        class="img-fluid w-100 mb-2"
        style="max-height: 60vh"
        alt="Image décrivant à quoi servent les notifications"
      >

      <div class="w-100 d-flex justify-content-center">
        <button
          type="button"
          role="button"
          class="btn btn-success rounded-5 mb-3 btn-lg"
          @click="validate"
        >
          {{ $t("common.next") }}
          <font-awesome-icon
            :icon="['fas', 'arrow-right']"
            class="ms-2"
          />
        </button>
      </div>
    </div>
  </amsom-modal>
</template>

<script>
import firebase from "@/js/firebase";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AmsomModal from "../UI/AmsomModal.vue";
import notificationImage from "@/assets/images/infoNotification.svg";
import logo from "@/assets/logo.png";

export default {
  name: "ModalNotification",
  components: { FontAwesomeIcon, AmsomModal },

  data() {
    return {
      showWelcome: false,
      showNotification: false,
      notificationImage: notificationImage,
      logo: logo,
      items: [
        "Payez votre loyer",
        "Gérez vos sollicitations",
        "Retrouvez vos documents",
        "Remplissez votre enquête annuelle",
        "Retrouvez vos numéros utiles",
        "Et bien plus encore...",
      ],
    };
  },

  computed: {
    alreadyAskFcm() {
      return window.localStorage?.getItem("fcmAsked") === "true";
    },
  },

  mounted() {
    // Si première demande du fcm, on affiche le modal
    if (!this.alreadyAskFcm) {
      this.showNotification = true;
      this.showWelcome = true;
    }
    // else {
    //     this.validate
    // }
  },

  methods: {
    validate() {
      this.showNotification = false;
      firebase.requestPermission();
    },
  },
};
</script>

<style>
.body-modal {
  max-height: 80vh;
  overflow-y: auto;
}
</style>
