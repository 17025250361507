<template>
  <div
    id="bandeau-communication"
    ref="bandeauCommunication"
    class="overflow-hidden rounded-3"
  >
    <vueper-slides
      v-if="slides.length > 0"
      :dragging-distance="70"
      :breakpoints="breakpoints"
      :arrows="false"
      autoplay
      bullets
      bullets-outside
      prevent-y-scroll
      duration="4000"
    >
      <vueper-slide
        v-for="(slide, i) in slides"
        :key="i"
        :title="slide.title"
        :content="slide.content"
        :image="slide.image"
        :link="slide.link"
        open-in-new
      >
        <template #content>
          <div class="vueperslide__content-wrapper">
            <div class="vueperslide__content w-100 h-100">
              <div class="widget-content text-primary p-0 h-100">
                <div class="d-flex flex-column pt-3">
                  <span
                    class="text-start ps-3 lh-sm"
                    :class="[
                      {
                        'blur bg-primary bg-opacity-75 mb-1 py-1 pe-2 me-auto':
                          slide.blurred,
                        'text-shadow': slide.contentShadow
                      }
                    ]"
                    :style="[
                      'color: ' + slide.titleColor ?? 'var(--bs-primary)',
                      textSize.title
                    ]"
                  >
                    {{ slide.title }}
                  </span>

                  <span
                    class="text-start ps-3 lh-sm"
                    :class="[
                      {
                        'blur bg-success bg-opacity-75 mb-1 py-1 pe-3 me-auto':
                          slide.blurred,
                        'text-shadow': slide.contentShadow
                      }
                    ]"
                    :style="[
                      'color: ' + slide.contentColor ?? 'var(--bs-success)',
                      textSize.content
                    ]"
                  >{{ slide.content }}
                  </span>
                </div>
              </div>

              <div class="position-absolute end-0 bottom-0">
                <button
                  v-if="slide.action"
                  type="button"
                  class="btn btn-success btn-lg rounded-5 mb-3 me-2 shadow"
                  @click="showMore(slide)"
                >
                  {{ $t("communicationCiblee.enSavoirPlus") }}
                </button>
              </div>
            </div>
          </div>
        </template>
      </vueper-slide>
    </vueper-slides>

    <amsom-modal
      v-if="showHtml"
      close-option
      @close="showHtml = false"
    >
      <div
        class="text-start"
        v-html="htmlToDisplay?.content"
      />

      <div class="text-center">
        <button
          type="button"
          class="btn btn-success rounded-5 my-3 shadow"
          @click="showHtml = false"
        >
          {{ $t("common.fermer") }}
        </button>
      </div>
    </amsom-modal>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import { mapGetters, mapState } from "vuex";
import fileUtils from "@/js/fileUtils.js";
import "vueperslides/dist/vueperslides.css";
import defaultImage from "@/assets/images/communicationCiblee/default.jpg";
import waitImage from "@/assets/images/communicationCiblee/wait.jpg";
import AmsomModal from "../UI/AmsomModal.vue";

export default {
  name: "CommunicationCiblee",
  components: { VueperSlides, VueperSlide, AmsomModal },

  data() {
    return {
      defaultImage: defaultImage,
      waitImage: waitImage,

      htmlToDisplay: null,
      showHtml: false,

      breakpoints: {
        9999: {
          slideRatio: 7 / 10,
        },

        992: {
          slideRatio: 3.5 / 10,
        },

        970: {
          slideRatio: 3.75 / 10,
        },

        936: {
          slideRatio: 4 / 10,
        },

        820: {
          slideRatio: 4.5 / 10,
        },

        768: {
          slideRatio: 5 / 10,
        },

        685: {
          slideRatio: 6 / 10,
        },

        600: {
          slideRatio: 7 / 10,
        },
      },
    };
  },

  computed: {
    ...mapState(["communicationCiblee"]),

    ...mapGetters({
      selectedContratId: "user/getSelectedContrat",
    }),

    textSize() {
      let fs = {
        title: "font-size: 1.5rem;",
        content: "font-size: 1.1rem;",
      };
      if (this.bandeauCommunicationWidth < 400) {
        fs.title = "font-size: 1.2rem;";
        fs.content = "font-size: 0.9rem;";
      } else if (this.bandeauCommunicationWidth < 600) {
        fs.title = "font-size: 1.3rem;";
        fs.content = "font-size: 1.1rem;";
      }

      return fs;
    },

    bandeauCommunicationWidth() {
      return this.$refs.bandeauCommunication?.clientWidth ?? 300;
    },

    slides() {
      let slides = [];
      if (!this.communicationCiblee.slides.length === 0)
        return [
          {
            title: "Patientez",
            titleColor: "#FFFFFF",
            titleShadow: true,
            content: "Chargement en cours",
            contentColor: "#A4A128",
            contentShadow: true,
            image: this.waitImage,
          },
        ];

      slides = this.communicationCiblee?.slides.map((slide) => {
        const params = slide.params ? JSON.parse(slide.params) : null;
        return {
          id:slide.idCommunication,
          title: slide.titre,
          titleColor: params?.titleColor ?? "#FFFFFF",
          titleShadow: true,
          content: slide.sousTitre,
          contentColor: params?.contentColor ?? "#A4A128",
          contentShadow: true,
          image: slide.image,
          blurred: params?.blurred ?? false,
          action: slide.action,
          actionContent: slide.valeurAction,
        };
      });
      return slides.sort((a, b) => b.id - a.id);
    },
  },

  watch: {
    selectedContratId: function () {
      this.loadDataOfContrat();
    },
  },

  mounted() {
    this.loadDataOfContrat();
  },

  methods: {
    loadDataOfContrat() {
      if (this.selectedContratId) {
        this.$store.dispatch(
          "communicationCiblee/loadSlides",
          this.selectedContratId
        );
      }
    },

    async showMore(slide) {
      if (slide.action === "lien") {
        if (window.cordova) {
          cordova.InAppBrowser.open(
            slide.actionContent,
            "_system",
            "location=yes"
          );
        } else {
          window.open(slide.actionContent, "_blank");
        }
      } else if (slide.action === "page") {
        this.$router.push(JSON.parse(slide.actionContent));
      } else if (slide.action === "document") {
        let fileBlob = await fetch(process.env.VUE_APP_FILESERVER_URL + "/dist/file/communication/" + slide.actionContent).then(r => r.blob());
        // let fileBlob = await fileUtils.localFileToBlob(
        //   "/dist/file/communication/" + slide.actionContent
        // );

        fileUtils.openFileBlob(fileBlob);
      } else if (slide.action === "html") {
        this.htmlToDisplay = {
          title: slide.title,
          content: slide.actionContent,
        };
        this.showHtml = true;
      }
    },
  },
};
</script>

<style scoped>
:deep(.vueperslides__inner) {
  border-radius: 0.5rem;
  overflow: hidden;
}
:deep(.vueperslides__bullet .default) {
  background-color: var(--bs-gray);
  border: none;
  box-shadow: none;
  transition: 0.3s;
  width: 10px;
  height: 10px;
}
:deep(.vueperslides__bullet--active .default) {
  background-color: var(--bs-secondary);
  width: 12px;
  height: 12px;
}

.text-shadow {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.blur {
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
</style>
