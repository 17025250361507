<template>
  <div
    id="component-assurance-description-panel"
    class="card"
  >
    <div class="card-body card-text">
      <h5>{{ $t('insuranceInformation.header') }}</h5>

      <p style="white-space: pre-line">
        {{ $t('insuranceInformation.lawPart') }}
      </p>

      <template v-if="assurance === null">
        <h5 class="text-danger">
          {{ $t('insurance.notAssured') }}
        </h5>
      </template>

      <template v-else>
        <h5
          v-if="unixIsAfter(assurance?.dateDebut)"
          class="text-secondary"
        >
          {{ $t("insurance.comming") }}
          {{ unixToDateString(assurance?.dateDebut, "DD/MM") }}
        </h5>

        <h5
          v-else-if="!assurance.valide"
          class="text-danger"
        >
          {{ $t("insurance.expired") }}
        </h5>

        <h5 v-else>
          {{ $t("insurance.upToDate") }}
        </h5>

        <p
          v-if="!assurance.valide"
          v-html="$t('insuranceInformation.insuranceExpiredDetail', {date: unixToDateString(assurance.dateFin)})"
        />

        <p
          v-else
          v-html="$t('insuranceInformation.insuranceNotExpiredDetail', {date: unixToDateString(assurance.dateFin)})"
        />
      </template>

      <div
        class="card bg-secondary"
      >
        <div class="card-text py-4 px-3 text-gray">
          {{ $t('insuranceInformation.penalityMessage') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { unixToDateString } from "../../js/text.js";
import { unixIsAfter } from "../../js/dateUtils.js";

export default {
  name: "AssuranceDescriptionPanel",

  props: {
    assurance: {
      type: Object,
      required: false,
      default: null,
    },
  },

  methods: {
    unixToDateString,
    unixIsAfter,
  },
};
</script>
