<template>
  <div>
    <div
      class="container-fluid bg-light"
    >
      <amsom-overlay
        :loading="registerPage.loading > 0"
        class="w-100"
      >
        <div class="row vh-100 text-center align-items-center">
          <div class="position-absolute top-0 start-0 p-3 text-start">
            <font-awesome-icon
              :icon="['fas', 'fa-arrow-left']"
              class="text-primary"
              role="button"
              size="2x"
              @click="$router.push('/')"
            />
          </div>

          <div class="col-12 col-md-6 px-2 px-sm-2 px-md-3 px-lg-4 px-xl-5">
            <img
              :src="logo"
              class="w-25 mb-3 d-md-none"
              alt="Logo"
            >

            <div class="card text-center rounded-4 mb-3">
              <div
                class="card-body"
                style="padding-top: 0.5rem !important;"
              >
                <div class="card-text">
                  <h4 class="text-primary my-3">
                    {{ $t("registerPage.cardHeader") }}
                  </h4>

                  <workflow-process-horizontal-block
                    class="d-none d-md-flex"
                    :process="workflowRegister"
                    @go-to="processChange"
                  />

                  <workflow-process-horizontal-block
                    class="d-flex d-md-none"
                    :process="workflowRegister"
                    hide-title
                    @go-to="processChange"
                  />

                  <register-form-block class="mt-4" />
                </div>
              </div>
            </div>
          </div>

          <div class="d-none d-md-block col-6">
            <img
              :src="loginImg"
              class="vh-100 mw-100 px-2"
              alt="Logo"
            >
          </div>
        </div>
      </amsom-overlay>
    </div>
  </div>
</template>

<script>
import RegisterFormBlock from "@/components/RegisterPage/FormBlock.vue";
import WorkflowProcessHorizontalBlock from "@/components/WorkflowProcessHorizontalBlock.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {mapState} from "vuex";
import logoImg from "@/assets/images/loginPage/login.svg"
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";
import logo from "@/assets/logo.png";

export default {
  name: "RegisterPage",
  components: { RegisterFormBlock, WorkflowProcessHorizontalBlock, FontAwesomeIcon, AmsomOverlay},

  data() {
    return {
      loginImg: logoImg,
      logo: logo,
    }
  },


  computed:{
    ...mapState(['registerPage']),

    registerStep: {
      get() {
        return this.registerPage.registerStep
      },

      set(value) {
        this.$store.commit('registerPage/setRegisterStep', value)
      }
    },

    workflowRegister(){
      return {
        currentStep: this.registerStep,
        steps: [
          {
            numero: 1,
            texte: this.$t('registerPage.workflowStep1'),
          },
          {
            numero: 2,
            texte: this.$t('registerPage.workflowStep2'),
          },
          {
            numero: 3,
            texte: this.$t('registerPage.workflowStep3'),
          },
          {
            numero: 4,
            texte: this.$t('registerPage.workflowStep4'),
          },
        ],
      }
    },
  },

  mounted() {
    const self = this;
    this.$store.commit('registerPage/setRegisterStep', 1);

    if (this.$route.query.mail && this.$route.query.contrat)
      this.$store.dispatch('registerPage/checkMailForContrat', {
        contrat: this.$route.query.contrat,
        mail: this.$route.query.mail
      })
        .then(() => {
          /// En cas de succès, invitation pour l'utilisateur à poursuivre son inscription
          this.$store.dispatch('registerPage/getAvailableCommunicationForContrat', self.$route.query.contrat)
          self.$store.commit('registerPage/setMail', self.$route.query.mail)
          this.$store.commit('registerPage/setRegisterStep', 3);
        })
        .catch((error) => {
          /// En cas d'erreur, invitation pour l'utilisateur à se rapprocher du bailleur
          console.log(error);
          this.$store.commit('registerPage/setRegisterStep', 0);
        })
  },

  methods: {
    processChange(step) {
      this.registerStep = step;
    },
  }
}
</script>

<style scoped>
</style>
