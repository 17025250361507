<template>
  <div
    class="card"
  >
    <div class="card-body">
      <amsom-overlay :loading="ged.loading > 0">
        <div class="d-flex justify-content-between mb-3">
          <div class="d-flex align-items-center">
            <span class="fs-5">{{ $t('fileList.header') }}</span>
          </div>

          <div class="d-flex align-items-center">
            <select
              v-if="monthOptions && monthOptions.length > 0"
              id="select-date-document"
              v-model="selectedTerme"
              class="form-select form-select-sm fs-7 rounded-3 py-0"
            >
              <option
                v-for="option in monthOptions"
                :key="option.value"
                :value="option.value"
                class="text-uppercase"
              >
                {{ option.texte }}
              </option>
            </select>
          </div>
        </div>

        <div v-if="(ged.gedMensuel && ged.gedMensuel.length === 0) || !selectedTerme">
          {{ $t('fileList.noDocument') }}
        </div>

        <div v-else>
          <button
            id="btn-download-avis-echeance"
            class="btn w-100 bg-linear bg-primary-to-secondary rounded rounded-3 text-white px-2 py-3 my-1 d-flex align-items-center"
            :disabled="!avisEcheance"
            @click="dowloadFileGed(avisEcheance.idGed, 'Avis d\'échéance '+ getMonthYear(selectedTerme))"
          >
            <template v-if="avisEcheance">
              <div class="w-75 text-start h-100">
                <span v-html="$t('fileList.downloadDueNotice', {monthYear: getMonthYear(selectedTerme)})" />
              </div>

              <div class="w-25 text-center">
                <font-awesome-icon
                  icon="fa-solid fa-cloud-arrow-down"
                  size="lg"
                />
              </div>
            </template>

            <template v-else>
              <div class="w-75 text-start">
                <span
                  v-html="$t('fileList.dueNoticeNotAvailable', {monthYear: getMonthYear(selectedTerme)})"
                />
              </div>
            </template>
          </button>

          <template
            v-for="avisEcheanceComp in avisEcheanceCompList"
            :key="avisEcheanceComp.id"
          >
            <button
              v-if="avisEcheanceComp"
              :id="'btn-download-avis-echeance-comp-'+avisEcheanceComp.id"
              class="btn w-100 bg-linear bg-primary-to-secondary rounded rounded-3 text-white px-2 py-3 my-1 d-flex align-items-center"
              @click="dowloadFileGed(avisEcheanceComp.idGed, avisEcheanceComp.libelle)"
            >
              <div class="w-75 text-start h-100">
                <span v-html="$t('fileList.downloadDueNoticeComp', {libelle: avisEcheanceComp.libelle})" />
              </div>

              <div class="w-25 text-center">
                <font-awesome-icon
                  icon="fa-solid fa-cloud-arrow-down"
                  size="lg"
                />
              </div>
            </button>
          </template>

          <button
            id="btn-download-quittance"
            class="btn w-100 bg-linear bg-secondary-to-primary rounded rounded-3 text-white px-2 py-3 my-1 d-flex align-items-center"
            :disabled="!quittance"
            @click="generateQuittance(selectedTerme)"
          >
            <template v-if="quittance">
              <div class="w-75 text-start  h-100">
                <span v-html="$t('fileList.downloadRentReceipt', {monthYear: getMonthYear(selectedTerme)})" />
              </div>

              <div class="w-25 text-center">
                <font-awesome-icon
                  icon="fa-solid fa-cloud-arrow-down"
                  size="lg"
                />
              </div>
            </template>

            <template v-else>
              <div class="text-start w-75">
                <span v-html="$t('fileList.rentReceiptUnpaid', {monthYear: getMonthYear(selectedTerme)})" />
              </div>
            </template>
          </button>
        </div>
      </amsom-overlay>
    </div>
  </div>
</template>

<script>
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";
import {sortArray} from "@/js/array";
import moment from "moment-timezone";
import fileUtils from "@/js/fileUtils";
import {mapGetters, mapState} from "vuex";

export default {
  name: "DocumentsBlock",
  components: {AmsomOverlay},

  data() {
    return {
      selectedTerme: null
    }
  },

  computed:{
    ...mapState(['ged']),

    ...mapGetters({
      selectedContratId: 'user/getSelectedContrat',
    }),

    monthOptions() {
      // get all month/year option using props terme of array gedMensuel
      // terme is YYYYMM
      let res = null
      if (this.ged.gedMensuel) {
        res = this.ged.gedMensuel.map((item) => {
          return {
            texte: this.getMonthYear(item.terme),
            value: item.terme
          }
        })

        res = sortArray(res, 'value', false)
      }

      return res
    },

    documentCurrentTerme() {
      if (this.ged.gedMensuel && this.selectedTerme) {
        return this.ged.gedMensuel.find((item) => item.terme === this.selectedTerme)
      }

      return null
    },

    quittance() {
      if (this.documentCurrentTerme) {
        return this.documentCurrentTerme.documents.find((item) => item.type === 'quittance' && item.id !== null)
      }

      return null
    },

    avisEcheance() {
      if (this.documentCurrentTerme) {
        return this.documentCurrentTerme.documents.find((item) => item.type === 'avis_echeance' && item.id !== null)
      }

      return null
    },

    avisEcheanceCompList() {
      if (this.documentCurrentTerme) {
        return this.documentCurrentTerme.documents.filter((item) => item.type === 'avis_echeance_comp' && item.id !== null)
      }

      return null
    }
  },

  watch: {
    'monthOptions': function () {
      if (this.monthOptions && this.monthOptions.length > 0) {
        for (let month of this.monthOptions) {
          // if have the same month in the list of monthOptions and have at least one document of documents with id not null
          if (this.ged.gedMensuel.find((item) => item.terme === month.value && item.documents.find((doc) => doc.id !== null))) {
            this.selectedTerme = month.value
            break
          }
          this.selectedTerme = this.monthOptions[0].value
        }
      }
    }
  },

  methods: {
    getMonthYear(terme) {
      let month = moment(terme, 'YYYYMM').format('MMMM YYYY')
      return month.charAt(0).toUpperCase() + month.slice(1)
    },

    dowloadFileGed(id, filename = 'Document') {
      this.$store.dispatch('ged/loadPdfById', id)
        .then((data) => {
          fileUtils.downloadFile(data, filename)

          this.isDownloading = false
        })
        .finally(() => this.isDownloading = false)
    },

    generateQuittance(terme) {
      this.$store.dispatch('ged/generateQuittance', {idContrat: this.selectedContratId, terme: terme})
        .then((data) => {
          fileUtils.downloadFile(data, 'Quittance de loyer ' + this.getMonthYear(terme))

          this.isDownloading = false
        })
        .finally(() => this.isDownloading = false)
    }
  }
}
</script>
