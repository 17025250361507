import moment from "moment-timezone";

export function normalize(chaine, lower = true){
    if(!chaine)
        return null
    let res = chaine.toString().normalize("NFD").replace(/[\u0300-\u036f]/g, "")

    if(lower)
        return res.toLowerCase()

    return res
}


export function toCurrencyEUR(amount, minimumFractionDigits = 2, forceLocale = null){
    let locale
    if(forceLocale){
        locale = forceLocale
    }else{
        locale = window.localStorage.getItem("locale") ?? 'fr'
    }

    return (amount ? amount?.toLocaleString(locale, {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: minimumFractionDigits
    }) : '0 €')
}

export function unixToDateString(unix, format = 'DD/MM/YYYY'){
    if(!moment.unix(unix).isValid())
        return unix

    return moment.unix(unix).format(format)
}

export function unixToDateTimeString(unix, format = 'DD/MM/YYYY à HH:mm'){
    return unixToDateString(unix, format)
}

export function howMuchTimeAgo(date){
    if(!moment.unix(date).isValid())
        return date
    return moment.unix(date).fromNow(true)
}
