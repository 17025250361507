<template>
  <div class="card mb-3 text-primary">
    <div class="card-body">
      <div class="card-title">
        <span
          v-if="module.categorie"
          class="h5"
        >
          {{ $te('common.mon' + module.categorie[0].toUpperCase() + module.categorie.slice(1)) ? $t('common.mon' + module.categorie[0].toUpperCase() + module.categorie.slice(1)) : 'Mon ' + module.categorie }}
        </span> {{ module.idModule }}
      </div>

      <div class="card-text mt-3 ms-2">
        <div class="d-flex mb-2 align-items-center">
          <font-awesome-icon
            icon="fa-solid fa-location-dot"
            size="2x"
            class="me-3"
          />
          {{ adresse }}
        </div>

        <div class="d-flex mb-2 align-items-center">
          <font-awesome-icon
            icon="fa-solid fa-edit"
            size="2x"
            class="me-2"
          />

          <span v-html="unixIsBefore(module.dateDebut) ? $t('module.rentedFrom', {date: unixToDateString(module.dateDebut)}) : $t('module.rentedSince', {date: unixToDateString(module.dateDebut)})" />
        </div>

        <div
          v-if="module.dateFin"
          class="d-flex mb-2"
        >
          <font-awesome-icon
            icon="fa-regular fa-calendar-xmark"
            size="2x"
            class="me-2"
          />

          <span>Résilié le</span>

          <strong class="text-danger mx-1">{{ unixToDateString(module.dateFin) }}</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {unixToDateString } from "../../js/text.js";
import {unixIsBefore} from "@/js/dateUtils.js";

export default {
  name:'ModulePanelItem',

  props: {
    module: {
      type: Object,
      required: true
    }
  },

  computed: {
    adresse() {
      let first = ''
      if (this.module.appt)
        first = ((this.module.categorie === 'Stationnement')?'Empl. ': 'Appt. ') + this.module.appt + ', '
      let sec = this.module.adresse
      let third = this.module.commune
      return first + sec + ' ' + third;
    }
  },

  methods: {
    unixIsBefore,
    unixToDateString,
  }
}
</script>
