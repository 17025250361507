<template>
  <v-select
    v-model="assureurSelected"
    :taggable="tagEnable"
    :options="assureurs"
    label="nom"
    :loading="utilities.loading>0"
    select-on-tab
    :placeholder="$t('insurance.insurerPlaceHolder')"
    :input-id="id"
    :filterable="false"
    :create-option="item => (
      {nom:item,
       id: 'N/C'}
    )"
    :clearable="false"
    @search="getItems"
    @open="getItems"
  >
    <template #search="{attributes, events}">
      <input
        class="vs__search"
        :required="required && !assureurSelected?.id"
        v-bind="attributes"
        trim
        v-on="events"
      >
    </template>

    <template #option="{ nom }">
      <h6 style="margin: 0">
        {{ nom }}
      </h6>
    </template>

    <template #no-options>
      <small
        class="hoverable text-decoration-underline text-danger"
        @click="tagEnable=true"
      >Le nom assureur n'apparaît pas dans la liste</small>
    </template>
  </v-select>
</template>

<script>
import 'vue-select/dist/vue-select.css';
import {normalize} from "@/js/text.js";
import {mapState} from 'vuex';


export default {
  name: 'AutocompleteAssureur',

  props: {
    id: {type: String, default: 'assureurName'},
    required: {type: Boolean, default: false},
    modelValue: {type: Object, required: true},
  },

  data() {
    return {
      tagEnable: false,
      searchString: null,
    }
  },

  computed: {
    ...mapState(['utilities']),

    assureurs() {
      if (this.searchString)
        return this.utilities.assureurs.filter(assureur => normalize(assureur.nom).includes(this.searchString))

      return this.utilities.assureurs;
    },

    assureurSelected: {
      get() {
        return {
          id: this.modelValue.assureurId,
          nom: this.modelValue.assureurLibelle
        };
      },

      set(newValue) {
        let toSend = {...this.modelValue}
        toSend.assureurId = newValue.id
        toSend.assureurLibelle = newValue.nom
        this.$emit('update:modelValue', toSend);
      }
    }
  },

  mounted() {
    this.$store.dispatch('utilities/getAssureurs');
  },

  methods: {
    getItems(search) {
      this.searchString = normalize(search);
    },
  },}
</script>

<style scoped>
.vs__search::placeholder {
  opacity: 0.55;
  font-size: 0.9em;
  color: var(--bs-primary)
}
</style>
