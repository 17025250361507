<template>
  <div
    id="errorPage"
    class="container-fluid mh-100 d-flex"
  >
    <div
      class="row mh-100 w-100 mx-0 justify-content-center"
    >
      <div
        class="col-12 col-lg-6 text-center text-primary align-content-center"
      >
        <div>
          <span
            class="fw-bold"
            style="font-size: 4em"
          >
            {{ $t('error.errorPage.pageMessage') }}
          </span>
        </div>

        <div>
          <div class="position-relative d-inline-block">
            <font-awesome-icon
              icon="fa-solid fa-cloud"
              class="fa-6x"
            />

            <font-awesome-icon
              icon="fa-solid fa-rotate-right"
              class="fa-3x position-absolute bottom-0 end-0 translate-middle-x text-light me-3 mb-3"
            />
          </div>
        </div>

        <div>
          <span
            class="fw-bold"
            style="font-size: 2em"
          >
            {{ $t('error.errorPage.retryMessage') }}
          </span>
        </div>

        <div class="mt-4">
          <div class="row">
            <div class="col-12 text-center text-md-end" />

            <router-link
              :to="{name: 'home'}"
              class="btn btn-secondary rounded-5 text-white"
            >
              {{ $t('error.errorPage.backToHome') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ErrorPage",

  data() {
    return {}
  },
}
</script>
