<template>
  <div class="stepper-wrapper">
    <div
      v-for="s in process.steps"
      :key="s.numero"
      class="stepper-item"
      :class="[
        {'active': s.numero === process.currentStep,
         'completed': s.numero < process.currentStep}
      ]"
      :role="s.numero < process.currentStep ? 'button' : 'none'"
      @click="s.numero < process.currentStep ? $emit('goTo', s.numero) : null"
    >
      <div
        class="step-counter"
        :class="{'fs-6': size === 'sm',
                 'fs-5': size === 'md',
                 'fs-4': size === 'lg'}"
      >
        {{ s.numero }}
      </div>

      <div
        v-if="!hideTitle"
        class="step-name"
        :class="{
          'fs-6': size === 'sm',
          'fs-5': size === 'md',
          'fs-4': size === 'lg'
        }"
      >
        {{ s.texte }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "WorkflowProcessHorizontalBlock",

  props: {
    'hideTitle': {
      type: Boolean,
      required: false,
      default: false
    },

    'process': {
      type: Object,
      required: true,

      default: ()=>{
        return {
          currentStep: 1,
          steps: [
            {
              numero: 1,
              texte: 'Step 1',
            },
            {
              numero: 2,
              texte: 'Step 2',
            },
            {
              numero: 3,
              texte: 'Step 3',
            }
          ]}
      }
    },

    'size': {
      type: String,
      required: false,
      default: 'sm',

      validator: function (value) {
        return ['sm', 'md', 'lg'].indexOf(value) !== -1
      }
    }

  },

  emits: ['goTo'],

}
</script>

<style scoped>
.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid var(--bs-secondary);
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid var(--bs-secondary);
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--bs-secondary);
  margin-bottom: 6px;
  color:var(--bs-light);
  font-size: 1.125rem;
}

.stepper-item.completed .step-counter {
  background-color: var(--bs-primary);
}
.stepper-item.active .step-counter {
  font-weight: bold;
  background-color: var(--bs-primary);
  font-size: 1.5rem;
  transition: ease-in-out 0.2s;
}
.stepper-item.active .step-name{
  font-weight: bold;
  font-size: 1.1rem;
  transition: ease-in-out 0.2s;

}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid var(--bs-primary);
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}
</style>
