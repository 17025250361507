<template>
  <div
    v-if="currentStep > 0"
    class="card mb-2"
  >
    <div class="card-body card-text d-flex justify-content-between align-items-center">
      <h5 class="m-0 p-0">
        {{ $t("prelevementFeedPanel.cardTitle") }}
      </h5>

      <div class="d-flex justify-content-between">
        <div
          v-for="n in maxSteps"
          :key="n"
          class="rounded-5 me-1"
          :class="[n <= currentStep ? 'bg-primary' : 'bg-gray bg-opacity-50']"
          :style="[
            n === currentStep ? 'width:25px' : 'width:10px',
            'height:10px',
            'transition:0.3s'
          ]"
        />
      </div>
    </div>
  </div>

  <amsom-overlay :loading="loading">
    <div class="card mb-2">
      <div class="card-body text-start">
        <Transition
          name="slide-fade"
          mode="out-in"
        >
          <div
            v-if="currentStep === 0"
            class="card-text"
          >
            <div
              class="row gy-1"
              align-h="center"
            >
              <h5 id="prelev-step-title">
                {{ $t("prelevementFeedPanel.header") }}
              </h5>

              <div class="col-12">
                <display-rib-block :rib="rib" />
              </div>

              <div
                v-if="rib?.enCours"
                id="confirmation-update-message"
                class="col-12"
              >
                <span class="bg-warning bg-opacity-25 py-3 d-block text-center rounded">
                  {{ $t("prelevementFeedPanel.currentValidation") }}
                </span>
              </div>

              <template v-else-if="rib">
                <div class="col-12 d-grid">
                  <button
                    class="btn btn-secondary rounded-5"
                    @click="currentStep = 1"
                  >
                    {{ $t("prelevementFeedPanel.modifyRIBBtn") }}
                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                  </button>
                </div>

                <div class="col-12 d-grid">
                  <button
                    class="btn btn-secondary rounded-5"
                    @click="
                      currentStep = 2;
                      editDateOnly = true;
                    "
                  >
                    {{ $t("prelevementFeedPanel.modifyDateBtn") }}
                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                  </button>
                </div>
              </template>
            </div>
          </div>
          <!-- Step 1 -->
          <div
            v-else-if="currentStep === 1"
            class="card-text"
          >
            <form @submit.prevent="validateStep1()">
              <div class="row text-start">
                <div class="col-12 mb-2">
                  <h5 id="prelev-step-title">
                    {{ $t("prelevementFeedPanel.step1.header") }}
                  </h5>

                  <span class="text-muted">{{
                    $t("common.allFieldsRequired")
                  }}</span>
                </div>

                <div
                  id="input-group-iban"
                  class="col-8"
                  role="group"
                >
                  <label
                    for="input-iban"
                    class="form-label d-block"
                  >
                    Code IBAN :
                  </label>

                  <input
                    id="input-iban"
                    v-model="editedRib.iban"
                    v-amsom-formater-upper-case
                    class="form-control"
                    type="text"
                    name="iban"
                    placeholder="IBAN"
                    pattern="^([A-Z]{2})(\d{2})([A-Z\d]+)$"
                    minlength="15"
                    maxlength="34"
                    required
                    @blur="ibanIsInvalid = !isValidIBAN(editedRib?.iban)"
                  >

                  <small
                    v-if="ibanIsInvalid"
                    class="text-danger ms-1"
                  >
                    {{ $t("prelevementFeedPanel.invalidIBAN") }}
                  </small>
                </div>

                <div
                  id="input-group-bic"
                  class="col-4"
                  role="group"
                >
                  <label
                    for="input-bic"
                    class="form-label d-block"
                  >
                    Code BIC :
                  </label>

                  <input
                    id="input-bic"
                    v-model="editedRib.bic"
                    v-amsom-formater-upper-case
                    type="text"
                    name="bic"
                    maxlength="11"
                    placeholder="BIC"
                    pattern="^([a-zA-Z]){6}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$"
                    required
                    class="form-control"
                    @blur="bicIsInvalid = !isValidBIC(editedRib?.bic)"
                  >

                  <small
                    v-if="bicIsInvalid"
                    class="text-danger ms-1"
                  >
                    {{ $t("prelevementFeedPanel.invalidBIC") }}
                  </small>
                </div>

                <div
                  id="input-group-nom-titulaire"
                  class="col-12"
                  role="group"
                >
                  <label
                    for="input-nom-titulaire"
                    class="form-label d-block"
                  >
                    {{ $t('prelevementFeedPanel.step2.name') + ' :' }}
                  </label>

                  <input
                    id="input-bic"
                    v-model="editedRib.libelleRib"
                    v-amsom-formater-upper-case
                    type="text"
                    name="nom-titulaire"
                    :placeholder="
                      $t('prelevementFeedPanel.step2.namePlaceHolder')
                    "
                    maxlength="30"
                    class="form-control"
                    required
                  >

                  <small
                    v-if="bicIsInvalid"
                    class="text-danger ms-1"
                  >
                    {{ $t("prelevementFeedPanel.invalidBIC") }}
                  </small>
                </div>
              </div>

              <div class="d-flex justify-content-between mt-4">
                <button
                  id="previous-step-rib-btn"
                  type="button"
                  role="button"
                  class="btn btn-secondary rounded-5"
                  @click="cancelStep1()"
                >
                  <font-awesome-icon :icon="['fas', 'arrow-left']" />
                  {{ $t("common.cancel") }}
                </button>

                <button
                  id="next-step-rib-btn"
                  type="submit"
                  role="button"
                  class="btn btn-success rounded-5"
                  :disabled="
                    fileLoading ||
                      !isValidIBAN(editedRib?.iban) ||
                      !isValidBIC(editedRib?.bic)
                  "
                >
                  {{ $t("common.nextStep") }}
                  <font-awesome-icon :icon="['fas', 'arrow-right']" />
                </button>
              </div>
            </form>
          </div>
          <!-- Step 2 -->
          <div
            v-else-if="currentStep === 2"
            class="card-text"
          >
            <form @submit.prevent="currentStep++">
              <div class="mb-4">
                <h5 id="prelev-step-title">
                  {{ $t("prelevementFeedPanel.step2.header") }}
                </h5>

                <span class="text-muted">{{
                  $t("common.allFieldsRequired")
                }}</span>
              </div>

              <div
                id="select-group-jour-prelevement"
                role="group"
              >
                <label
                  class="form-label d-block"
                  for="select-jour-prelevement"
                >
                  {{ $t("prelevementFeedPanel.collectionDay") }} :
                </label>

                <select
                  id="select-jour-prelevement"
                  v-model="editedRib.jourPrelevement"
                  class="form-select"
                  name="jour-prelevement"
                  required
                >
                  <option
                    v-for="jour in (editDateOnly ? joursOptions.filter((jour) => jour.value !== rib.jourPrelevement): joursOptions)"
                    :key="jour.value"
                    :value="jour.value"
                  >
                    {{ jour.text }}
                  </option>
                </select>
              </div>

              <div class="d-flex justify-content-between mt-4">
                <button
                  id="previous-step-rib-btn"
                  type="button"
                  role="button"
                  class="btn btn-secondary rounded-5"
                  @click="editDateOnly ? (currentStep = 0) : currentStep--"
                >
                  <font-awesome-icon :icon="['fas', 'arrow-left']" />
                  {{ $t("common.previous") }}
                </button>

                <button
                  id="next-step-rib-btn"
                  type="submit"
                  role="button"
                  class="btn btn-success rounded-5"
                >
                  {{ $t("common.nextStep") }}
                  <font-awesome-icon :icon="['fas', 'arrow-right']" />
                </button>
              </div>
            </form>
          </div>
          <!-- Step 3 -->
          <div
            v-else-if="currentStep === 3"
            class="card-text"
          >
            <div class="mb-4">
              <h5 id="prelev-step-title">
                {{ $t("prelevementFeedPanel.step3.header") }}
              </h5>
            </div>

            <h5>{{ $t("prelevementFeedPanel.step3.enteredInformation") }} :</h5>

            <display-rib-block
              :old-rib="rib"
              :rib="editedRib"
            />

            <hr>

            <h5>{{ $t("prelevementFeedPanel.step3.creditorContact") }} :</h5>

            <div>
              <label>{{ $t("common.name") }}</label>

              <input
                type="text"
                class="form-control rounded-3 mb-2"
                readonly
                value="AMSOM HABITAT"
              >

              <label>ICS</label>

              <input
                type="text"
                class="form-control rounded-3 mb-2"
                readonly
                value="FR22ZZZ002039"
              >

              <label>{{ $t("common.address") }}</label>

              <textarea
                class="form-control rounded-3 mb-2"
                readonly
                value="1 Rue du Général Frère - 80084 Amiens CEDEX 2 - FRANCE"
              />
            </div>

            <form
              class="mt-4"
              @submit.prevent="validateRIB"
            >
              {{ $t("prelevementFeedPanel.step3.validate") }}.
              <div
                id="select-group-jour-prelevement"
                role="group"
              >
                <label
                  for="input-password"
                  class="form-label text-white d-none"
                >{{ $t('common.confirmPassword') + ' :' }}</label>

                <div class="position-relative">
                  <input
                    id="input-password"
                    v-model="password"
                    class="form-control rounded-3"
                    :type="showPassword ? 'text' : 'password'"
                    autocomplete="current-password"
                    placeholder="******"
                    required
                  >

                  <div
                    role="button"
                    class="position-absolute end-0 top-50 translate-middle-y pe-2 text-primary"
                  >
                    <font-awesome-icon
                      v-if="!showPassword"
                      icon="fa-solid fa-eye"
                      data-toggle="tooltip"
                      data-placement="top"
                      :title="$t('common.showPassword')"
                      @click="showPassword = !showPassword"
                    />

                    <font-awesome-icon
                      v-else-if="showPassword"
                      icon="fa-solid fa-eye-slash"
                      data-toggle="tooltip"
                      data-placement="top"
                      :title="$t('common.hidePassword')"
                      @click="showPassword = !showPassword"
                    />
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between mt-4">
                <button
                  id="previous-step-rib-btn"
                  type="button"
                  role="button"
                  class="btn btn-secondary rounded-5"
                  @click="currentStep--"
                >
                  <font-awesome-icon :icon="['fas', 'arrow-left']" />
                  {{ $t("common.correct") }}
                </button>

                <button
                  id="validate-rib-btn"
                  type="submit"
                  role="button"
                  class="btn btn-success rounded-5"
                >
                  {{ $t("common.confirm") }}
                  <font-awesome-icon :icon="['fas', 'arrow-right']" />
                </button>
              </div>
            </form>
          </div>
        </Transition>

        <small
          class="text-secondary hoverable text-center d-block mt-4"
          role="link"
          @click="showCGU = true"
        >{{ $t("common.seeLegalNotice") }}</small>

        <amsom-modal
          v-if="showCGU"
          close-option
          @close="showCGU = false"
        >
          <h5>{{ $t('common.legalNotice') }}</h5>

          <div
            class="text-start"
            v-html="$t('prelevementFeedPanel.legalNoticeText')"
          />
        </amsom-modal>
      </div>
    </div>
  </amsom-overlay>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { isValidBIC, isValidIBAN } from "ibantools";
import { toast } from "vue3-toastify";
import DisplayRibBlock from "@/components/PrelevementPage/DisplayRibBlock.vue";
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";
import AmsomModal from "../UI/AmsomModal.vue";

export default {
  name: "PrelevementFeedPanel",

  components: {
    DisplayRibBlock,
    AmsomOverlay,
    AmsomModal
  },

  data() {
    return {
      currentStep: 0,
      editDateOnly: false,
      maxSteps: 3,
      fileLoading: false,
      joursOptions: [
        {value: 5, text: this.$t('prelevementFeedPanel.periodicity', {day: 5})},
        {value: 10, text: this.$t('prelevementFeedPanel.periodicity', {day: 10})},
        {value: 15, text: this.$t('prelevementFeedPanel.periodicity', {day: 15})},
        {value: 20, text: this.$t('prelevementFeedPanel.periodicity', {day: 20})}
      ],

      editedRib: {},
      ibanIsInvalid: false,
      bicIsInvalid: false,
      showPassword: false,

      password: null,

      showCGU: false,
    };
  },

  computed: {
    ...mapState(["contrat", "user"]),

    ...mapGetters({
      selectedContratId: "user/getSelectedContrat",
    }),

    loading() {
      return this.contrat.loading > 0 || this.user.loading > 0;
    },

    rib() {
      if (!this.contrat.prelevementInfos) return null;
      return this.contrat.prelevementInfos[0];
    },
  },

  watch: {
    selectedContratId: function () {
      this.loadData();
    },
  },

  mounted() {
    this.$store.dispatch("utilities/loadBanques");
    this.loadData();
  },

  methods: {
    isValidBIC,
    isValidIBAN,

    loadData() {
      if (this.selectedContratId) {
        this.$store
          .dispatch("contrat/loadPrelevementInfos", this.selectedContratId)
          .then(() => {
            this.editedRib = {};

            this.reloadStep();
          });
      }
    },

    reloadStep() {
      if (this.rib) {
        this.currentStep = 0;
        this.editedRib.jourPrelevement = this.rib.jourPrelevement;
      } else {
        this.currentStep = 1;
      }
    },

    validateStep1() {
      if (this.ibanIsInvalid) {
        toast.error("IBAN non conforme");
        return;
      }

      if (this.bicIsInvalid) {
        toast.error("BIC non conforme");
        return;
      }

      let countryCode = this.editedRib.bic.substring(4, 6);

      if (!this.editedRib.iban.startsWith(countryCode)) {
        toast.error("Le BIC ne correspond pas à l'IBAN");
        return;
      }

      this.currentStep++;
    },

    validateRIB() {
      this.$store
        .dispatch("contrat/updateRib", {
          rib: this.editedRib,
          idContrat: this.selectedContratId,
          password: this.password,
        })
        .then(() => {
          this.loadData();
        })
        .catch((error) => {
          toast.error(error);
        });
    },

    updateFileLoading(value) {
      this.fileLoading = value;
    },

    cancelStep1() {
      if (this.rib) {
        this.currentStep--;
      } else {
        this.$router.push({ name: "compte" });
      }
    },
  },
};
</script>
