const isVersionLower = function (version1, version2) {
    const version1Parts = version1.split('.');
    const version2Parts = version2.split('.');
    for (let i = 0; i < version1Parts.length; i++) {
        if (parseInt(version1Parts[i]) < parseInt(version2Parts[i])) {
            return true;
        } else if (parseInt(version1Parts[i]) > parseInt(version2Parts[i])) {
            return false;
        }
    }
    return false;
}

const isVersionUpper = function (version1, version2) {
    const version1Parts = version1.split('.');
    const version2Parts = version2.split('.');
    for (let i = 0; i < version1Parts.length; i++) {
        if (parseInt(version1Parts[i]) > parseInt(version2Parts[i])) {
            return true;
        } else if (parseInt(version1Parts[i]) < parseInt(version2Parts[i])) {
            return false;
        }
    }
    return false;
}

export default { isVersionLower }
