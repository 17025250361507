<template>
  <div
    class="container-fluid py-2 px-4"
  >
    <div class="row">
      <div class="col-12 col-md-6 mb-2 order-last order-md-first">
        <workflow-process-block process="assurance" />
      </div>

      <div class="col-12 col-md-6 mb-2">
        <assurance-feed-panel />
      </div>
    </div>
  </div>
</template>

<script>
import AssuranceFeedPanel from '@/components/AssurancePage/AssuranceFeedPanel.vue';
import WorkflowProcessBlock from "@/components/WorkflowProcessBlock.vue";

export default {
  name: 'AssurancePage',

  components: {
    WorkflowProcessBlock,
    AssuranceFeedPanel
  },

  // watch: {
  //   'selectedContratId': function () {
  //     this.$router.push({name: "assurance"})
  //   },
  // },

  // computed: {
  //   ...mapGetters({'selectedContratId': 'user/getSelectedContrat'}),
  // },

  mounted() {
    this.$store.commit('topbar/setBreadcrumb',
                       [
                         { icon: 'fa-solid fa-house', to: { name: 'home' } },
                         { text: this.$t('breadcrumb.insurance'), to: { name: 'assurance' } },
                         { text: this.$t('breadcrumb.editInsurance'), active: true }
                       ]
    )
  },
}
</script>
