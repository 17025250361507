<template>
  <div class="card m-0">
    <div class="card-body d-flex align-items-center d-md-none">
      <div class="flex-grow-1">
        <h6 class="m-0">
          {{ contact.libelle }}
        </h6>

        <span class="text-muted">{{ contact.nature }}</span>
      </div>

      <div class="flex-shrink-1">
        <div class="d-flex justify-content-end">
          <button
            v-if="contact.adresse"
            role="button"
            :class="btnClass"
            @click="mapsTo(contact.adresse)"
          >
            <font-awesome-icon
              icon="fa-solid fa-map-location-dot"
              :size="iconSize"
              :class="iconClass"
            />
          </button>

          <button
            v-if="contact.mail"
            role="button"
            :class="btnClass"
            @click="mailTo(contact.mail)"
          >
            <font-awesome-icon
              icon="fa-solid fa-envelope"
              :size="iconSize"
              :class="iconClass"
            />
          </button>

          <button
            role="button"
            :class="btnClass"
            @click="tel(contact.telephone)"
          >
            <font-awesome-icon
              icon="fa-solid fa-phone"
              :size="iconSize"
              :class="iconClass"
            />
          </button>
        </div>
      </div>
    </div>

    <div class="card-body p-0 text-center d-none d-md-block">
      <img
        class="contact-image rounded-circle shadow mb-3 "
        :src="contact.photo"
        :alt="'Image représentant : ' + contact.libelle"
      >

      <h6 class="m-0">
        {{ contact.libelle }}
      </h6>

      <span class="text-secondary">{{ contact.nature }}</span>

      <div class="mt-4">
        <button
          v-if="contact.adresse"
          role="button"
          :class="btnClass"
          @click="mapsTo(contact.adresse)"
        >
          <font-awesome-icon
            icon="fa-solid fa-map-location-dot"
            :size="iconSize"
            :class="iconClass"
          />
        </button>

        <button
          v-if="contact.mail"
          role="button"
          :class="btnClass"
          @click="mailTo(contact.mail)"
        >
          <font-awesome-icon
            icon="fa-solid fa-envelope"
            :size="iconSize"
            :class="iconClass"
          />
        </button>

        <button
          role="button"
          :class="btnClass"
          @click="tel(contact.telephone)"
        >
          <font-awesome-icon
            icon="fa-solid fa-phone"
            :size="iconSize"
            :class="iconClass"
          />
        </button>

        <button
          class="btn btn-success  btn-sm mx-1 rounded-3"
          role="button"
          @click="showMore = true"
        >
          <font-awesome-icon
            icon="fa-solid fa-info"
            :size="iconSize"
            class="mx-1"
          />
        </button>
      </div>

      <div
        v-if="showMore"
        class="my-2"
      >
        <div v-if="contact.adresse">
          <font-awesome-icon icon="fa-solid fa-map-location-dot" />
          {{ contact.adresse }}
        </div>

        <div v-if="contact.mail">
          <font-awesome-icon icon="fa-solid fa-envelope" />
          {{ contact.mail }}
        </div>

        <div v-if="contact.telephone">
          <font-awesome-icon icon="fa-solid fa-phone" />
          {{ contact.telephone }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactItem",
  props: ["contact"],

  data() {
    return {
      iconSize: "lg",
      showMore: false,
      btnClass: "btn bg-gray bg-opacity-50 btn-sm mx-1 rounded-3",
      iconClass: "text-secondary",
    };
  },

  methods: {
    tel(number) {
      if (window.cordova) {
        cordova.InAppBrowser.open("tel:" + number, "_system");
      } else {
        window.open("tel:" + number, "_system");
      }
    },

    mailTo(mail) {
      if (window.cordova) {
        cordova.InAppBrowser.open("mailto:" + mail, "_system");
      } else {
        window.open("mailto:" + mail, "_system");
      }
    },

    mapsTo(address) {
      if (window.cordova) {
        let prefixUrl
        if(device.platform.toLowerCase() === "ios")
          prefixUrl = "maps://maps.google.com/?q="
        else
          prefixUrl = "https://maps.google.com/?q="

        cordova.InAppBrowser.open(
          prefixUrl + address,
          "_system"
        );
      } else {
        window.open("https://maps.google.com/?q=" + address, "_system");
      }
    },
  },
};
</script>

<style scoped>
.contact-image {
  width: 9em;
  height: 9em;
  object-fit: cover;
}
</style>
