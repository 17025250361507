<template>
  <div
    class="container-fluid py-2 px-4"
  >
    <div class="row">
      <div class="col-12 col-md-6 mb-2 order-last order-md-first">
        <workflow-process-block :process="rib ? 'prelevementEdition' : 'prelevementSubscription'" />
      </div>

      <div class="col-12 col-md-6 mb-2">
        <prelevement-feed-panel />
      </div>
    </div>
  </div>
</template>

<script>

import PrelevementFeedPanel from "@/components/PrelevementPage/PrelevementFeedPanel.vue";
import WorkflowProcessBlock from "@/components/WorkflowProcessBlock.vue";
import {mapGetters, mapState} from "vuex";

export default {
  name: 'AssurancePage',

  components: {
    PrelevementFeedPanel,
    WorkflowProcessBlock
  },

  computed: {
    ...mapGetters({
      selectedContratId: 'user/getSelectedContrat',
    }),

    ...mapState(["contrat"]),

    rib() {
      if(!this.contrat.prelevementInfos)
        return null
      return this.contrat.prelevementInfos[0]
    }
  },

  watch: {
    'selectedContratId': function () {
      this.loadPrelevementInfos()
    },
  },

  mounted() {
    this.$store.commit('topbar/setBreadcrumb',
                       [
                         {icon: 'fa-solid fa-house', to: {name: 'home'}},
                         {text: this.$t('breadcrumb.account'), to: {name: 'compte'}},
                         {text: this.$t('breadcrumb.prelevement'), active: true}
                       ]
    )
    this.loadPrelevementInfos()
  },

  methods: {
    loadPrelevementInfos() {
      if (this.selectedContratId) {
        this.$store.dispatch('contrat/loadPrelevementInfos', this.selectedContratId)
      }
    }
  }
}
</script>
