<template>
  <div class="container-fluid py-2 px-4">
    <div class="row">
      <div class="col-12 col-lg-8 mb-3">
        <locataire-panel />
      </div>

      <div class="col-12 col-lg-4 mb-2">
        <module-panel />
      </div>
    </div>
  </div>
</template>

<script>
import LocatairePanel from '@/components/ContratPage/LocatairePanel.vue';
import ModulePanel from '@/components/ContratPage/ModulePanel.vue';

export default {
  name: 'ContratPage',

  components: {
    LocatairePanel,
    ModulePanel,
  },

  mounted() {
    this.$store.commit('topbar/setBreadcrumb',
                       [
                         {icon: 'fa-solid fa-house', to: {name: 'home'}},
                         {text: this.$t('breadcrumb.contract'), active: true}
                       ]
    )
  }
}
</script>
