<template>
  <div
    id="validation-locataire"
  >
    <amsom-overlay :loading="locatairePanel.loading > 0 || utilities.loading > 0 || loading > 0">
      <h3>{{ $t('tenants.confirmation.header') }}</h3>

      <div
        v-if="editedLocataire"
        class="row justify-content-center"
      >
        <table
          class="table table-sm table-borderless text-start w-auto mt-3"
        >
          <modified-field
            v-if="editedLocataire.fixe"
            :name="$t('tenants.informations.landlinePhone')"
            :force-hide-old="newLocataire"
            :old-value="locataire.fixe"
            :new-value="editedLocataire.fixe"
          />

          <modified-field
            v-if="editedLocataire.mobile"
            :name="$t('tenants.informations.mobilePhone')"
            :force-hide-old="newLocataire"
            :old-value="locataire.mobile"
            :new-value="editedLocataire.mobile"
          />

          <modified-field
            v-if="editedLocataire.mail"
            :name="$t('tenants.informations.mail')"
            :force-hide-old="newLocataire"
            :old-value="locataire.mail"
            :new-value="editedLocataire.mail"
          />

          <modified-field
            v-if="editedLocataire.titre"
            :name="$t('tenants.informations.gender')"
            :force-hide-old="newLocataire"
            :old-value="locataire.titre"
            :new-value="editedLocataire.titre"
          />

          <modified-field
            v-if="editedLocataire.prenom"
            :name="$t('tenants.informations.firstName')"
            :force-hide-old="newLocataire"
            :old-value="locataire.prenom"
            :new-value="editedLocataire.prenom"
          />

          <modified-field
            v-if="editedLocataire.nomNaissance"
            :name="$t('tenants.informations.birthLastName')"
            :force-hide-old="newLocataire"
            :old-value="locataire.nomNaissance"
            :new-value="editedLocataire.nomNaissance"
          />

          <modified-field
            v-if="editedLocataire.nom"
            :name="$t('tenants.informations.usageLastName')"
            :force-hide-old="newLocataire"
            :old-value="locataire.nom"
            :new-value="editedLocataire.nom"
          />

          <modified-field
            v-if="editedLocataire.dateNaissance"
            :name="$t('tenants.informations.birthDate')"
            :force-hide-old="newLocataire"
            :old-value="unixToDateString(locataire.dateNaissance)"
            :new-value="unixToDateString(editedLocataire.dateNaissance)"
          />

          <modified-field
            v-if="editedLocataire.lieuNaissance"
            :name="$t('tenants.informations.birthCity')"
            :force-hide-old="newLocataire"
            :old-value="locataire.lieuNaissance"
            :new-value="editedLocataire.lieuNaissance"
          />

          <modified-field
            v-if="editedLocataire.paysNaissance"
            :name="$t('tenants.informations.birthCountry')"
            :force-hide-old="newLocataire"
            :old-value="getPaysName(locataire.paysNaissance)"
            :new-value="getPaysName(editedLocataire.paysNaissance)"
          />

          <modified-field
            v-if="editedLocataire.zipNaissance"
            :name="$t('tenants.informations.birthPostalCode')"
            :force-hide-old="newLocataire"
            :old-value="locataire.zipNaissance"
            :new-value="editedLocataire.zipNaissance"
          />

          <modified-field
            v-if="editedLocataire.contratTravail"
            :name="$t('tenants.informations.workContractType')"
            :force-hide-old="newLocataire"
            :wait-while="!utilities.contratTravail"
            :old-value="getContratTravailById(locataire.contratTravail)?.libelle"
            :new-value="getContratTravailById(editedLocataire.contratTravail)?.libelle"
          />

          <modified-field
            v-if="editedLocataire.situationSociopro"
            :name="$t('tenants.informations.professionalSituation')"
            :force-hide-old="newLocataire"
            :wait-while="!utilities.situationSociopro"
            :old-value="getSituationSocioproById(locataire.situationSociopro)?.libelle"
            :new-value="getSituationSocioproById(editedLocataire.situationSociopro)?.libelle"
          />

          <modified-field
            v-if="editedLocataire.nomEmployeur"
            :name="$t('tenants.informations.employer')"
            :force-hide-old="newLocataire"
            :old-value="locataire.nomEmployeur"
            :new-value="editedLocataire.nomEmployeur"
          />

          <modified-field
            v-if="editedLocataire.situationFamiliale"
            :name="$t('tenants.informations.familySituation')"
            :force-hide-old="newLocataire"
            :wait-while="!utilities.situationFamiliale"
            :old-value="getSituationFamilialeById(locataire.situationFamiliale)?.libelle"
            :new-value="getSituationFamilialeById(editedLocataire.situationFamiliale)?.libelle"
          />

          <modified-field
            v-if="editedLocataire.lienParente"
            :name="$t('tenants.informations.signatoryRelationship')"
            :force-hide-old="newLocataire"
            :wait-while="!utilities.lienParente"
            :old-value="getLienParenteById(locataire.lienParente)?.libelle"
            :new-value="getLienParenteById(editedLocataire.lienParente)?.libelle"
          />

          <modified-field
            v-if="editedLocataire.dateDepart"
            :name="(editedLocataire.decede? $t('tenants.confirmation.deathDateDeclared'):$t('tenants.confirmation.departureDateDeclared'))"
            :force-hide-old="newLocataire"
            :new-value="'le ' + unixToDateString(editedLocataire.dateDepart)"
          />

          <modified-field
            v-if="editedLocataire.appt"
            :name="$t('tenants.informations.appartementNumber')"
            :force-hide-old="newLocataire"
            :old-value="locataire.appt"
            :new-value="editedLocataire.appt"
          />

          <modified-field
            v-if="editedLocataire.numRue"
            :name="$t('tenants.informations.streetNumber')"
            :force-hide-old="newLocataire"
            :old-value="locataire.numRue"
            :new-value="editedLocataire.numRue"
          />

          <modified-field
            v-if="editedLocataire.adr1"
            :name="$t('tenants.informations.addressLine1')"
            :force-hide-old="newLocataire"
            :old-value="locataire.adr1"
            :new-value="editedLocataire.adr1"
          />

          <modified-field
            v-if="editedLocataire.adr2"
            :name="$t('tenants.informations.addressLine2')"
            :force-hide-old="newLocataire"
            :old-value="locataire.adr2"
            :new-value="editedLocataire.adr2"
          />

          <modified-field
            v-if="editedLocataire.adr3"
            :name="$t('tenants.informations.additionalAddressInfo')"
            :force-hide-old="newLocataire"
            :old-value="locataire.adr3"
            :new-value="editedLocataire.adr3"
          />

          <modified-field
            v-if="editedLocataire.lieuDit"
            :name="$t('tenants.informations.locality')"
            :force-hide-old="newLocataire"
            :old-value="locataire.lieuDit"
            :new-value="editedLocataire.lieuDit"
          />

          <modified-field
            v-if="editedLocataire.commune"
            :name="$t('tenants.informations.city')"
            :force-hide-old="newLocataire"
            :old-value="locataire.commune"
            :new-value="editedLocataire.commune"
          />

          <modified-field
            v-if="editedLocataire.cp"
            :name="$t('tenants.informations.postalCode')"
            :force-hide-old="newLocataire"
            :old-value="locataire.cp"
            :new-value="editedLocataire.cp"
          />

          <modified-field
            v-if="editedLocataire.pays"
            :name="$t('tenants.informations.country')"
            :force-hide-old="newLocataire"
            :old-value="getPaysById(locataire.pays)?.nom"
            :new-value="getPaysById(editedLocataire.pays)?.nom"
          />

          <modified-field
            v-if="editedLocataire.carteInvalidite !== null"
            :name="$t('tenants.informations.cmi')"
          >
            <template
              v-if="!newLocataire"
              #oldValue
            >
              <font-awesome-icon
                v-if="locataire.carteInvalidite"
                icon="fa-solid fa-check"
                class="text-success"
              />

              <font-awesome-icon
                v-else
                icon="fa-solid fa-times"
                class="text-danger"
              />
            </template>

            <template #newValue>
              <font-awesome-icon
                v-if="editedLocataire.carteInvalidite"
                icon="fa-solid fa-check"
                class="text-success"
              />

              <font-awesome-icon
                v-else
                icon="fa-solid fa-times"
                class="text-danger"
              />
            </template>
          </modified-field>

          <template v-if="locataire.type === 'occupant'">
            <modified-field
              v-if="editedLocataire.aCharge !== null"
              :name="$t('tenants.informations.isDependant')"
            >
              <template
                v-if="!newLocataire"
                #oldValue
              >
                <font-awesome-icon
                  v-if="locataire.aCharge"
                  icon="fa-solid fa-check"
                  class="text-success"
                />

                <font-awesome-icon
                  v-else
                  icon="fa-solid fa-times"
                  class="text-danger"
                />
              </template>

              <template #newValue>
                <font-awesome-icon
                  v-if="editedLocataire.aCharge"
                  icon="fa-solid fa-check"
                  class="text-success"
                />

                <font-awesome-icon
                  v-else
                  icon="fa-solid fa-times"
                  class="text-danger"
                />
              </template>
            </modified-field>

            <modified-field
              v-if="editedLocataire.dtgard !== null"
              :name="$t('tenants.informations.isCustodyRight')"
            >
              <template
                v-if="!newLocataire"
                #oldValue
              >
                <font-awesome-icon
                  v-if="locataire.dtgard"
                  icon="fa-solid fa-check"
                  class="text-success"
                />

                <font-awesome-icon
                  v-else
                  icon="fa-solid fa-times"
                  class="text-danger"
                />
              </template>

              <template #newValue>
                <font-awesome-icon
                  v-if="editedLocataire.dtgard"
                  icon="fa-solid fa-check"
                  class="text-success"
                />

                <font-awesome-icon
                  v-else
                  icon="fa-solid fa-times"
                  class="text-danger"
                />
              </template>
            </modified-field>

            <modified-field
              v-if="editedLocataire.dtvis !== null"
              :name="$t('tenants.informations.isVisitingRight')"
            >
              <template
                v-if="!newLocataire"
                #oldValue
              >
                <font-awesome-icon
                  v-if="locataire.dtvis"
                  icon="fa-solid fa-check"
                  class="text-success"
                />

                <font-awesome-icon
                  v-else
                  icon="fa-solid fa-times"
                  class="text-danger"
                />
              </template>

              <template #newValue>
                <font-awesome-icon
                  v-if="editedLocataire.dtvis"
                  icon="fa-solid fa-check"
                  class="text-success"
                />

                <font-awesome-icon
                  v-else
                  icon="fa-solid fa-times"
                  class="text-danger"
                />
              </template>
            </modified-field>
          </template>
        </table>

        <!-- Justificatifs -->
        <template v-if="editedLocataire.justificatifs?.length > 0">
          <h5 class="text-decoration-underline">
            {{ $t('common.pj') }}
          </h5>

          <div
            v-for="file in editedLocataire.justificatifs"
            :key="file.id"
          >
            <span
              class="hoverable"
              @click="openFile(file.id)"
            >
              <font-awesome-icon icon="fa-solid fa-solid fa-paperclip" />
              {{ file.libelle }}
            </span>
          </div>
        </template>

        <!-- Boutons -->
        <div class="d-flex justify-content-between mt-4">
          <div
            class="btn-group btn-group-sm rounded-5 overflow-hidden"
            role="group"
            aria-label="Group"
          >
            <button
              id="btn-corriger-modification"
              class="btn btn-primary"
              type="button"
              @click="corrigerModification"
            >
              <font-awesome-icon
                :icon="['fas', 'arrow-left']"
                class="me-1 d-none d-sm-inline"
              />
              {{ $t('common.correct') }}
            </button>

            <button
              id="btn-annuler-modification"
              class="btn btn-danger "
              type="button"
              @click="cancelModification"
            >
              {{ $t('common.cancel') }}
              <font-awesome-icon
                :icon="['fas', 'times']"
                class="ms-1"
              />
            </button>
          </div>

          <button
            id="btn-confirm-modification"
            role="button"
            type="button"
            class="btn btn-success rounded-5"
            @click="confirmModification"
          >
            {{ $t('common.confirm') }}
            <font-awesome-icon
              :icon="['fas', 'arrow-right']"
              class="ms-1"
            />
          </button>
        </div>
      </div>
    </amsom-overlay>
  </div>

  <amsom-modal
    v-if="showAlert"
    id="alerte-desactivation-compte"
    close-option
    @close="showAlert = false"
  >
    <div class="position-absolute top-0 end-0 p-2 m-2">
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        @click="showAlert = false"
      />
    </div>

    <div
      class="text-center"
      v-html="$t('contratPage.confirmation.disabledCompteWarning')"
    />

    <div class="text-center">
      <button
        type="button"
        class="btn btn-success rounded-5 my-3 shadow"
        @click="showAlert = false; alertShown = true;"
      >
        {{ $t("contratPage.confirmation.validateWarning") }}
        <font-awesome-icon
          :icon="['fas', 'arrow-right']"
          class="ms-1"
        />
      </button>
    </div>
  </amsom-modal>
</template>

<script>
import {unixToDateString} from "@/js/text.js";
import {mapGetters, mapState} from "vuex";
import ModifiedField from "@/components/ModifiedField.vue";
import {toast} from "vue3-toastify";
import fileUtils from "@/js/fileUtils.js";
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";
import AmsomModal from "@/components/UI/AmsomModal.vue";

export default {
  name: "ConfirmLocataireModificationBlock",

  components: {
    ModifiedField,
    AmsomOverlay,
    AmsomModal
  },

  props: {
    locataire: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      loading: 0,
      showAlert: false,
      alertShown: false,
    }
  },

  computed: {
    ...mapState(['utilities', 'locatairePanel']),

    ...mapGetters({
      'selectedContratId': "user/getSelectedContrat",
      'getContratTravailById' : "utilities/getContratTravailById",
      'getSituationSocioproById': 'utilities/getSituationSocioproById',
      'getSituationFamilialeById': 'utilities/getSituationFamilialeById',
      'getLienParenteById' : 'utilities/getLienParenteById',
      'getPaysById' : 'utilities/getPaysById',
      'getIdCompte': "user/codeIndividu",
    }),

    editedLocataire(){
      return this.locatairePanel.editedLocataire
    },

    newLocataire(){
      return !this.locataire.idIndividu
    },

    compteWillBeDesactivated(){
      return this.editedLocataire.dateDepart && this.getIdCompte === this.locataire.idIndividu
    },
  },

  watch: {
    locataire(){
      this.loadModifications()
    }
  },

  mounted() {
    this.$store.dispatch('utilities/loadPays');
    this.loadModifications()
  },

  methods: {
    unixToDateString,

    loadModifications(){
      this.$store.dispatch('locatairePanel/loadModificationsIndividuContrat', this.locataire)
    },

    confirmModification(){
      let data = {
        idModification: this.editedLocataire.id
      }

      // Affiche un message d'alerte au signataire qui tente de supprimer son compte
      if(!this.alertShown && this.compteWillBeDesactivated){
        this.showAlert = true

        return
      }

      this.$store.dispatch('locatairePanel/confirmModificationIndividuContrat', data)
        .then(() => {
          this.$store.dispatch('contrat/loadIndividuInfosByContrat', this.selectedContratId)
          this.$store.commit('locatairePanel/setMode', 'view')
        })
        .catch((error) => {
          toast.error(error)
        })
    },

    cancelModification() {
      let payload = {
        data : {
          idModification: this.editedLocataire.id
        },

        deleteEditedLocataire: true
      }

      this.$store.dispatch('locatairePanel/cancelModificationIndividuContrat', payload)
        .then(() => {
          this.$store.dispatch('contrat/loadIndividuInfosByContrat', this.selectedContratId)

          if(this.newLocataire){
            this.$store.commit('locatairePanel/setSelectedLocataireId', null)
            this.$store.commit('locatairePanel/setMode', 'list')
          }else{
            this.$store.commit('locatairePanel/setMode', 'view')
          }
        })
    },

    corrigerModification(){
      if(this.editedLocataire.dateDepart)
        this.$store.commit('locatairePanel/setMode', 'correctExit')
      else
        this.$store.commit('locatairePanel/setMode', 'correctEdit')
    },

    openFile(id) {
      this.loading ++
      this.$store.dispatch('ged/loadPdfById', id)
        .then((data) => {
          fileUtils.openFileBlob(data)
          this.loading --
        })
    },

    getPaysName(ISO3){
      if(!this.utilities.pays){
        return null
      }

      return this.utilities.pays.find((pays) => pays.id === ISO3)?.nom
    }
  }
}
</script>
