<template>
  <div class="container-fluid bg-light">
    <div class="row vh-100 text-center">
      <div class="position-absolute top-0 start-0 p-3 text-start">
        <font-awesome-icon
          :icon="['fas', 'fa-arrow-left']"
          class="text-primary"
          role="button"
          size="2x"
          @click="$router.push('/')"
        />
      </div>

      <div class="col-12 col-lg-7 px-3 px-sm-4 py-5 mx-auto text-center">
        <amsom-overlay :loading="loading">
          <img
            :src="logo"
            class="w-25 mb-3"
            alt="Logo"
          >

          <div class="card text-center rounded-4 mb-3">
            <div
              class="card-body"
              style="padding-top: 0.5rem !important"
            >
              <div class="card-text">
                <div
                  class="row"
                  align-h="center"
                >
                  <div class="col-12 col-lg-9 mx-auto">
                    <h5 class="mt-2 mb-3 text-center">
                      {{ $t("loginPage.updateLoginLabel") }}
                    </h5>

                    <div
                      v-if="token"
                      class="text-start"
                    >
                      <div
                        v-if="resultRequest.message !== null"
                        class="w-100 text-center mb-3"
                      >
                        <span
                          :class="[
                            {
                              'text-success': resultRequest.state === 'success',
                              'text-danger': resultRequest.state === 'error'
                            }
                          ]"
                        >{{ resultRequest.message }}</span>

                        <div
                          v-if="confirmRedirectCooldown > 0"
                          class="fw-bold"
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                          />
                          {{ confirmRedirectCooldown / 1000 }}
                        </div>
                      </div>

                      <form
                        v-if="confirmRedirectCooldown <= 0"
                        id="resetPasswordConfirmForm"
                        @submit.prevent="updateLoginConfirm()"
                      >
                        <label
                          for="input-password"
                          class="form-label"
                        >{{
                          $t("common.confirmPassword")
                        }}</label>

                        <div class="position-relative">
                          <input
                            id="input-confirm-password"
                            v-model.lazy="password"
                            :type="showPassword ? 'text' : 'password'"
                            :placeholder="$t('registerPage.password')"
                            autocomplete="current-password"
                            class="form-control rounded-3 my-2"
                            required
                          >

                          <div
                            class="position-absolute end-0 top-0 h-100 d-flex align-items-center pe-2"
                            role="button"
                          >
                            <font-awesome-icon
                              v-if="!showPassword"
                              icon="fa-solid fa-eye"
                              title="Afficher mot de passe en clair"
                              @click="showPassword = !showPassword"
                            />

                            <font-awesome-icon
                              v-else-if="showPassword"
                              icon="fa-solid fa-eye-slash"
                              title="Cacher mot de passe"
                              @click="showPassword = !showPassword"
                            />
                          </div>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-success rounded-5 py-2 mt-3 mx-auto d-block"
                        >
                          {{ $t("common.validate") }}
                        </button>
                      </form>
                    </div>

                    <div
                      v-else
                      class="text-start"
                    >
                      <form @submit.prevent="updateLoginRequest">
                        <label
                          for="input-mail"
                          class="form-label"
                        >{{
                          $t("common.newMailAccount")
                        }}</label>

                        <input
                          id="input-mail"
                          v-model.trim="newLogin"
                          v-amsom-formater-lower-case
                          :pattern="regexMail"
                          type="email"
                          inputmode="email"
                          class="form-control"
                          :placeholder="$t('loginPage.usernamePlaceholder')"
                          required
                        >

                        <div
                          v-if="resultRequest.message !== null"
                          class="w-100 text-center mb-3"
                        >
                          <span
                            :class="[
                              {
                                'text-success': resultRequest.state === 'success',
                                'text-danger': resultRequest.state === 'error'
                              }
                            ]"
                          >{{ resultRequest.message }}</span>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-success rounded-5 py-2 mt-3 mx-auto d-block"
                        >
                          {{ $t("loginPage.updateLoginBtn") }}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </amsom-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";
import logo from "@/assets/logo.png";

export default {
  name: "UpdateLoginPage",
  components: { AmsomOverlay },

  data() {
    return {
      logo: logo,
      regexMail: process.env.VUE_APP_REGEX_EMAIL,

      showPassword: false,

      password: null,
      token: null,
      newLogin: null,
      oldLogin: null,

      loading: false,

      resultRequest: {
        state: null,
        message: null,
      },

      confirmRedirectCooldown: 0,
    };
  },

  watch: {
    confirmRedirectCooldown: function (newvalue) {
      if (newvalue <= 0) {
        this.resultRequest = {
          state: null,
          message: null,
        };

        return;
      }

      if (newvalue >= 1000) {
        setTimeout(() => {
          this.confirmRedirectCooldown = newvalue - 1000;
        }, 1000);
        return;
      }

      if (newvalue < 1000) {
        setTimeout(() => {
          this.confirmRedirectCooldown = newvalue - newvalue;
        }, newvalue);
        return;
      }

      return;
    },
  },

  mounted() {
    this.token = this.$route.query["token"] ?? null;
    this.oldLogin = this.$route.query["oldLogin"] ?? null;
    this.newLogin = this.$route.query["newLogin"] ?? null;
  },

  methods: {
    updateLoginConfirm() {
      this.loading = true;

      this.$store
        .dispatch("user/updateLoginConfirm", {
          token: this.token,
          password: this.password,
          oldLogin: this.oldLogin,
          newLogin: this.newLogin,
        })
        .then(() => {
          this.$store
            .dispatch("user/login", {
              email: this.newLogin,
              password: this.password,
            })
            .then(() => {
              this.resultRequest.state = "success";
              this.resultRequest.message =
                "Votre mail de connexion a bien été mise à jour, vous allez être redirigé";
              this.confirmRedirectCooldown = 5000;

              setTimeout(() => {
                this.$router.push({ name: "home" });
              }, 5000);
            })
            .catch(() => {
              this.$store.dispatch("user/logout");

              this.resultRequest.state = "error";
              this.resultRequest.message =
                "Votre mail de connexion a bien été mise à jour mais nous n'arrivons pas à vous connecter, veuillez essayer vous meme, vous allez être redirigé sur la page de connexion";
              this.confirmRedirectCooldown = 5000;

              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, 5000);
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch((error) => {
          this.resultRequest.state = "error";
          this.resultRequest.message = error;
          this.loading = false;
        });
    },

    updateLoginRequest() {
      if (!this.newLogin) return;
      this.loading = true;

      this.$store
        .dispatch("user/updateLoginRequest", { newLogin: this.newLogin })
        .then(() => {
          this.email = null;
          this.resultRequest.state = "success";
          this.resultRequest.message =
            "Un lien vous a été envoyé par mail, ce lien sera valable 4H";
          this.loading = false;
        })
        .catch((error) => {
          this.resultRequest.state = "error";
          this.resultRequest.message = error;
          this.loading = false;
        });
    },
  },
};
</script>
