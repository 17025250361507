<template>
  <div
    class="container-fluid py-2 px-4"
  >
    <bandeau-block :loading="!loaded" />

    <preparation-block
      v-if="etatEnquete && etatEnquete === 'N'"
      class="mt-2"
    />

    <workflow-block
      v-if="etatEnquete && etatEnquete !== 'N'"
      class="mt-3"
      :etat-enquete="etatEnquete"
    />

    <div
      v-if="enqueteContrat"
      class="row"
    >
      <div class="col text-center my-4">
        <span
          role="button"
          @click="showMentions = true"
        >
          {{ $t('common.legalNotice') }}
        </span>
      </div>
    </div>

    <amsom-modal
      v-if="showMentions"
      id="modal-infos-RFR"
      close-option
      @close="showMentions = false"
    >
      <h4>Mentions légales</h4>

      <mention-legale-s-l-s
        v-if="enquete.loading === 0"
        :type="enqueteContrat.type"
        :nom="enqueteContrat.nom"
      />
    </amsom-modal>
  </div>
</template>

<script>
import BandeauBlock from "@/components/EnqueteHomePage/BandeauBlock.vue";
import PreparationBlock from "@/components/EnqueteHomePage/PreparationBlock.vue";
import WorkflowBlock from "@/components/EnqueteHomePage/WorkflowEnqueteBlock.vue";
import MentionLegaleSLS from "@/components/EnqueteHomePage/MentionsLegales/MentionLegaleSLS.vue";
import AmsomModal from "@/components/UI/AmsomModal.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "EnqueteHomePage",
  components: { WorkflowBlock, BandeauBlock, MentionLegaleSLS, PreparationBlock, AmsomModal },

  data() {
    return {
      showMentions: false,
      loaded: false,
    };
  },

  computed: {
    ...mapState(["enquete"]),

    ...mapGetters({
      selectedContratId: "user/getSelectedContrat",
    }),

    enqueteContrat() {
      return this.enquete.enqueteContrat;
    },

    etatEnquete() {
      return this.enquete.enqueteContrat?.etat;
    },
  },

  watch: {
    "selectedContratId": function () {
      this.loadDataOfContrat()
    },
  },

  mounted() {
    this.$store.commit("topbar/setBreadcrumb", [
      { icon: 'fa-solid fa-house', to: { name: 'home' } },
      { text: this.$t('breadcrumb.survey'), active: true },
    ]);

    this.loadDataOfContrat();
  },

  methods: {
    loadDataOfContrat() {
      if(this.selectedContratId){
        this.loaded = false;
        this.$store.dispatch("enquete/loadEnqueteContrat", this.selectedContratId)
          .finally(() => {
            this.loaded = true;
          });
      }

    },
  },
};
</script>
