<template>
  <div class="container-fluid py-2 px-4">
    <div
      v-if="loading"
      class="row mb-2 g-4"
    >
      <div class="col-12 col-md-6">
        <div class="card card-body">
          <amsom-skeleton />
        </div>
      </div>

      <div class="col-6 d-none d-md-block">
        <div class="card">
          <div class="card card-body">
            <amsom-skeleton :config="[8, 10, 6]" />
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="row mb-2 g-4"
    >
      <div class="col-12 col-md-6 order-md-first order-last">
        <assurance-description-panel
          :assurance="assurance.assurance"
        />
      </div>

      <div class="col-12 col-md-6">
        <assurance-info-panel
          :assurance="assurance.assurance"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AssuranceDescriptionPanel from '@/components/AssurancePage/AssuranceDescriptionPanel.vue';
import AssuranceInfoPanel from '@/components/AssurancePage/AssuranceInfoPanel.vue';
import AmsomSkeleton from '@/components/UI/AmsomSkeleton.vue';
import {mapGetters, mapState} from 'vuex';

export default {
  name: 'AssurancePage',

  components: {
    AssuranceDescriptionPanel,
    AssuranceInfoPanel,
    AmsomSkeleton
  },

  computed: {
    ...mapState(['assurance', 'user']),
    ...mapGetters({'selectedContratId': 'user/getSelectedContrat'}),

    loading() {
      return this.assurance.loading > 0 || this.user.contrats === null;
    }
  },

  watch: {
    'selectedContratId': function () {
      this.loadDataOfContrat()
    },
  },

  mounted() {
    this.$store.commit('topbar/setBreadcrumb',
                       [
                         { icon: 'fa-solid fa-house', to: { name: 'home' } },
                         {text: this.$t('breadcrumb.insurance'), active: true}
                       ]
    )
    this.loadDataOfContrat()
  },

  methods: {
    loadDataOfContrat() {
      if (this.selectedContratId) {
        this.$store.dispatch('assurance/getAssuranceByContratId', {'idContrat' : this.selectedContratId})
      }
    },
  }
}
</script>
