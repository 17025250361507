<template>
  <!-- Titre -->
  <div
    id="input-titre"
    aria-required="true"
    role="radiogroup"
    tabindex="-1"
    class="col-12 text-center"
  >
    <template
      v-for="(option, idx) in titreOptionsInput"
      :key="option.idx"
    >
      <div class="form-check form-check-inline">
        <input
          :id="'input-titre_option_' + idx"
          v-model="individu.titre"
          type="radio"
          required
          name="titre"
          :value="option.value"
          aria-required="true"
          class="form-check-input"
          :class="{ active: individu.titre === option.value }"
        >

        <label
          :for="'input-titre_option_' + idx"
          class="form-check-label"
        >{{
          option.text
        }}</label>
      </div>
    </template>
  </div>

  <!-- Prénom -->
  <div
    id="input-group-prenom"
    class="col-12 col-md-6"
    role="group"
  >
    <label
      for="input-prenom"
      class="form-label d-block"
    >
      {{ $t('tenants.informations.firstName') }} <span class="text-danger">*</span>
    </label>

    <input
      id="input-prenom"
      v-model.trim="individu.prenom"
      v-amsom-formater-upper-case
      class="form-control"
      name="prenom"
      type="text"
      :placeholder="$t('tenants.informations.firstNamePlaceholder')"
      required
      aria-required="true"
      maxlength="50"
    >
  </div>

  <!-- Nom de naissance -->
  <div
    id="input-group-nom-naissance"
    class="col-12 col-md-6"
    role="group"
  >
    <label
      for="input-nom-naissance"
      class="form-label d-block"
    >
      {{ $t('tenants.informations.birthLastName') }} <span class="text-danger">*</span>
    </label>

    <input
      id="input-nom-naissance"
      v-model.trim="individu.nomNaissance"
      v-amsom-formater-upper-case
      class="form-control"
      name="nomNaissance"
      type="text"
      :placeholder="$t('tenants.informations.birthLastNamePlaceholder')"
      required
      aria-required="true"
      maxlength="50"
    >
  </div>

  <!-- Nom d'usage -->
  <div
    id="input-group-nom"
    class="col-12 col-md-6"
    role="group"
  >
    <label
      for="input-nom"
      class="form-label d-block"
    > {{ $t('tenants.informations.usageLastName') }} </label>

    <input
      id="input-nom"
      v-model.trim="individu.nom"
      v-amsom-formater-upper-case
      class="form-control"
      name="nom"
      type="text"
      :placeholder="$t('tenants.informations.usageLastNamePlaceholder')"
      maxlength="50"
    >
  </div>

  <!-- Date de naissance -->
  <div
    id="input-group-date-naissance"
    class="col-12 col-md-6"
    role="group"
  >
    <label
      for="input-date-naissance"
      class="form-label d-block"
    >
      {{ $t(individu.titre === 'Mme' ? 'tenants.informations.birthDateWoman' : 'tenants.informations.birthDateMan') }}
      <span class="text-danger">*</span>
    </label>

    <input
      id="input-date-naissance"
      v-model="dateNaissance"
      class="form-control"
      type="date"
      name="dateNaissance"
      :placeholder="$t('tenants.informations.birthDatePlaceholder')"
      required
      :max="maxDateNaissance"
    >
  </div>

  <!-- Lieu de naissance -->
  <template v-if="showLieuNaissance">
    <autocomplete-city
      v-model="fullLieuNaissance"
      zip-code-required
      maxlength="25"
      :pays-options="paysOptions"
      required
      :city-label="$t('tenants.informations.birthPlace')"
    />
  </template>

  <div
    v-if="isConflictEtatCivil"
    class="col-12 bg-danger rounded-3 p-2 shadow text-light text-center"
  >
    {{ $t('tenants.informations.tooMuchModifications') }}
  </div>

  <div
    v-if="needPjEtatCivil || gedPjIdentite"
    class="col-12"
  >
    <amsom-overlay :loading="loadingPjIdentite">
      <upload-file
        v-model="individu.pjEtatCivil"
        title="Pièce(s) justificative(s)"
        name="etat-civil"
        pj-name="Justificatif d'identité *"
        class="fw-bold"
        sub-text="Copie de la carte d'identité, passeport, acte de naissance, page du livret de famille mentionnant la naissance de l'enfant ou titre de séjour."
        :file-name="etatCivilFileName"
        required
        :ged-file="gedPjIdentite"
        @delete-file="delete individu.pjEtatCivil"
        @file-loading="updateFileLoading"
      />
    </amsom-overlay>
  </div>
</template>

<script>
import AutocompleteCity from "@/components/AutocompleteCity.vue";
import UploadFile from "@/components/UploadFile.vue";
import moment from "moment-timezone";
import { mapState, mapGetters } from "vuex";
import { normalize } from "@/js/text.js";
import { sortArray } from "@/js/array.js";
import fileUtils from "@/js/fileUtils.js";
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";

export default {
  name: "LocataireContactBlock",

  components: {
    AutocompleteCity,
    UploadFile,
    AmsomOverlay,
  },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },

    showLieuNaissance: {
      type: Boolean,
      default: true,
    },

    needPjEtatCivil: {
      type: Boolean,
      default: false,
    },

    isConflictEtatCivil: {
      type: Boolean,
      default: false,
    },

    titreOptions: {
      type: Array,
      default: null,
    },
  },

  emits: ["update:modelValue", "fileLoading"],

  data() {
    return {
      individu: this.modelValue,
      loadingPjIdentite: false,
      gedPjIdentite: null,
    };
  },

  computed: {
    ...mapState(["utilities"]),
    ...mapGetters({ getNomPrenom: "contrat/getNomPrenom" }),

    dateNaissance: {
      get() {
        let date = this.individu?.dateNaissance;
        return date ? moment.unix(date).format("YYYY-MM-DD") : null;
      },

      set(value) {
        let data = value ? moment(value, "YYYY-MM-DD").unix().toString() : null;
        this.individu.dateNaissance = data;
      },
    },

    fullLieuNaissance: {
      get() {
        if (
          this.individu?.lieuNaissance &&
          this.individu?.zipNaissance &&
          this.individu?.paysNaissance
        ) {
          return {
            city: {
              nom: this.individu?.lieuNaissance,
              pays: this.individu?.paysNaissance,
            },

            cp: this.individu?.zipNaissance,
          };
        } else {
          return null;
        }
      },

      set(value) {
        this.individu.lieuNaissance = value?.city?.nom;
        this.individu.zipNaissance = value?.cp;
        this.individu.paysNaissance = value?.city?.pays;
      },
    },

    maxDateNaissance() {
      return moment().format("YYYY-MM-DD");
    },

    paysOptions() {
      if (!this.utilities.pays) return [];

      let data = this.utilities.pays
        .filter((pays) => pays.id !== "FRA")
        .map((pays) => {
          return { id: pays.id, nom: normalize(pays.nom, false) };
        });

      return sortArray(data, "nom", true);
    },

    etatCivilFileName() {
      return (
        this.getNomPrenom(this.individu) +
        " - Carte d'identité " +
        moment().format("YYYY-MM-DD")
      );
    },

    titreOptionsInput() {
      if(this.titreOptions){
        return this.titreOptions
      }

      return [
        { value: "M.", text: this.$t('common.sir') },
        { value: "Mme", text: this.$t('common.madam') },
      ];
    },
  },

  watch: {
    individu: {
      handler(value) {
        this.$emit("update:modelValue", value);
      },

      deep: true,
    },

    needPjEtatCivil: function (newValue, oldValue) {
      if (oldValue && !newValue) delete this.individu.pjEtatCivil;
    },

    loadingPjIdentite(value) {
      this.$emit("fileLoading", value);
    },

    // modelValue: {
    //   handler(value) {
    //     this.individu = value;
    //   },

    //   deep: true,
    // },
  },

  mounted() {
    this.$store.dispatch("utilities/loadPays");

    if (this.individu.justificatifs) {
      let pj = this.individu.justificatifs.find(
        (pj) => pj.typeFichier === "identite"
      );
      if (pj) {
        this.loadingPjIdentite = true;
        this.$store.dispatch("ged/loadPdfById", pj.id).then((data) => {
          this.gedPjIdentite = new window.File_native([data], pj.libelle, {
            type: data.type,
          });
          this.loadingPjIdentite = false;
        });
      }
    }
  },

  // watchEffect() {
  //   this.$emit("update:modelValue", this.individu);
  // },

  methods: {
    updateFileLoading(value) {
      this.$emit("fileLoading", value);
    },

    openFile(id) {
      this.loading++;
      this.$store.dispatch("ged/loadPdfById", id).then((data) => {
        fileUtils.openFileBlob(data);
        this.loading--;
      });
    },
  },
};
</script>
