<template>
  <div
    id="edition-locataire"
    class="ps-3"
  >
    <amsom-overlay :loading="!!locatairePanel.loading">
      <h3 class="my-3">
        Départ de {{ getNomPrenom(locataire) }}
      </h3>

      <form @submit.prevent="exitLocataire">
        <div class="row gy-3 gx-3 justify-content-start text-start">
          <locataire-sortie-block
            v-model="sortie"
            :ask-motif-depart="!isOccupant"
            :ask-date-sortie="true"
            :need-pj-sortie="isNeededPjSortie(locataire)"
            :min-date-sortie="minDateSortie"
            @file-loading="updateFileLoading"
          />

          <template v-if="!isOccupant && !sortie.decede">
            <div
              class="col-12 my-2"
            >
              <div class="row mt-4">
                <div class="h6">
                  {{ $t('tenants.exitInformations.newContactOfTenant', {name: getNomPrenom(locataire)}) }}
                </div>

                <locataire-contact-block
                  v-model="sortie"
                  required-mail
                  :required-mobile="!sortie.fixe"
                />
              </div>


              <div class="row mt-4">
                <h6>
                  {{ $t('tenants.exitInformations.newAddressOfTenant', {name: getNomPrenom(locataire)}) }}
                </h6>

                <locataire-adresse-block v-model="sortie" />
              </div>
            </div>
          </template>

          <div class="col-12 text-end mt-4">
            <button
              id="btn-exit-locataire-confirm"
              role="button"
              type="submit"
              class="btn btn-danger rounded-5"
              :disabled="fileLoadingCount !== 0"
            >
              {{ sortie.decede ? $t('tenants.confirmation.confirmDeath') : $t('tenants.confirmation.confirmDeparture') }}
              <font-awesome-icon
                :icon="['fas', 'arrow-right']"
                class="ms-1"
              />
            </button>
          </div>
        </div>
      </form>
    </amsom-overlay>
  </div>
</template>

<script>
import {toast} from "vue3-toastify";
import {mapGetters, mapState} from "vuex";
import {sortArray} from "@/js/array.js";
import {normalize} from "@/js/text.js";
import LocataireSortieBlock from "@/components/Feat/IndividuFeeding/LocataireSortieBlock.vue";
import LocataireContactBlock from "@/components/Feat/IndividuFeeding/LocataireContactBlock.vue";
import LocataireAdresseBlock from "@/components/Feat/IndividuFeeding/LocataireAdresseBlock.vue";
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";
import moment from "moment-timezone";

export default {
  name: "ExitLocataireBlock",
  components: {LocataireSortieBlock, LocataireContactBlock, LocataireAdresseBlock, AmsomOverlay},

  props: {
    locataire: {
      type: Object,
      required: true
    },

    oldEditedLocataire: {
      type: Object,

      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      regexFixe: process.env.VUE_APP_REGEX_FIXE,
      regexMobile: process.env.VUE_APP_REGEX_MOBILE,
      regexMail: process.env.VUE_APP_REGEX_EMAIL,

      sortie: {},

      fileLoadingCount: 0,

      fixe: null,
      mobile: null,
      mail: null,

      appt: null,
      numRue: null,
      adr1: null,
      adr2: null,
      adr3: null,
      lieuDit: null,
      communeData: null,
    }
  },

  computed: {
    ...mapState(['locatairePanel', 'utilities']),

    ...mapGetters({
      'selectedContratId': "user/getSelectedContrat",
      getTypeLocataire: 'contrat/getTypeLocataire',
      isNeededPjSortie: 'locataireFeeding/isNeededPjSortie',
      getNomPrenom: "contrat/getNomPrenom",
    }),

    minDateSortie() {
      return moment().format('YYYY-MM-DD')
    },

    paysOptions() {
      if (!this.utilities.pays) return [];

      let data = this.utilities.pays.filter((pays) => pays.id !== "FRA").map((pays) => {
        return { id: pays.id, nom: normalize(pays.nom,false) };
      });

      return sortArray(data,'nom', true);
    },

    isOccupant() {
      return this.getTypeLocataire(this.locataire) === 'occupant'
    },

    fullCommune: {
      get(){
        if(this.oldEditedLocataire){
          return {
            city: {
              nom: this.oldEditedLocataire.commune,
              pays: this.oldEditedLocataire.pays,
            },

            cp: this.oldEditedLocataire.cp,
          };
        }else{
          return null
        }
      },

      set(value){
        if(!value){
          this.communeData = null;
          return;
        }

        this.communeData = {
          city: value?.city?.nom,
          cp: value?.cp,
          pays: value?.city?.pays,
        };
      }
    }
  },

  mounted() {
    this.sortie.fixe = this.locataire.fixe
    this.sortie.mobile = this.locataire.mobile
    this.sortie.mail = this.locataire.mail
    if(this.oldEditedLocataire){
      let individu = this.oldEditedLocataire
      // this.sortie.motifDepart = individu.motifDepart
      this.sortie.dateDepart = individu.dateDepart
      this.sortie.appt = individu.appt
      this.sortie.numRue = individu.numRue
      this.sortie.adr1 = individu.adr1
      this.sortie.adr2 = individu.adr2
      this.sortie.adr3 = individu.adr3
      this.sortie.lieuDit = individu.lieuDit
      this.sortie.fixe = individu.fixe
      this.sortie.mobile = individu.mobile
      this.sortie.mail = individu.mail
      this.sortie.commune = individu.commune
      this.sortie.pays = individu.pays
      this.sortie.cp = individu.cp
    }
  },

  methods: {
    updateFileLoading(value) {
      if (value) this.fileLoadingCount++;
      else this.fileLoadingCount--;
    },

    async exitLocataire() {
      let data = {}
      data.idIndividu = this.locataire.idIndividu
      data.idContrat = this.locataire.idContrat
      data.pjExitIndividu = this.sortie.pjDepart
      data.dateDepart = this.sortie.dateDepart
      if(!this.sortie.decede){
        data.appt = this.sortie.appt
        data.numRue = this.sortie.numRue
        data.adr1 = this.sortie.adr1
        data.adr2 = this.sortie.adr2
        data.adr3 = this.sortie.adr3
        data.lieuDit = this.sortie.lieuDit
        data.cp = this.sortie?.cp
        data.commune = this.sortie?.commune
        data.pays = this.sortie?.pays
        data.fixe = this.sortie.fixe
        data.mobile = this.sortie.mobile
        data.mail = this.sortie.mail
        data.decede = this.sortie.decede
      }

      if (this.oldEditedLocataire?.idModification) {
        let cancelPayload = {
          data: {
            idModification: this.oldEditedLocataire.idModification
          }
        }

        await this.$store.dispatch('locatairePanel/cancelModificationIndividuContrat', cancelPayload)
      }

      this.$store.dispatch('locatairePanel/exitIndividuContrat', data)
        .then(() => {
          this.$store.dispatch('contrat/loadIndividuInfosByContrat', this.selectedContratId)
            .then(() => {
              this.$store.commit('locatairePanel/setSelectedLocataireId', this.locataire.id)
            })
            .then(() => {
              this.$store.commit('locatairePanel/setMode', 'confirm')
            })
        })
        .catch((error) => {
          toast.error(error)
        })
    },

    // cancel() {
    //   this.$store.commit('locatairePanel/setMode', 'view')
    // }
  }
}
</script>
