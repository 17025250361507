<template>
  <div
    id="infos-locataire"
  >
    <amsom-overlay :loading="locatairePanel.loading > 0">
      <div class="row ms-2 ms-md-0">
        <div class="col-12 col-md-5">
          <div class="row justify-content-center gy-2 gx-2">
            <div
              class="col-12 text-center pt-1 d-none d-md-block"
            >
              <!--
                <font-awesome-icon
                :icon="['fas', 'fa-circle-user']"
                class="text-primary mt-2 fa-6x"
                />
              -->
              <img
                :src="locataireImg"
                class="w-50"
                alt="Image illustrant le profil du locataire"
              >
            </div>

            <div class="col-12 text-center mt-2">
              <span class="h6"> {{ locataire.nom ?? locataire.nomNaissance }} <span
                class="text-capitalize"
              >{{ locataire.prenom.toLowerCase() }} </span></span>

              <span
                class="d-block text-capitalize text-success"
              >
                {{ $te(('common.' + locataire.type.toLowerCase())) ? $t(('common.' + locataire.type.toLowerCase())) : locataire.type
                }}
              </span>
            </div>

            <div
              v-if="locataire.mail"
              class="col-12 d-flex align-items-center"
            >
              <font-awesome-icon
                :icon="['fas', 'fa-envelope']"
                size="sm"
                :class="iconClass"
              />

              <small id="mail-field">
                {{ locataire.mail }}
              </small>
            </div>

            <div
              v-if="locataire.fixe"
              class="col-12 d-flex align-items-center"
            >
              <font-awesome-icon
                :icon="['fas', 'fa-phone']"
                size="sm"
                :class="iconClass"
              />

              <span id="tel-fixe-field">
                {{ locataire.fixe.split('').reduce((a, e, i) => a + e + (i % 2 === 1 ? ' ' : ''), '') }}</span>
            </div>

            <div
              v-if="locataire.mobile"
              class="col-12 d-flex align-items-center"
            >
              <font-awesome-icon
                :icon="['fas', 'fa-mobile-screen']"
                size="sm"
                :class="iconClass"
              />

              <span id="tel-mobile-field">
                {{ locataire.mobile.split('').reduce((a, e, i) => a + e + (i % 2 === 1 ? ' ' : ''), '') }}</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-7 d-flex">
          <div class="d-none d-md-block border-start border-2 border-gray-500" />

          <div class="row gx-0 gy-1 ms-0 ms-md-3 text-start">
            <div class="col-12">
              <hr class="d-block d-md-none mx-auto w-75">

              <h5>{{ $t('tenants.informations.header') }}</h5>
            </div>
            <!-- Titre -->
            <div
              v-if="locataire.titre"
              class="col-12"
            >
              <strong>{{ $t('tenants.informations.gender') }}</strong>
              :
              <span id="titre-field">{{ locataire.titre === 'Mme' ? $t('common.madam') : $t('common.sir') }}</span>
            </div>
            <!-- Nom de naissance -->
            <div
              v-if="locataire.nomNaissance"
              class="col-12"
            >
              <strong>{{ $t('tenants.informations.birthLastName') }}</strong>
              :
              <span id="nom-naissance-field">{{ locataire.nomNaissance }}</span>
            </div>
            <!-- Nom d'usage -->
            <div
              v-if="locataire.nom"
              class="col-12"
            >
              <strong>{{ $t('tenants.informations.usageLastName') }}</strong>
              :
              <span id="nom-field">{{ locataire.nom }}</span>
            </div>
            <!-- Prénom -->
            <div
              v-if="locataire.prenom"
              class="col-12"
            >
              <strong>{{ $t('tenants.informations.firstName') }}</strong>
              :
              <span
                id="prenom-field"
                class="text-capitalize"
              >{{ locataire.prenom.toLowerCase() }}</span>
            </div>
            <!-- Date de naissance -->
            <div
              v-if="locataire.dateNaissance"
              class="col-12"
            >
              <strong>
                {{ $t(locataire.titre === 'Mme' ? 'tenants.informations.birthDateWoman' : 'tenants.informations.birthDateMan')
                }}
              </strong>
              :
              <span id="date-naissance-field">{{ unixToDateString(locataire.dateNaissance) }}</span>
            </div>
            <!-- Lieu de naissance -->
            <div
              v-if="locataire.lieuNaissance"
              class="col-12"
            >
              <strong>{{ $t('tenants.informations.birthPlace') }}</strong>
              :
              <span
                id="lieu-naissance-field"
                class="text-capitalize"
              >{{ locataire.lieuNaissance.toLowerCase() }}</span>
            </div>
            <!-- Situation familiale -->
            <div
              v-if="!utilities.situationFamiliale"
              class="col-12 spinner-border text-primary"
              role="status"
            />

            <div
              v-else-if="locataire.situationFamiliale"
              class="col-12"
            >
              <strong>{{ $t('tenants.informations.familySituation') }}</strong>
              :
              <span
                id="situation-familiale-field"
              >{{ getSituationFamilialeById(locataire.situationFamiliale)?.libelle ?? 'Inconnu'
              }}</span>
            </div>
            <!-- Lien de parenté avec le(s) signataire(s) -->
            <div
              v-if="!utilities.lienParente"
              class="col-12 spinner-border text-primary"
              role="status"
            />

            <div
              v-if="locataire.lienParente"
              class="col-12"
            >
              <strong>{{ $t('tenants.informations.signatoryRelationship') }}</strong>
              :
              <span id="lien-parente-field">{{ getLienParenteById(locataire.lienParente)?.libelle ?? 'Inconnu' }}</span>
            </div>
            <!-- Situation professionnelle -->
            <div
              v-if="!utilities.situationSociopro"
              class="col-12 spinner-border text-primary"
              role="status"
            />

            <div
              v-else-if="locataire.situationSociopro"
              class="col-12"
            >
              <strong>{{ $t('tenants.informations.professionalSituation') }}</strong>
              :
              <span
                id="situation-sociopro-field"
              >{{ getSituationSocioproById(locataire.situationSociopro)?.libelle ?? 'Inconnue'
              }}</span>
            </div>
            <!-- Type de contrat -->
            <div
              v-if="!utilities.contratTravail"
              class="col-12 spinner-border text-primary"
              role="status"
            />

            <div
              v-else-if="locataire.contratTravail"
              class="col-12"
            >
              <strong>{{ $t('tenants.informations.workContractType') }}</strong>
              :
              <span id="contrat-travail-field">{{ getContratTravailById(locataire.contratTravail)?.libelle ?? 'Inconnu'
              }}</span>
            </div>
            <!-- Employeur -->
            <div
              v-if="locataire.nomEmployeur"
              class="col-12"
            >
              <strong>{{ $t('tenants.informations.employer') }}</strong>
              :
              <span id="employeur-field">{{ locataire.nomEmployeur }}</span>
            </div>

            <!-- CMI -->
            <div class="col-12">
              <strong>{{ $t('tenants.informations.cmi') }}</strong>
              :
              <span id="cmi">
                <font-awesome-icon
                  v-if="locataire.carteInvalidite"
                  icon="fa-solid fa-check"
                  class="text-success"
                />

                <font-awesome-icon
                  v-else
                  icon="fa-solid fa-times"
                  class="text-danger"
                /></span>
            </div>

            <template v-if="locataire.type === 'occupant'">
              <div class="col-12">
                <strong>{{ $t('tenants.informations.isDependant') }}</strong>
                :
                <span id="a-charge">
                  <font-awesome-icon
                    v-if="locataire.aCharge"
                    icon="fa-solid fa-check"
                    class="text-success"
                  />

                  <font-awesome-icon
                    v-else
                    icon="fa-solid fa-times"
                    class="text-danger"
                  /></span>
              </div>

              <div class="col-12">
                <strong>{{ $t('tenants.informations.isCustodyRight') }}</strong>
                :
                <span id="droit-garde-field">
                  <font-awesome-icon
                    v-if="locataire.dtgard"
                    icon="fa-solid fa-check"
                    class="text-success"
                  />

                  <font-awesome-icon
                    v-else
                    icon="fa-solid fa-times"
                    class="text-danger"
                  /></span>
              </div>

              <div class="col-12">
                <strong>{{ $t('tenants.informations.isVisitingRight') }}</strong>
                :
                <span id="droit-visite-field">
                  <font-awesome-icon
                    v-if="locataire.dtvis"
                    icon="fa-solid fa-check"
                    class="text-success"
                  />

                  <font-awesome-icon
                    v-else
                    icon="fa-solid fa-times"
                    class="text-danger"
                  /></span>
              </div>
            </template>
          </div>
        </div>

        <div class="col-12 mt-4">
          <div
            v-if="currentContratInfos.enqueteEnCours"
            class="text-end text-secondary"
          >
            <span>
              {{ $t('tenants.surveyInProgressWarning') }}
            </span>
          </div>

          <div
            v-else-if="!validLocataireState.includes(locataire?.etat)"
            class="text-end"
          >
            <div
              v-if="locataire?.etat === 'I'"
              class="btn-group rounded-5 overflow-hidden"
              role="group"
              aria-label="Group"
            >
              <button
                id="btn-annuler-modification"
                class="btn btn-danger"
                type="button"
                @click="cancelModification()"
              >
                {{ $t('common.cancel') }}
                <font-awesome-icon
                  icon="fa-solid fa-times"
                  class="ms-1"
                />
              </button>

              <button
                role="button"
                type="button"
                class="btn btn-primary"
                @click="corriger()"
              >
                {{ $t('common.correct') }}
                <font-awesome-icon
                  :icon="['fas', 'arrow-right']"
                  class="me-1 d-none d-sm-inline"
                />
              </button>
            </div>

            <span
              v-else
              class="text-warning p-2"
            >
              {{ $t('tenants.validationWaiting') }}
            </span>
          </div>

          <div
            v-else-if="isLocataireAlreadyExited(locataire)"
            class="text-end"
          >
            <!-- locataire sorti -->
            <span
              class="text-secondary"
            >
              {{ $t(locataire.titre === 'Mme' ? 'tenants.informations.departureDateWoman' : 'tenants.informations.departureDateMan', {date: unixToDateString(getLocataireDateSortie(locataire))})
              }}
            </span>
          </div>

          <div
            v-else-if="locataire.fin"
            class="text-end"
          >
            <span
              class="text-secondary"
            >
              {{ $t('tenants.informations.departureDateComming', {date: unixToDateString(getLocataireDateSortie(locataire))})
              }}
            </span>
          </div>

          <div
            v-else
            class="d-grid d-sm-block text-sm-end gap-2"
          >
            <button
              id="btn-exit-locataire"
              role="button"
              type="button"
              class="mx-1 btn btn-danger rounded-5"
              @click="setMode('exit')"
            >
              {{ $t('tenants.informations.departureBtn') }}
              <font-awesome-icon
                icon="fa-solid fa-user-minus"
                class="ms-1"
              />
            </button>

            <button
              id="btn-edit-locataire"
              role="button"
              type="button"
              class="mx-1 btn btn-primary rounded-5"
              @click="setMode('edit')"
            >
              {{ $t('common.modify') }}
              <font-awesome-icon
                icon="fa-solid fa-user-edit"
                class="ms-1"
              />
            </button>
          </div>
        </div>
      </div>
    </amsom-overlay>
  </div>
</template>

<script>
import {unixToDateString} from "@/js/text.js";
import {mapGetters, mapState} from "vuex";
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";
import locataireImg from '@/assets/images/contratPage/locataire.svg'

export default {
  name: "DisplayLocataireBlock",
  components: {AmsomOverlay},
  props: ["locataire", "validLocataireState"],

  data: () => ({
    iconClass: "p-2 bg-gray bg-opacity-50 text-secondary rounded-3 me-2 box fa-fw",
    locataireImg: locataireImg,
  }),

  computed: {
    ...mapState(['utilities', 'contrat', 'locatairePanel']),

    ...mapGetters({
      'getContratTravailById': "utilities/getContratTravailById",
      'getSituationSocioproById': 'utilities/getSituationSocioproById',
      'getSituationFamilialeById': 'utilities/getSituationFamilialeById',
      'getLienParenteById': 'utilities/getLienParenteById',
      'isLocataireAlreadyExited': 'contrat/isLocataireAlreadyExited',
      'getLocataireDateSortie': 'contrat/getLocataireDateSortie',
      'selectedContratId': "user/getSelectedContrat"
    }),

    currentContratInfos() {
      return this.contrat.currentContratInfos
    },

    newLocataire() {
      return !this.locataire.idIndividu
    },

    editedLocataire() {
      let modification = this.locatairePanel.editedLocataire
      let individu = {}
      Object.assign(individu, this.currentLocataire)

      for (let key of Object.keys(modification)) {
        if (key !== 'id' && modification[key] !== null && modification[key] !== undefined) {
          individu[key] = modification[key]
        }
      }

      individu.idModification = modification.id

      return individu
    }
  },

  methods: {
    unixToDateString,

    setMode(val) {
      this.$store.commit('locatairePanel/setMode', val)
    },

    async corriger() {
      await this.$store.dispatch('locatairePanel/loadModificationsIndividuContrat', this.locataire)
      if (this.locatairePanel.editedLocataire.dateDepart)
        this.$store.commit('locatairePanel/setMode', 'correctExit')
      else
        this.$store.commit('locatairePanel/setMode', 'correctEdit')
    },

    async cancelModification() {
      await this.$store.dispatch('locatairePanel/loadModificationsIndividuContrat', this.locataire)

      let payload = {
        data: {
          idModification: this.editedLocataire.idModification
        }
      }

      this.$store.dispatch('locatairePanel/cancelModificationIndividuContrat', payload)
        .then(() => {
          this.$store.dispatch('contrat/loadIndividuInfosByContrat', this.selectedContratId)

          if (this.newLocataire) {
            this.$store.commit('locatairePanel/setSelectedLocataireId', null)
            this.$store.commit('locatairePanel/setMode', 'list')
          } else {
            this.$store.commit('locatairePanel/setMode', 'view')
          }
        })
    },
  }
}
</script>
