<template>
  <div class="container-fluid py-3">
    <div
      v-if="
        getRejetsGrouped &&
          Object.keys(getRejetsGrouped).length > 0 &&
          enquete.loading === 0
      "
      class="text-center"
    >
      <!-- icon pour afficher la modal des rejets  -->
      <span
        class="p-2 text-danger rounded"
        role="button"
        @click="$refs.rejetModal.showModal = true"
      >
        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />

        Votre enquête a été rejetée (<u>voir plus</u>)
      </span>
    </div>

    <rejet-modal
      v-if="getRejetsGrouped && Object.keys(getRejetsGrouped).length > 0"
      ref="rejetModal"
      :rejets="getRejetsGrouped"
      :show="enqueteContrat.etat === 'I'"
      @set-current-step="currentStep = $event"
      @go-to-individu="goToIndividu"
    />

    <amsom-modal
      v-else-if="
        showInfoModal && enquete.enqueteIndividus && enquete.rejets !== null
      "
      close-option
      @close="showInfoModal = false"
    >
      <h5 class="mb-4 text-center">
        La saisie de votre enquête se déroule en 3 étapes :
      </h5>

      <amsom-vertical-stepper :steps="stepsDescription" />

      <div class="text-center">
        <button
          role="button"
          class="btn btn-success rounded-5"
          @click="showInfoModal = false"
        >
          C'est parti !
        </button>
      </div>
    </amsom-modal>

    <amsom-overlay
      v-if="enqueteContrat"
      :loading="enquete.loading > 0"
    >
      <template v-if="!isExpired && !isTooEarly">
        <workflow-process-horizontal-block
          :process="{ currentStep: currentStep,
                      steps: steps }"
          class="my-3"
          @go-to="currentStep = $event"
        />

        <div class="py-2">
          <individu-enquete-block
            v-if="currentStep === 1"
            ref="individuEnqueteBlock"
            :locataire-list="listIndividus"
            :can-prev="currentStep > 1"
            @next="nextPage"
            @prev="prevPage"
          />

          <fiscalite-foyer-enquete-block
            v-else-if="currentStep === 2"
            :can-prev="currentStep > 1"
            @next="nextPage"
            @prev="prevPage"
          />

          <recap-enquete-block
            v-else-if="currentStep === 3"
            @set-current-step="currentStep = $event"
            @go-to-individu="goToIndividu"
          />
        </div>
      </template>

      <div v-else>
        <div class="row">
          <div class="col-12 col-lg-6 text-center text-primary">
            <template v-if="isExpired">
              <div class="mt-3">
                <span
                  class="fw-bold"
                  style="font-size: 3em"
                >Cette enquête ne peut plus être saisie depuis le
                  {{ enqueteContrat.fin3 }}</span>
              </div>

              <div class="text-end mt-3 me-5">
                <router-link :to="{ name: 'home' }">
                  <button class="btn btn-secondary">
                    Retourner à l'accueil
                  </button>
                </router-link>
              </div>
            </template>

            <template v-else-if="isTooEarly">
              <div class="mt-3">
                <span
                  class="fw-bold"
                  style="font-size: 3em"
                >Cette enquête ne pourra être saisie qu'à partir du
                  {{ enqueteContrat.debut }}</span>
              </div>

              <div class="text-end mt-3 me-5">
                <router-link :to="{ name: 'home' }">
                  <button class="btn btn-secondary">
                    Retourner à l'accueil
                  </button>
                </router-link>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="row mt-auto">
        <div class="col text-center mb-2">
          <a
            href="#"
            class="hoverable"
            @click="showMentions = true"
          >
            {{ $t('common.legalNotice') }}
          </a>
        </div>
      </div>
    </amsom-overlay>

    <amsom-modal
      v-if="showMentions"
      id="modal-infos-RFR"
      close-option
      @close="showMentions = false"
    >
      <h4>Mentions légales</h4>

      <mention-legale-s-l-s
        v-if="enquete.loading === 0"
        :type="enqueteContrat.type"
        :nom="enqueteContrat.nom"
      />
    </amsom-modal>
  </div>
</template>

<script>
import FiscaliteFoyerEnqueteBlock from "@/components/EnqueteEntryPage/FiscaliteFoyerEnqueteBlock.vue";
import mentionLegaleSLS from "@/components/EnqueteHomePage/MentionsLegales/MentionLegaleSLS.vue";
import { mapState, mapGetters } from "vuex";
import RecapEnqueteBlock from "@/components/EnqueteEntryPage/RecapEnqueteBlock.vue";
import moment from "moment-timezone";
import IndividuEnqueteBlock from "@/components/EnqueteEntryPage/IndividuEnqueteBlock.vue";
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";
import pageUtils from "@/js/pageUtils.js";
import AmsomModal from "@/components/UI/AmsomModal.vue";
import RejetModal from "@/components/EnqueteEntryPage/RejetModal.vue";
import WorkflowProcessHorizontalBlock from "@/components/WorkflowProcessHorizontalBlock.vue";
import AmsomVerticalStepper from "@/components/UI/AmsomVerticalStepper.vue";

export default {
  name: "EnqueteEntryPage",

  components: {
    RecapEnqueteBlock,
    FiscaliteFoyerEnqueteBlock,
    MentionLegaleSLS: mentionLegaleSLS,
    IndividuEnqueteBlock,
    AmsomOverlay,
    AmsomModal,
    RejetModal,
    WorkflowProcessHorizontalBlock,
    AmsomVerticalStepper,
  },

  data() {
    return {
      showMentions: false,
      showInfoModal: true,
      currentStep: 1,
      steps: [
        {
          texte: "Occupation",
          key: "occ",
          numero: 1,
        },
        {
          texte: "Fiscalité",
          key: "fisc",
          numero: 2,
        },
        {
          texte: "Récapitulatif",
          key: "recap",
          numero: 3,
        },
      ],
    };
  },

  computed: {
    ...mapState(["enquete"]),

    ...mapGetters({
      getRejetsGrouped: "enquete/getRejetsGrouped",
    }),

    listIndividus() {
      if(!this.enquete.enqueteIndividus)
        return [];

      let individus = this.enquete.enqueteIndividus;

      // Trier les locataires selon le type et, en cas d'égalité de type, selon l'ancienneté
      individus.sort((a, b) => {
        // Tri par type : signataire, cosignataire, occupant
        const typeOrder = {
          signataire: 1,
          cosignataire: 2,
          occupant: 3
        }


        if (typeOrder[a.type] !== typeOrder[b.type]) {
          return typeOrder[a.type] - typeOrder[b.type];
        } else {
          // Si les types sont identiques, tri par la propriété "debut"
          // if (a.debut !== b.debut) {
          //     return a.debut - b.debut;
          // }
          return b.idIndividu - a.idIndividu;
        }
      });

      return individus;
    },

    enqueteContrat() {
      return this.enquete.enqueteContrat;
    },

    stepsDescription() {
      return [
        {
          id: 1,
          title: "Occupation de votre foyer",
          titleColor: "primary",
          description:
            "Mettez à jour les informations des locataires présents dans votre foyer",

          descriptionColor: "secondary",
          icon: ["fas", "users"],
          iconColor: "primary",
        },
        {
          id: 2,
          title: "Fiscalité de votre foyer",
          titleColor: "primary",
          description:
            "Renseignez les revenus déclarés auprès de l'administration fiscale au moyen de votre avis d'imposition",

          descriptionColor: "secondary",
          icon: ["fas", "euro-sign"],
          iconColor: "primary",
        },
        {
          id: 3,
          title: "Validation",
          titleColor: "primary",
          description:
            "Vérifiez les informations saisies et validez votre enquête",

          descriptionColor: "secondary",
          icon: ["fas", "pen"],
          iconColor: "primary",
        },
      ];
    },

    isExpired() {
      return (
        this.enqueteContrat &&
        this.enqueteContrat.fin3 &&
        this.enqueteContrat.fin3 < moment().unix()
      );
    },

    isTooEarly() {
      return (
        this.enqueteContrat &&
        this.enqueteContrat.debut &&
        this.enqueteContrat.debut > moment().unix()
      );
    },
  },

  watch: {
    currentStep: function () {
      pageUtils.autoScroll();
    },
  },

  async mounted() {
    this.$store.commit("topbar/setBreadcrumb", [
      { icon: 'fa-solid fa-house', to: { name: 'home' } },
      { text: this.$t('breadcrumb.survey'), to: { name: "enqueteHome" } },
      { text: this.$t('breadcrumb.requestInput'), active: true },
    ]);

    this.currentStep = 1;

    this.$store
      .dispatch("enquete/initEnquete", {
        idContrat: this.enqueteContrat.idContrat,
        idEnquete: this.enqueteContrat.idEnquete,
      })
      .then(() => {
        this.$store.dispatch(
          "enquete/loadRejetJusitficatif",
          this.enqueteContrat.id
        );
        this.$store.dispatch("enquete/loadEnqueteIndividus", {
          idContrat: this.enqueteContrat.idContrat,
          idEnquete: this.enqueteContrat.idEnquete,
        });
      });
  },

  methods: {
    nextPage() {
      this.currentStep += 1;
    },

    prevPage() {
      this.currentStep -= 1;
    },

    goToIndividu(individu) {
      this.currentStep = 1;
      this.$nextTick(() => {
        this.$refs.individuEnqueteBlock.selectIndividu(individu);
      });
    },
  },
};
</script>
