<template>
  <table class="overflow-auto table table-borderless table-sm">
    <modified-field
      v-if="rib?.libelleRib"
      :name="$t('prelevementFeedPanel.owner')"
      :old-value="oldRib?.libelleRib"
      :new-value="rib?.libelleRib"
    />

    <modified-field
      v-if="rib?.iban"
      name="IBAN"
      :old-value="oldRib?.iban"
      :new-value="rib?.iban"
    />

    <modified-field
      v-if="rib?.bic"
      name="BIC"
      :old-value="oldRib?.bic"
      :new-value="rib?.bic"
    />

    <modified-field
      v-if="rib?.jourPrelevement"
      :name="$t('prelevementFeedPanel.collectionDayShort')"
      :old-value="joursOptions.find((option) => option.value === oldRib?.jourPrelevement)?.text"
      :new-value="joursOptions.find((option) => option.value === rib?.jourPrelevement)?.text"
    />
  </table>
</template>

<script>

import ModifiedField from "@/components/ModifiedField.vue";
import {mapGetters} from "vuex";

export default {
  name: "DisplayRibBlock",
  components: {ModifiedField},
  props: ['rib', 'oldRib'],

  data() {
    return {
      joursOptions: [
        {value: 5, text: this.$t('prelevementFeedPanel.periodicity', {day: 5})},
        {value: 10, text: this.$t('prelevementFeedPanel.periodicity', {day: 10})},
        {value: 15, text: this.$t('prelevementFeedPanel.periodicity', {day: 15})},
        {value: 20, text: this.$t('prelevementFeedPanel.periodicity', {day: 20})}
      ]
    }
  },

  computed:{
    ...mapGetters({
    })
  },
}
</script>
