<template>
  <div class="container-fluid py-2 px-4">
    <div
      class="row justify-content-center"
    >
      <template v-if="!notification.listNotification">
        <amsom-overlay :loading="notification.loading > 0" />
      </template>

      <template
        v-else-if="Object.keys(notification.listNotification).length > 0"
      >
        <div class="col-12 mb-3">
          <h5>{{ $t('notificationPage.header') }}</h5>
        </div>

        <div
          v-for="(notif, index) in notification.listNotification"
          :key="index"
          class="col-md-10 col-12 my-2 notification-item"
          :role="haveRedirection(notif) ? 'button' : ''"
          @click="handleResult(notif)"
        >
          <div
            class="card p-2 m-3 me-0 align-items-start"
            :class="[notif.dateLecture === null ? 'bg-body' : 'bg-gray bg-opacity-25']"
          >
            <div class="card-text w-100 position-relative py-2 ps-4 py-md-4 px-md-5 pb-md-2">
              <div
                class="position-absolute top-0 start-0 translate-middle rounded-circle d-flex align-items-center justify-content-center bg-primary"
                style="height: 50px;width: 50px ;"
              >
                <font-awesome-icon
                  :icon="['fas', notif.icone ? notif.icone : 'envelope']"
                  size="xl"
                  class="text-light"
                />
              </div>

              <div class="overflow-hidden text-wrap lh-sm ps-2 mb-4">
                <h5 class="m-0">
                  {{ notif.titre }}
                </h5>

                <span class="text-secondary">
                  {{ notif.message }}
                </span>

                <small
                  v-if="haveRedirection(notif)"
                  class="text-muted fw-light fst-italic text-decoration-underline text-center d-block mt-2 me-4 show-more-label"
                >
                  {{ $t('notificationPage.showMore') }}
                </small>
              </div>

              <div class="position-absolute bottom-0 end-0 text-muted mb-1 me-2">
                <!-- class="ms-auto text-muted pt-2 pt-sm-0 d-flex align-items-end"> -->
                <small>{{ $t('common.dateAndHour', {date: unixToDateString(notif.dateMessage),
                                                    hour: unixToTimeString(notif.dateMessage)}) }}</small>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <h5 class="text-secondary text-center mt-4">
          {{ $t('notificationPage.noNotification') }}
        </h5>
      </template>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {unixToDateString} from "../js/text.js";
import router from '@/router';
import firebase from "@/js/firebase";
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";

export default {
  name: 'NotificationPage',

  components: {
    AmsomOverlay
  },

  data() {
    return {
    }
  },

  computed: {
    ...mapGetters({'selectedContratId': 'user/getSelectedContrat'}),
    ...mapState(['notification']),
  },

  watch: {
    'selectedContratId': function () {
      this.loadListNotification()
    },
  },

  mounted() {
    this.$store.commit('topbar/setBreadcrumb',
                       [
                         {icon: 'fa-solid fa-house', to: {name: 'home'}},
                         { text: this.$t('breadcrumb.notifications'), active: true},
                       ]
    );
    this.$store.commit('notification/setPageVisited', true)
    this.loadListNotification();
  },

  methods: {
    unixToDateString,

    unixToTimeString(unix) {
      return unixToDateString(unix, "HH:mm");
    },

    loadListNotification() {
      if(this.selectedContratId){
        this.$store.dispatch('notification/loadListNotification', this.selectedContratId).then(() => {
          this.$store.dispatch('notification/readNotification', {idContrat: this.selectedContratId})
        })
      }
    },

    redirection(route) {
      if(route?.name) router.push(route)
      else return
    },

    handleResult(notif) {
      firebase.handleFirebaseNotification({query: JSON.stringify(notif)})
    },

    haveRedirection(notif) {
      let route = JSON.parse(notif.route)
      return route?.name //&& route?.name != "home"
    }
  }
}
</script>
