<template>
  <div class="card card-body">
    <amsom-overlay
      v-if="sollicitation?.loading !== undefined"
      :loading="isLoading"
    >
      <div class="card-text">
        <div class="d-flex ">
          <span
            v-if="selectedNomenclatureItem && !selectedNomenclatureItem?.idParent || (selectedNomenclatureItem?.idParent && selectedNomenclatureItem?.idParent > 0)"
            class="hoverable text-decoration-underline"
            @click="selectedNomenclature = null"
          >
            <font-awesome-icon
              icon="fa-solid fa-angles-left"
              class="me-1 text-shadow"
              size="lg"
            />
          </span>

          <span
            v-if="selectedNomenclatureItem?.idParent && selectedNomenclatureItem?.idParent > 0"
            class="hoverable text-decoration-underline me-2"
            @click="selectedNomenclature = selectedNomenclatureItem?.idParent"
          >
            <font-awesome-icon
              icon="fa-solid fa-arrow-left"
              class="me-1 text-shadow"
              size="lg"
            />
          </span>

          <h5 class="mb-3 mx-auto pe-sm-5">
            {{ $t('request.create') }}
          </h5>
        </div>
        <!-- Aucun noeud selectionné -->
        <div
          v-if="!selectedNomenclatureItem"
          id="searchBlock"
          class="px-0 px-md-5 mx-0 mx-md-5"
        >
          <div class="input-group my-3 border rounded-4 mx-auto">
            <span
              id="searchbar-addon1"
              class="input-group-text text-gray border-0 bg-gray bg-opacity-50"
            >
              <font-awesome-icon icon="fa-solid fa-search" />
            </span>

            <input
              id="searchbar"
              v-model="searchValue"
              type="search"
              class="form-control bg-gray bg-opacity-50 border-0"
              :placeholder="$t('common.search') + ' ...'"
              aria-label="Rechercher un motif de sollicitation"
              aria-describedby="searchbar-addon1"
              autocomplete="off"
            >
          </div>

          <div class="mt-2 text-center">
            <small> {{ $t('request.nomenclature.orChooseAmong') }}</small>
          </div>
        </div>
        <!-- Noeud selectionné -->
        <div
          v-else
          id="returnBlock"
        >
          <div class="d-flex align-items-center mb-2">
            <span class="mx-auto d-flex align-items-center">
              <font-awesome-icon
                :icon="['fa-solid', selectedNomenclatureItem.icone ?? 'fa-gear']"
                class="text-light bg-primary rounded-circle p-2"
                style="width: 1em; height: 1em;"
              />

              <small class="ms-2 text-primary text-shadow">{{ selectedNomenclatureItem.libelle }}</small>
            </span>
          </div>

          <hr>
        </div>
        <!-- Stop message -->
        <div
          v-if="selectedNomenclatureItem && selectedNomenclatureItem.stopMessage"
          id="stopMessageBlock"
        >
          <p>{{ selectedNomenclatureItem.stopMessage }}</p>
        </div>
        <!-- Liste des motifs -->
        <div
          v-else-if="!selectedNomenclatureItem || !selectedNomenclatureItem.idMotif"
          id="selectBlock"
          class="row mt-2 g-0"
        >
          <div
            v-if="!nomenclatureToDisplay?.length"
            class="text-center"
          >
            {{ $t('request.nomenclature.noResult') }}.
          </div>

          <template v-else>
            <div
              v-for="item in nomenclatureToDisplay"
              :key="item.id"
              class="col"
            >
              <div
                :id="'nomenclature-'+item.id"
                class="text-center hoverable py-1"
                @click="selectNomenclature(item.id)"
              >
                <div
                  class="avatar avatar-lg bg-primary text-white fs-5 shadow rounded-circle shadow"
                >
                  <font-awesome-icon
                    :icon="['fa-solid', item.icone ?? 'fa-gear']"
                    class="fs-2"
                  />
                </div>

                <small
                  class="d-block mt-1 mx-4"
                  style="font-size: 0.8em;"
                >{{ item.libelle }}</small>
              </div>
            </div>

            <div
              v-if="selectedNomenclatureItem?.afficherAutreDemande"
              class="col-12 text-center mt-3"
            >
              {{ $t('request.nomenclature.otherQuery') }}
              <button
                type="button"
                role="button"
                class="btn btn-secondary rounded-5 fs-5 mt-1"
                title="Centre Relation Client"
                @click="tel('0322545000')"
              >
                <font-awesome-icon
                  icon="fa-solid fa-phone"
                  class="me-1"
                />

                <span class="d-none d-sm-inline">Centre Relation Client : </span>03 22 54 50 00
              </button>
            </div>
          </template>
        </div>
        <!-- Création de la sollicitation -->
        <div
          v-else
          id="descriptionBlock"
        >
          <amsom-overlay :loading="loading">
            <form
              class="text-end"
              @submit.prevent="createSollicitation"
            >
              <div class="row gy-4 text-start">
                <div class="col-12 ">
                  <label
                    for="input-description-sollicitation"
                    class="mb-1 form-label fw-bold text-primary"
                  >
                    {{ $t('request.message') }}
                  </label>

                  <textarea
                    id="input-description-sollicitation"
                    v-model="description"
                    class="form-control rounded-3"
                    :placeholder="$t('request.messagePlaceHolder') + ' ...'"
                    rows="4"
                    :disabled="loading"
                    maxlength="2000"
                    required
                  />

                  <small class="text-muted text-start ms-2">
                    {{ (description?.length ?? 0) }} /2000
                  </small>
                </div>

                <div class="col-12">
                  <upload-file
                    v-model="pj"
                    class="text-start"
                    name="sollicitation"
                    :pj-name="$t('request.attachement')"
                    :label="$t('request.attachementDescription')"
                    file-name="fichier-locataire"
                    :title="$t('request.attachementUploaded')"
                    @delete-file="pj = null"
                    @file-loading="updateFileLoading"
                  />
                </div>

                <div class="col-12 text-end align-self-center">
                  <button
                    id="create-sollicitation-btn"
                    class="btn btn-success mt-2 rounded-5"
                    type="submit"
                    :disabled="loading || pjLoading"
                  >
                    {{ $t('common.validate') }}
                    <font-awesome-icon
                      :icon="['fa-solid', 'fa-arrow-right']"
                      class="ms-1"
                    />
                  </button>
                </div>
              </div>
            </form>
          </amsom-overlay>
        </div>

        <div
          v-if="prestation?.telephone"
          class="text-center mt-3"
        >
          <button
            type="button"
            role="button"
            class="btn btn-secondary rounded-5 fs-5"
            :title="prestation.nature"
            @click="tel(prestation.telephone)"
          >
            <font-awesome-icon
              icon="fa-solid fa-phone"
              class="me-1"
            />

            <span class="d-none d-sm-inline">{{ prestation.libelle }} : </span>{{ prestation.telephone }}
          </button>
        </div>
      </div>
    </amsom-overlay>
  </div>
</template>

<script>
import {normalize} from "@/js/text.js";
import {toast} from "vue3-toastify";
import {mapGetters, mapState} from "vuex";
import UploadFile from "@/components/UploadFile.vue";
import LanguageDetect from "languagedetect";
import AmsomOverlay from "../UI/AmsomOverlay.vue";


// Fonction pour parcourir l'arbre et vérifier la présence d'une caractéristique
function traverseTree(node, properties, nomenclatures) {
  if (properties.some(property=> node[property])) {
    return true;
  }
  if (
    nomenclatures.some(nomenclature => {
      if (node.id === nomenclature.idParent) {
        if (traverseTree(nomenclature, properties, nomenclatures)){
          return true;
        };
      }
    })) {
    return true;
  }
  return false;
}

export default {
  name: "SollicitationCreate",
  components: {UploadFile, AmsomOverlay},

  data() {
    return {
      searchValue: "",
      selectedNomenclature: null,
      description: "",
      pj: null,

      loading:false,
      pjLoading: false
    }
  },

  computed: {
    ...mapState(['sollicitation', 'utilities', 'contact', 'moduleContrat']),

    ...mapGetters({
      getSelectedContrat: 'user/getSelectedContrat',
      isLoading: 'contact/listLoading',
      contacts: 'contact/getContactList',
      mainModule: 'moduleContrat/getMainModule',
    }),

    isLoading() {
      return this.sollicitation.loading  > 0 || this.contact.loading > 0 || this.moduleContrat.loading > 0 || this.utilities.loading > 0
    },

    nomenclature() {
      // On filtre les éléments qui ont un prestataire absent des contacts
      return this.utilities.nomenclature
        .filter((nomenclature) => {
          if (nomenclature.prestataire) {
            return this.contacts.find(contact => contact.idNature === nomenclature.prestataire)
          }
          return true
        })
        // Filtre les éléments qui possèdent un nœud avec idPih ou stopMessage
        .filter((nomenclature, idx, nomenclatures) => {
          const hasDisplayableSon = traverseTree(nomenclature, ['idMotif', 'stopMessage', 'prestataire'], nomenclatures);
          return hasDisplayableSon;
        })
    },

    nomenclatureToDisplay() {
      // On commence par filtrer les éléments de la nomenclature en fonction de la recherche
      if (this.searchValue.length > 0) {
        let res = this.nomenclature.filter(item =>
          item.keyWords &&
          item.keyWords.some(
            keyword => normalize(keyword).includes(normalize(this.searchValue))
              || normalize(this.searchValue).includes(normalize(keyword))
          )
        )
        // Filtre les éléments qui présentent le même libellé
        res = res.filter((nomenclature, index, self) =>
          index === self.findIndex((t) => (t.libelle === nomenclature.libelle))
        )

        return res
      }

      // Si on a sélectionné un élément, on affiche les éléments enfants
      if (this.selectedNomenclature)
        return this.nomenclature.filter(item => item.idParent === this.selectedNomenclature);

      // Sinon on affiche les éléments de niveau 1
      return this.nomenclature.filter(item => !item.idParent)
    },

    selectedNomenclatureItem() {
      return this.nomenclature.find(item => item.id === this.selectedNomenclature);
    },

    prestation() {
      if (this.selectedNomenclatureItem && this.selectedNomenclatureItem.prestataire)
        return this.contacts.find(contact => contact.idNature === this.selectedNomenclatureItem.prestataire)??null

      return null;
    },
  },

  mounted() {
    this.initComponent()
  },

  methods: {
    initComponent(){
      let promises = []
      promises.push(this.$store.dispatch('utilities/loadNomenclature'));
      if (this.getSelectedContrat){
        promises.push(
          this.$store.dispatch('moduleContrat/getModuleByContratId', this.getSelectedContrat).then(() => {
            let moduleId = this.mainModule.idModule
            this.$store.dispatch('contact/getContactsByModule', moduleId)
          })
        );
      }
      Promise.all(promises).then(() => {
        this.selectNomenclature(parseInt(this.$route.query.nomenclature))
      })
    },

    updateFileLoading(value) {
      this.loadingFile = value
    },

    selectNomenclature(id) {
      this.searchValue = "";
      this.selectedNomenclature = id;
    },

    createSollicitation() {
      let lng = new LanguageDetect()
      let detection = lng.detect(this.description).find(res => {
        return res[0] === "french"
      })

      // console.log(lng.detect(this.description))

      if(!detection || detection[1] < 0.20){
        alert('Vous semblez ne pas avoir rédigé en francais')
        return
      }

      this.loading = true
      let data = {}
      data.description = this.description
      data.motif = this.selectedNomenclatureItem.idMotif
      data.idContrat = this.getSelectedContrat
      data.pj = this.pj

      this.$store.dispatch('sollicitation/createSollicitation', data)
        .then((id) => {
          this.searchValue = "";
          this.selectedNomenclature = null;
          this.description = "";
          this.pj = "";
          this.$router.push({name: 'sollicitationDetail', params: { sollicitationId: id}})
        })
        .catch(error => {
          toast.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },

    tel(number) {
      if (window.cordova) {
        cordova.InAppBrowser.open("tel:" + number, "_system");
      } else {
        window.open("tel:" + number, "_system");
      }
    },
  },
}
</script>

<style scoped>
#searchbar:hover, #searchbar:focus {
  background-color: var(--secondary) !important;
  font-size: 1.25em;
  transition: 0.15s ease-in-out;
}
.text-shadow {
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3);
}
</style>
