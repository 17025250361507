<template>
  <div>
    <amsom-overlay
      :loading="user.loading > 0"
      class="w-100"
    >
      <div class="container-fluid bg-light">
        <div class="row vh-100 text-center align-items-center">
          <div class="col-12 col-md-6 px-2 px-sm-2 px-md-3 px-lg-4 px-xl-5">
            <img
              :src="logo"
              class="w-25 mb-4 d-md-none"
              alt="Logo"
            >

            <div class="card text-center rounded-4">
              <div class="card-body">
                <div class="card-text">
                  <h3 class="text-primary">
                    {{ $t("loginPage.cardHeader") }}
                  </h3>

                  <login-form-block class="mt-4 px-2 px-lg-5" />
                </div>
              </div>
            </div>

            <div class="card text-center rounded-4 mt-2">
              <div class="card-body">
                <div class="card-text">
                  <router-link
                    :to="{ name: 'register' }"
                    role="link"
                    class="nav-link hover-animate m-0 p-0 fs-5"
                  >
                    {{ $t("loginPage.registerButton") }}
                    <font-awesome-icon
                      icon="fa-solid fa-arrow-right"
                      class="ms-2"
                    />
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="d-none d-md-block col-6">
            <img
              alt="Logo"
              :src="loginImg"
              class="vh-100 mw-100 px-2"
            >
          </div>
        </div>
      </div>

      <!-- Modal de demande d'accepter les notifications -->
      <modal-notification v-if="$isAppCordova" />
    </amsom-overlay>
  </div>
</template>

<script>
import LoginFormBlock from "@/components/LoginPage/FormBlock.vue";
import loginImg from "@/assets/images/loginPage/login.svg";
import ModalNotification from "@/components/LoginPage/ModalNotification.vue";
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";
import { mapState } from "vuex";
import logo from "@/assets/logo.png";

export default {
  name: "LoginPageBrowser",
  components: { LoginFormBlock, ModalNotification, AmsomOverlay },

  data() {
    return {
      loginImg: loginImg,
      logo: logo,
    }
  },
  
  computed: {
    ...mapState(["user"]),
  }
};
</script>
