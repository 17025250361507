<template>
  <div class="col">
    <div class="row">
      <div class="col-12 col-xl-4">
        <img
          :src="imgNumLocataire"
          alt="Aide numéro locataire"
          width="200"
          class="img-fluid rounded"
        >
      </div>

      <div class="col-12 col-xl-8 mt-3 mt-lg-0">
        {{ $t('registerPage.askForContrat') }}
        <form
          id="step1FormRegister"
          class="mt-3"
          @submit.prevent="validateStep()"
        >
          <div class="position-relative mx-auto w-75">
            <input
              id="input-numero-contrat"
              v-model.number="numContrat"
              :placeholder="$t('registerPage.monNumeroContrat')"
              type="number"
              inputmode="numeric"
              name="numero-contrat"
              class="form-control rounded-3 mb-3 ps-4"
              size="10"
              maxlength="10"
              pattern="\d+"
              required
            >

            <div class="position-absolute start-0 top-0 h-100 d-flex align-items-center ps-2">
              L/
            </div>
          </div>

          <div
            v-if="error"
            class="text-center text-danger my-2"
          >
            {{ error }}
          </div>

          <div class="text-center">
            <button
              type="submit"
              class="btn btn-success rounded-5 mt-3 py-2 "
            >
              {{ $t("common.nextStep") }}
              <font-awesome-icon :icon="['fas', 'arrow-right']" />
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import imgNumLocataire from "@/assets/images/num_contrat_inscription.png";
import {mapState} from "vuex";

export default {
  name: "RegisterStep1Block",

  data() {
    return {
      imgNumLocataire: imgNumLocataire,
      regexNumContrat: process.env.VUE_APP_REGEX_NUM_CONTRAT,

      numContrat: null,

      error: null
    }
  },

  computed: {
    ...mapState(['registerPage']),

    registerStep: {
      get() {
        return this.registerPage.registerStep
      },

      set(value) {
        this.$store.commit('registerPage/setRegisterStep', value)
      }
    },
  },

  methods: {
    validateStep() {
      const self = this

      this.$store.dispatch('registerPage/getAvailableCommunicationForContrat', this.numContrat)
        .then(() => {
          self.registerStep ++
          self.error = null
        })
        .catch(() => {
          self.error = this.$t('registerPage.contactNumberUnknown')
        })
    },
  }
}
</script>

<style scoped>
/* Disable arrow from input[number] */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
