<template>
  <div class="d-inline-block col-12 px-3">
    <div class="form-check form-switch">
      <input
        id="checkbox-carte-invalidite"
        v-model="individu.carteInvalidite"
        class="form-check-input"
        type="checkbox"
        name="carteInvalidite"
      >

      <label
        for="checkbox-carte-invalidite"
        class="form-check-label active"
      >
        {{ $t('tenants.informations.cmi') }}
      </label>
    </div>

    <small class="text-muted form-text lh-sm d-block">{{ $t('tenants.informations.cmiDetail') }}</small>
  </div>

  <div
    v-if="needPjCmi || individu.pjCarteInvalidite"
    class="col-12"
  >
    <amsom-overlay :loading="loadingPjCmi">
      <upload-file
        v-model="individu.pjCarteInvalidite"
        :ged-file="gedPjCmi"
        name="cmi"
        pj-name="Carte Mobilité Inclusion *"
        sub-text="Copie recto/verso de votre CMI."
        :file-name="cmiFileName"
        title="Pièce(s) justificative(s)"
        class="fw-bold"
        required
        @delete-file="delete individu.pjCarteInvalidite"
        @file-loading="updateFileLoading"
      />
    </amsom-overlay>
  </div>
</template>

<script>
import UploadFile from "@/components/UploadFile.vue";
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";

export default {
  name: "LocataireCmiBlock",

  components: {
    UploadFile,
    AmsomOverlay,
  },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },

    needPjCmi: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update:modelValue", "fileLoading"],

  data() {
    return {
      individu: this.modelValue,
      loadingPjCmi: false,
      gedPjCmi: null,
    };
  },

  computed: {
    ...mapGetters({ getNomPrenom: "contrat/getNomPrenom" }),

    cmiFileName() {
      return (
        this.getNomPrenom(this.individu) +
        " - CMI " +
        moment().format("YYYY-MM-DD")
      );
    },
  },

  watch: {
    individu: {
      handler(value) {
        this.$emit("update:modelValue", value);
      },

      deep: true,
    },

    needPjCmi: function (newValue, oldValue) {
      if (oldValue && !newValue) delete this.individu.pjCarteInvalidite;
    },

    loadingPjCmi(value) {
      this.$emit("fileLoading", value);
    },
    // modelValue: {
    //   handler(value) {
    //     this.individu = value;
    //   },

    //   deep: true,
    // },
  },

  mounted() {
    if (this.individu.justificatifs) {
      let pj = this.individu.justificatifs.find(
        (pj) => pj.typeFichier === "cmi"
      );
      if (pj) {
        this.loadingPjCmi = true;
        this.$store.dispatch("ged/loadPdfById", pj.id).then((data) => {
          this.gedPjCmi = new window.File_native([data], pj.libelle, { type: data.type });
          this.loadingPjCmi = false;
        });
      }
    }
  },

  methods: {
    updateFileLoading(value) {
      this.$emit("fileLoading", value);
    },
  },
};
</script>
