<template>
  {{ $t('registerPage.impossibleToRegister') }}

  <div class="row g-2 align-items-center px-4 mt-3">
    <div class="col-2 text-end">
      <font-awesome-icon
        :icon="['fas', 'fa-envelope']"
        size="lg"
        :class="iconClass"
        style="width: 1em"
      />
    </div>

    <div
      class="col-10 text-start"
      role="button"
      @click="mailTo(mailContact)"
    >
      {{ mailContact }}
    </div>

    <div class="col-2 text-end">
      <font-awesome-icon
        :icon="['fas', 'fa-phone']"
        size="lg"
        :class="iconClass"
        style="width: 1em"
      />
    </div>

    <div
      class="col-10 text-start"
      role="button"
      @click="tel(telContact)"
    >
      {{ telContact.split('').reduce((a, e, i) => a + e + (i % 2 === 1 ? ' ' : ''), '') }}
    </div>

    <div class="col-2 text-end">
      <font-awesome-icon
        :icon="['fas', 'fa-location-dot']"
        size="lg"
        :class="iconClass"
        style="width: 1em"
      />
    </div>

    <div
      class="col-10 text-start"
      role="button"
      @click="mapsTo(adrContact)"
    >
      {{ adrContact }}
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: "RegisterErrorBlock",
  components: { FontAwesomeIcon },

  data() {
    return {
      mailContact: 'contact@amsom-habitat.fr',
      telContact: '0322545000',
      adrContact: '1 rue Du Général frère, 80080 Amiens',
      iconClass: "p-2 bg-gray bg-opacity-50 text-secondary rounded-3 fa-fw",
    }
  },

  methods: {
    tel(number) {
      if (window.cordova) {
        cordova.InAppBrowser.open("tel:" + number, "_system");
      } else {
        window.open("tel:" + number, "_system");
      }
    },

    mailTo(mail) {
      const mailTo = 'mailto:' + mail + '?subject=' + encodeURIComponent('[Espace client] Inscription impossible')
      if (window.cordova) {
        cordova.InAppBrowser.open(mailTo, "_system");
      } else {
        window.open(mailTo, "_system");
      }
    },

    mapsTo(address) {
      if (window.cordova) {
        let prefixUrl
        if (device.platform.toLowerCase() === "ios")
          prefixUrl = "maps://maps.google.com/?q="
        else
          prefixUrl = "https://maps.google.com/?q="

        cordova.InAppBrowser.open(
          prefixUrl + address,
          "_system"
        );
      } else {
        window.open("https://maps.google.com/?q=" + address, "_system");
      }
    },
  },
}
</script>
