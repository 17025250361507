<template>
  <div
    class="container-fluid py-2 px-4"
  >
    <div class="row">
      <div class="col-12 col-lg-4 mb-2 order-lg-first order-last my-1">
        <sollicitation-list
          show-inactive
          length="-1"
          style="max-height: 80vh;"
        />
      </div>

      <div
        v-if="contrat?.currentContratInfos && !contrat?.currentContratInfos?.hasExpired"
        class="col-12 col-lg-8 mb-2"
      >
        <sollicitation-create />
      </div>
    </div>
  </div>
</template>

<script>
import SollicitationList from '@/components/SollicitationPage/SollicitationList.vue';
import SollicitationCreate from '@/components/SollicitationPage/SollicitationCreate.vue';
import { mapState } from 'vuex';

export default {
  name: 'SollicitationPage',

  components: {
    SollicitationList,
    SollicitationCreate
  },

  computed: {
    ...mapState(['contrat']),
  },

  mounted() {
    this.$store.commit('topbar/setBreadcrumb',
                       [
                         { icon: 'fa-solid fa-house', to: { name: 'home' } },
                         { text: this.$t('breadcrumb.requests'), active: true }
                       ]
    )
  }
}
</script>
