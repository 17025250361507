import { createApp } from "vue";
import i18n from "./i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
// import BootstrapVueNext from 'bootstrap-vue-next'
import vSelect from "vue-select";
import moment from "moment-timezone";
import Vue3Toastify, { toast } from "vue3-toastify";
import VueSafeHTML from 'vue-safe-html';
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    FontAwesomeIcon,
    FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "@amsom-habitat/bubbly-amsom/style.default.css"
// import "./assets/styles/style.default.css"
// import "@amsom-habitat/bubbly/dist/index.css"
// import "./assets/styles/bootstrap.css"
const company = process.env.VUE_APP_COMPANY
console.log(company)
if (company === "bdsh") {
    import('@amsom-habitat/bootstrap-5/dist/bootstrap.bdsh.min.css')
} else {
    import('@amsom-habitat/bootstrap-5/dist/bootstrap.min.css')
}
import "./assets/styles/custom.css";
import "./assets/styles/navGrid.css";
import "vue3-toastify/dist/index.css";

import {
    faCircleRight,
    faFolder,
    faFileLines,
    faCircleCheck,
    faCalendarXmark,
    faBell,
    faCalendarCheck,
    faCalendar,
} from "@fortawesome/free-regular-svg-icons";

import {
    faAddressCard,
    faArrowTrendDown,
    faBuilding,
    faCloud,
    faFileCirclePlus,
    faLink,
    faPersonWalkingArrowRight,
    faMapLocationDot,
    faRightToBracket,
    faRotateRight,
    faHouse,
    faFileSignature,
    faPersonCircleQuestion,
    faCloudArrowDown,
    faBriefcase,
    faCalendarAlt,
    faGear,
    faReply,
    faChevronRight,
    faChevronDown,
    faCircle,
    faArrowLeft,
    faAlignLeft,
    faArrowRight,
    faCheck,
    faLocationDot,
    faPhone,
    faMobileScreen,
    faEnvelope,
    faEye,
    faEyeSlash,
    faTriangleExclamation,
    faShield,
    faPercent,
    faUsers,
    faUser,
    faUserPlus,
    faUserSlash,
    faCircleInfo,
    faPlus,
    faArrowsRotate,
    faXmark,
    faCircleQuestion,
    faFileArrowUp,
    faPaperclip,
    faCommentDots,
    faPen,
    faFileCircleQuestion,
    faFileCircleCheck,
    faFileCircleExclamation,
    faEuroSign,
    faChildReaching,
    faHandsHoldingChild,
    faClock,
    faSearch,
    faCoins,
    faMoneyCheck,
    faLandmark,
    faBuildingUser,
    faReceipt,
    faMoneyBillTransfer,
    faBan,
    faCreditCard,
    faAngleDown,
    faAngleUp,
    faEllipsis,
    faAnglesLeft,
    faAngleLeft,
    faUserShield,
    faBug,
    faBath,
    faCity,
    faDoorOpen,
    faDroplet,
    faElevator,
    faFaceAngry,
    faDove,
    faSquareParking,
    faKey,
    faFire,
    faWarehouse,
    faPlugCircleXmark,
    faPersonDigging,
    faLightbulb,
    faLeaf,
    faWifi,
    faTv,
    faHouseChimneyCrack,
    faTrash,
    faPaintRoller,
    faScrewdriverWrench,
    faUserMinus,
    faHouseUser,
    faUserEdit,
    faInfo,
    faPhoneSlash,
    faPaw,
    faCheckCircle,
    faCloudArrowUp,
    faFileShield,
    faAddressBook,
    faPenToSquare,
    faRightLeft,
    faCircleUser,
    faListCheck,
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faListCheck,
    faAnglesLeft,
    faChevronDown,
    faAngleLeft,
    faEllipsis,
    faClock,
    faCalendarXmark,
    faHandsHoldingChild,
    faChildReaching,
    faRightToBracket,
    faArrowRight,
    faArrowLeft,
    faCheck,
    faLocationDot,
    faPhone,
    faMobileScreen,
    faEnvelope,
    faEye,
    faEyeSlash,
    faTriangleExclamation,
    faCircleRight,
    faShield,
    faPercent,
    faUsers,
    faUser,
    faUserPlus,
    faUserSlash,
    faFileLines,
    faCircleInfo,
    faCircleCheck,
    faPlus,
    faArrowsRotate,
    faXmark,
    faCircleQuestion,
    faFileArrowUp,
    faPaperclip,
    faCommentDots,
    faPen,
    faFileCircleQuestion,
    faFileCircleCheck,
    faFileCircleExclamation,
    faEuroSign,
    faAddressCard,
    faEnvelope,
    faBuilding,
    faLink,
    faArrowTrendDown,
    faCloud,
    faRotateRight,
    faMapLocationDot,
    faPersonWalkingArrowRight,
    faHouse,
    faFileSignature,
    faPersonCircleQuestion,
    faFileCirclePlus,
    faCloudArrowDown,
    faCloudArrowUp,
    faCreditCard,
    faCircle,
    faFolder,
    faBriefcase,
    faCalendarAlt,
    faGear,
    faReply,
    faChevronRight,
    faAlignLeft,
    faBell,
    faSearch,
    faCoins,
    faMoneyCheck,
    faLandmark,
    faBuildingUser,
    faReceipt,
    faMoneyBillTransfer,
    faBan,
    faAngleDown,
    faAngleUp,
    faUserShield,
    faBug,
    faBath,
    faCity,
    faDoorOpen,
    faDroplet,
    faElevator,
    faDove,
    faFaceAngry,
    faSquareParking,
    faKey,
    faFire,
    faWarehouse,
    faPlugCircleXmark,
    faPersonDigging,
    faLightbulb,
    faLeaf,
    faWifi,
    faTv,
    faHouseChimneyCrack,
    faTrash,
    faPaintRoller,
    faScrewdriverWrench,
    faUserMinus,
    faHouseUser,
    faUserEdit,
    faCalendarCheck,
    faCalendar,
    faInfo,
    faPhoneSlash,
    faPaw,
    faCheckCircle,
    faFileShield,
    faAddressBook,
    faPenToSquare,
    faRightLeft,
    faCircleUser
);

import { getPlatforms } from "@ionic/vue";


moment.tz.setDefault("Europe/Paris");
if(i18n.global.locale === "ar"){
    moment.locale("ar-dz");
}else{
    moment.locale(i18n.global.locale);
}
//set the html lang attribute
document.getElementsByTagName("html")[0].lang = i18n.global.locale;
document.title = process.env.VUE_APP_TITLE ?? "AMSOM&Moi";
const descEl = document.querySelector('head meta[name="description"]');
if (descEl) {
    descEl.setAttribute("content", process.env.VUE_APP_DESCRIPTION ?? "AMSOM&Moi");
}

function initApp(isAppCordova = false, isPhoneFormat = false) {

    const app = createApp(App);
    app.config.globalProperties.$isAppCordova = isAppCordova
    app.config.globalProperties.$isPhoneFormat = isPhoneFormat

    app.config.globalProperties.$upperCaseTransform = function (e) {
        return e?.toUpperCase()
    }
    app.config.globalProperties.$lowerCaseTransform = function (e) {
        return e?.toLowerCase()
    }
    
    app
        .directive("amsomFormaterUpperCase", {
            beforeUpdate(el) {
                el.value = el.value.toUpperCase();
            },
        })
        .directive("amsomFormaterLowerCase", {
            beforeUpdate(el) {
                el.value = el.value.toLowerCase();
            },
        })
        .component("font-awesome-icon", FontAwesomeIcon)
        .component("font-awesome-layers", FontAwesomeLayers)
        .component("v-select", vSelect)
        .use(router)
        .use(store)
        .use(i18n)
        .use(VueSafeHTML, {
            allowedAttributes: ['class'],
        })
        .use(Vue3Toastify, {
            autoClose: 3000,
            position: toast.POSITION.BOTTOM_RIGHT,
        })
        .mount("#app");
}

const isAppCordova = !!window.cordova && (process.env.CORDOVA_PLATFORM === 'ios' || process.env.CORDOVA_PLATFORM === 'android');
const isPhoneFormat = getPlatforms().includes("android") || getPlatforms().includes("ios")

if (isAppCordova) {
    document.addEventListener(
        "deviceready",
        () => {
            //todo
            // alert(device.platform)
            // window.open = cordova.InAppBrowser.open;
            // let ref = cordova.InAppBrowser.open('https://www.africau.edu/images/default/sample.pdf', '_system', 'location=yes');
            // let ref = cordova.InAppBrowser.open('https://www.africau.edu/images/default/sample.pdf', '_system', 'location=yes');

            try {
                StatusBar.overlaysWebView(false);
                StatusBar.styleDefault();

                // StatusBar.backgroundColorByHexString('#fafafa')
            } catch (e) {
                console.log(e);
            }

            initApp(isAppCordova, isPhoneFormat);
        },
        false
    );
}
else {
    initApp(isAppCordova, isPhoneFormat);
}
