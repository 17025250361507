<template>
  <div
    class="card"
    :class="[{'border border-danger': currentRejets?.length > 0}]"
  >
    <div class="position-absolute start-0 top-0  translate-middle-y text-center w-100">
      <span
        v-if="currentRejets?.length > 0"

        class="fs-7 text-danger bg-danger bg-opacity-25 rounded rounded-pill px-2 py-1 border border-1 border-danger"
      >
        {{ currentRejets?.length }} Erreur{{ currentRejets?.length > 1 ? 's' : '' }} détectée{{ currentRejets?.length > 1 ? 's' : '' }}
      </span>
    </div>

    <div class="card-body card-text text-primary">
      <h5 class="mb-4">
        <font-awesome-icon icon="fa-solid fa-arrow-trend-down" /> Baisse des revenus en {{ previousYear }}
      </h5>

      <div class="row">
        <div class="col col-12">
          <p>
            En cas de diminution de vos revenus <strong>{{ previousYear }}</strong>

            de plus de 10% par rapport à <strong>{{ previousYear -1 }}</strong>, vous
            pouvez demander à ce que
            le calcul du SLS porte sur vos revenus de l'année <strong>{{ previousYear }}</strong>.
          </p>

          <p>Pour cela, merci de joindre les pièces justifiant cette diminution.</p>
        </div>

        <div
          class="col-12"
        >
          <amsom-overlay :loading="loadingPjBaisse">
            <upload-file
              v-model="fiscalite.pjBaisseRevenus"
              :ged-file="gedPjBaisse"
              name="baisse-revenus"
              pj-name="Baisse des revenus"
              sub-text="Copies des 12 derniers bulletins de salaire, attestation du Pôle Emploi ou justificatif de pension de retraite."
              :file-name="baisseFileName"
              title="Pièce(s) justificative(s)"
              class="fw-bold"
              @delete-file="delete fiscalite.pjBaisseRevenus"
              @file-loading="updateFileLoading"
            />
          </amsom-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import {mapState, mapGetters} from "vuex";
import UploadFile from "@/components/UploadFile.vue";
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";

export default {
  name: 'FiscaliteBaisseBlock',
  components: { UploadFile, AmsomOverlay },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },

  emits: ['fileLoading', 'update:modelValue'],

  data() {
    return {
      fiscalite: this.modelValue,

      loadingPjBaisse: false,
      gedPjBaisse: null,
    }
  },

  computed: {
    ...mapState(['enquete']),
    ...mapGetters({getRejetsGrouped: 'enquete/getRejetsGrouped'}),

    currentRejets() {
      if(!this.getRejetsGrouped || !this.getRejetsGrouped['contrat']) return null

      return this.getRejetsGrouped['contrat'].filter(rejet => rejet.libelleJustificatif === "Baisse de revenus")
    },

    enqueteContrat() {
      return this.enquete.enqueteContrat;
    },

    currentYear() {
      if(!this.enqueteContrat.dateEffet){
        return null;
      }
      return moment.unix(this.enqueteContrat.dateEffet).format('YYYY');
    },

    previousYear(){
      if(!this.enqueteContrat.dateEffet){
        return null;
      }
      return moment.unix(this.enqueteContrat.dateEffet).subtract(1, 'years').format('YYYY');
    },

    baisseFileName(){
      return this.enqueteContrat.idContrat + ' - Justificatif de baisse de revenus ' + moment.unix(this.enqueteContrat.dateEffet).year()
    },
  },


  watch: {
    fiscalite: {
      handler(value) {
        this.$emit("update:modelValue", value);
      },

      deep: true,
    },

    loadingPjBaisse(value) {
      this.$emit("fileLoading", value);
    },
  },

  mounted() {
    if (this.enqueteContrat.justificatifs) {
      let pj = this.enqueteContrat.justificatifs.find(
        (pj) => pj.typeFichier === "baisse_revenu"
      );
      if (pj) {
        this.loadingPjBaisse= true;
        this.$store.dispatch("ged/loadPdfById", pj.id).then((data) => {
          this.gedPjBaisse = new window.File_native([data], pj.libelle, { type: data.type });
          this.loadingPjBaisse = false;
        });
      }
    }
  },

  methods:{
    updateFileLoading(value) {
      this.$emit("fileLoading", value);
    },
  },
}
</script>
