<template>
  <header>
    <div class="container-fluid d-flex text-primary align-items-center justify-content-between top-bar-block px-2 px-sm-3">
      <div>
        <span
          :class="[{ 'd-none': sideBarShowed }]"
          @click="$router.push({ name: 'home' })"
        >
          <img
            :src="navbarLogo"
            alt="Icon de la navbar"
            style="height: calc(var(--top-nav-height) * 0.75)"
          >
        </span>

        <div
          class="d-none d-md-inline-block"
          :class="[{ 'ms-2': !sideBarShowed }]"
        >
          <template v-if="!topbar.breadcrumb">
            <span class="fs-4 fw-bold text-capitalize">
              {{ $t("topBar.welcomeMessage", { name: prenom }) }}
            </span>
          </template>

          <nav
            v-else
            aria-label="breadcrumb ps-2  fw-bold text-capitalize"
          >
            <ol class="breadcrumb m-0">
              <li
                v-for="item in topbar.breadcrumb"
                :key="item.text ?? item.icon"
                class="breadcrumb-item fs-5"
                :class="{ active: 'active' }"
              >
                <router-link
                  v-if="item.to"
                  :to="item.to ?? ''"
                >
                  <template v-if="item.text">
                    {{ item.text }}
                  </template>

                  <font-awesome-icon
                    v-else
                    :icon="item.icon"
                    size="sm"
                  />
                </router-link>

                <template v-else>
                  <template v-if="item.text">
                    {{ item.text }}
                  </template>

                  <font-awesome-icon
                    v-else
                    :icon="item.icon"
                    size="sm"
                  />
                </template>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div
        class="row align-items-center justify-content-between gx-0"
      >
        <div class="col-auto me-2 shadow py-1 px-2 px-lg-3 fw-bold rounded-3 small hoverable bg-body">
          <span
            @click="$router.push({ name: 'compte' })"
          >
            <span
              v-if="isNaN(solde)"
              id="topbar-solde"
              class="spinner-grow spinner-grow-sm"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </span>

            <template v-else>
              <span
                class="d-none d-lg-inline"
                v-html="$t('topBar.balanceLabel', {solde: toCurrencyEUR(solde),
                                                   variant: (solde < 0 ? 'danger' : 'success')})"
              />

              <span
                class="d-inline d-lg-none"
                v-html="$t('topBar.balanceLabelMinimized', {solde: toCurrencyEUR(solde),
                                                            variant: (solde < 0 ? 'danger' : 'success')})"
              />
            </template>
          </span>
        </div>

        <div
          id="notification-bell"
          class="col-auto position-relative hoverable shadow px-2 py-1 fw-bold rounded-3 small bg-body me-3"
          @click="$router.push({ name: 'notification' })"
        >
          <span>
            <font-awesome-icon
              :icon="['far', 'fa-bell']"
              :class="[{ 'fa-shake fs-5': nbNotificationUnread > 0 }]"
            />
          </span>
          <!--          <span -->
          <!--            class="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle" -->
          <!--          > -->
          <!--            <span class="position-absolute">0</span> -->
          <!--          </span> -->
          <div
            v-if="nbNotificationUnread > 0"
            id="badge-notification-counter"
            class="position-absolute top-0 start-100 translate-middle badge rounded-5 bg-danger"
          >
            {{ nbNotificationUnread }}
          </div>
        </div>

        <div class="col-auto text-end d-flex align-items-center">
          <div
            id="topbar-info-perso"
            class="d-none d-md-block mx-1"
            @click="openDropDown()"
          >
            <div
              class="fw-bold d-none d-lg-block"
            >
              {{ prenom }} {{ nom }}
            </div>

            <div
              class="fw-bold text-truncate d-block d-lg-none"
              style="max-width: 19vw;"
            >
              {{ prenom }} {{ nom }}
            </div>

            <small class="text-success">{{ $t('common.contract') }}
              <span class="fw-bold">{{ selectedContratId }}</span></small>
          </div>

          <tippy
            id="dropDownMenuIcon"
            style="scale: 0.85"
            theme="light-border"
            placement="top-end"
            animation="perspective"
            trigger="mouseenter click"
            role="menu"
            interactive
          >
            <div class="d-flex align-items-center">
              <div
                class="avatar bg-primary text-white"
                role="button"
              >
                <font-awesome-icon
                  icon="fa-solid fa-user"
                  class="fa fa-2xl"
                />
              </div>

              <font-awesome-icon
                icon="fa-solid fa-chevron-down"
                class="ms-2 fw-bold d-none d-sm-block"
              />
            </div>

            <template #content="{ hide }">
              <div class="d-flex flex-column d-md-none">
                <div class="fw-bold">
                  {{ prenom }} {{ nom }}
                </div>

                <small class="text-success">{{ $t('common.contract') }}
                  <span class="fw-bold">{{ selectedContratId }}</span></small>

                <div class="dropdown-divider" />
              </div>

              <!--
                <div class="d-flex">
                <select-language-block />
                </div> 
              -->

              <div
                v-if="currentContrat && contrats.length > 1"
              >
                <div class="pt-1">
                  <div class="form-floating">
                    <select
                      id="contratSelect"
                      v-model="currentContrat"
                      class="form-select text-start"
                      :style="'min-width: ' + ($t('topBar.contractSwitchBtn').length * 8.5) + 'px;'"
                      @change="hide()"
                    >
                      <option
                        v-for="contrat in contrats"
                        :key="contrat"
                        :value="contrat"
                      >
                        {{ contrat }}
                      </option>
                    </select>

                    <label
                      for="contratSelect"
                      class="px-auto"
                    >{{ $t("topBar.contractSwitchBtn") }}</label>
                  </div>
                </div>

                <div class="dropdown-divider" />
              </div>

              <router-link
                :to="{ name: 'profil' }"
                class="dropdown-item p-1"
                @click="hide()"
              >
                {{ $t("profilPage.header") }}
              </router-link>

              <span
                class="dropdown-item  p-1 mb-3"
                role="button"
                @click="logout"
              >
                {{ $t("topBar.logoutBtn") }}
              </span>

              <div class="position-absolute bottom-0 end-0 text-end">
                <span class="fs-8 text-muted px-1 mx-1 rounded">
                  V.{{ appVersion }}
                </span>
              </div>
            </template>
          </tippy>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import { toCurrencyEUR } from "@/js/text.js";
import packageInfo from "../../package.json";

// https://vue-tippy.netlify.app/
import { Tippy } from "vue-tippy";
// import SelectLanguageBlock from "@/components/SelectLanguageBlock.vue";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/animations/perspective.css";
import logo from "@/assets/logo.png";

export default {
  name: "TopBar",
  components: { Tippy },
  props: ["sideBarShowed"],

  data() {
    return {
      navbarLogo: logo,
      appVersion: packageInfo.version,
    };
  },

  computed: {
    ...mapGetters({
      locataireConnected: "user/getLocataireInfo",
      solde: "compte/getSolde",
      selectedContratId: "user/getSelectedContrat",
      contrats: "user/getContrats",
    }),

    ...mapState(["topbar", "notification"]),

    prenom() {
      let prenom = this.locataireConnected?.prenom;
      if (!prenom || prenom.length > 10) {
        return "";
      }
      prenom = prenom.toLowerCase();
      return prenom.charAt(0).toUpperCase() + prenom.slice(1);
    },

    nom() {
      let nom = this.locataireConnected?.nom;
      if (!nom) {
        return "";
      }
      return nom.toUpperCase();
    },

    currentContrat: {
      get() {
        return this.selectedContratId;
      },

      set(contrat) {
        this.$store.commit("user/setSelectedContrat", contrat);
      },
    },

    nbNotificationUnread() {
      if(this.notification.pageVisited || !this.notification?.listNotification)
        return 0

      return this.notification.listNotification.filter(
        (n) => n.dateLecture === null
      ).length
    },
  },

  watch: {
    selectedContratId: function () {
      this.loadListNotification();
    },
  },

  mounted() {
    this.loadListNotification();
  },

  methods: {
    openDropDown() {
      document.getElementById("dropDownMenuIcon").click();
    },

    toCurrencyEUR,

    logout() {
      this.$store.dispatch("user/logout").then(() => {
        this.$router.push({ name: "login" });
      });
    },

    loadListNotification() {
      if (this.selectedContratId) {
        this.$store.dispatch(
          "notification/loadListNotification",
          this.selectedContratId
        );
      }
    },
  },
};
</script>
