import {createI18n} from "vue-i18n";

function loadLocaleRessoureces() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    console.log(messages)
    return messages
}

// let locale = window.localStorage.getItem('locale') ?? 'fr'
let locale = 'fr'

const i18n = createI18n({
    locale: locale,
    fallbackLocale: 'fr',
    messages: loadLocaleRessoureces()
})

export default i18n
