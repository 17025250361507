<template>
  <div class="col-12">
    <span class="h5 text-decoration-underline">{{ $t('tenants.exitInformations.newAddressHeader') }}</span>
  </div>

  <div class="mb-3 col-12">
    <label
      class="form-label d-block"
      for="input-appt"
    >
      {{ $t('tenants.exitInformations.appartementNumber') }}
    </label>

    <input
      id="input-appt"
      v-model="individu.appt"
      v-amsom-formater-upper-case
      type="text"
      class="form-control"
      :placeholder="$t('tenants.exitInformations.appartementNumber')"
      minlength="1"
      maxlength="4"
    >
  </div>

  <div class="mb-3 col-3">
    <label
      class="form-label d-block"
      for="input-numRue"
    >
      {{ $t('tenants.exitInformations.streetNumber') }}
      <span class="text-danger">*</span></label>

    <input
      id="input-numRue"
      v-model.trim="individu.numRue"
      v-amsom-formater-upper-case
      required
      type="text"
      class="form-control"
      :placeholder="$t('tenants.exitInformations.streetNumber')"
      minlength="1"
      maxlength="5"
    >
  </div>

  <div class="mb-3 col-9">
    <label
      class="form-label d-block"
      for="input-adr1"
    >
      {{ $t('tenants.exitInformations.addressLine1') }}
      <span class="text-danger">*</span></label>

    <input
      id="input-adr1"
      v-model="individu.adr1"
      v-amsom-formater-upper-case
      type="text"
      class="form-control"
      required
      :placeholder="$t('tenants.exitInformations.addressLine1Placeholder')"
      minlength="1"
      maxlength="30"
    >
  </div>

  <div class="mb-3 col-12">
    <label
      class="form-label d-block"
      for="input-adr2"
    >
      {{ $t('tenants.exitInformations.addressLine2') }}
    </label>

    <input
      id="input-adr2"
      v-model="individu.adr2"
      v-amsom-formater-upper-case
      type="text"
      class="form-control"
      :placeholder="$t('tenants.exitInformations.addressLine2Placeholder')"
      minlength="1"
      maxlength="30"
    >
  </div>

  <div class="mb-3 col-12">
    <label
      class="form-label d-block"
      for="input-adr3"
    >{{ $t('tenants.exitInformations.additionalAddressInfo') }}</label>

    <input
      id="input-adr3"
      v-model="individu.adr3"
      v-amsom-formater-upper-case
      type="text"
      class="form-control"
      :placeholder="$t('tenants.exitInformations.additionalAddressInfoPlaceholder')"
      minlength="1"
      maxlength="33"
    >
  </div>

  <div class="mb-3 col-12 col-md-6">
    <label
      class="form-label d-block"
      for="input-lieuDit"
    >{{ $t('tenants.exitInformations.locality') }}</label>

    <input
      id="input-lieuDit"
      v-model="individu.lieuDit"
      v-amsom-formater-upper-case
      type="text"
      class="form-control"
      :placeholder="$t('tenants.exitInformations.locality')"
      minlength="1"
      maxlength="38"
    >
  </div>

  <autocomplete-city
    v-model="fullCommune"
    zip-code-required
    :placeholder="$t('tenants.exitInformations.city')"
    no-result-string="Cliquez ici pour indiquer une commune située en dehors de la France"
    maxlength="38"
    :pays-options="paysOptions"
    :required="true"
    :city-label="$t('tenants.exitInformations.city')"
  />
</template>

<script>
import {sortArray} from "@/js/array.js";
import {mapState} from "vuex";
import {normalize} from "@/js/text.js";
import AutocompleteCity from "@/components/AutocompleteCity.vue";

export default {
  name: "LocataireAdresseBlock",

  components: {
    AutocompleteCity,
  },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },

  emits: ["update:modelValue"],

  data() {
    return {
      individu: this.modelValue,
    };
  },

  computed: {
    ...mapState(['utilities']),

    fullCommune: {
      get() {
        if (
          (this.individu?.commune && this.individu?.cp &&
            this.individu?.pays)
        ) {
          return {
            city: {
              nom: this.individu?.commune,
              pays: this.individu?.pays,
            },

            cp: this.individu?.cp,
          };
        } else {
          return null;
        }
      },

      set(value) {
        this.individu.commune = value?.city?.nom;
        this.individu.cp = value?.cp;
        this.individu.pays = value?.city?.pays;
      },
    },

    paysOptions() {
      if (!this.utilities.pays) return [];

      let data = this.utilities.pays
        .filter((pays) => pays.id !== "FRA")
        .map((pays) => {
          return {id: pays.id, nom: normalize(pays.nom, false)};
        });

      return sortArray(data, "nom", true);
    }
  },

  watch: {
    individu: {
      handler(value) {
        this.$emit("update:modelValue", value);
      },

      deep: true,
    },

    // modelValue: {
    //   handler(value) {
    //     this.individu = value;
    //   },

    //   deep: true,
    // },
  },

  mounted() {
    this.$store.dispatch('utilities/loadPays');
  },
};
</script>
