<template>
  <div class="col-12 col-lg-8">
    <form
      v-if="registerPage.availableCommunication.mail && !registerFormInfos.showErrorBlock"
      @submit.prevent="validateMail()"
    >
      <div class="mb-2">
        <span>{{ $t('registerPage.askForMail') }}</span>

        <input
          v-model.trim="mailContrat"
          v-amsom-formater-lower-case
          class="form-control rounded-3 mt-3"
          type="email"
          inputmode="email"
          maxlength="100"
          :placeholder="$t('registerPage.monMail')"
          :pattern="regexMail"
          required
        >

        <small
          role="link"
          class="text-secondary text-decoration-underline d-block hoverable"
          style="font-size: smaller;"
          @click="registerPage.availableCommunication.mail = false; error = null"
        >{{ $t('registerPage.cantUseMail') }}</small>
      </div>

      <button
        type="submit"
        class="btn btn-success rounded-5 py-2 mt-4"
      >
        {{ $t('common.nextStep') }}
      </button>
    </form>

    <div v-else-if="registerPage.availableCommunication.telephone && !registerFormInfos.showErrorBlock">
      <form
        v-if="!registerFormInfos.showConfirmPhoneBlock && !registerFormInfos.showErrorBlock"
        @submit.prevent="validateTel()"
      >
        {{ $t('registerPage.askForMobile') }}
        <input
          id="input-tel-contrat"
          v-model.trim="telContrat"
          v-amsom-formater-lower-case
          class="form-control rounded-3 mt-3"
          type="tel"
          inputmode="tel"
          :placeholder="$t('registerPage.monTel')"
          pattern="^(\+33|0033|0)(6|7)[0-9]{8}$"
          size="12"
          minlength="10"
          maxlength="12"
          required
        >

        <small
          role="link"
          class="text-secondary text-decoration-underline hoverable d-block"
          style="font-size: smaller;"
          @click="registerPage.availableCommunication.telephone = false; error = null;"
        >{{ $t('registerPage.cantUseMobile') }}</small>

        <small
          role="link"
          class="text-secondary text-decoration-underline hoverable d-block my-2"
          style="font-size: smaller;"
          @click="registerPage.availableCommunication.mail = true; error = null"
        >
          {{ $t('registerPage.backToMail') }}
        </small>

        <button
          class="btn btn-success rounded-5 py-2 mt-3"
          type="submit"
        >
          {{ $t('common.nextStep') }}
        </button>
      </form>

      <form
        v-else-if="registerFormInfos.showConfirmPhoneBlock && !registerFormInfos.showUpdateMailBlock && !registerFormInfos.showErrorBlock"
        @submit.prevent="validateCode()"
      >
        <span>{{ $t("registerPage.smsCodeSent") }}</span>

        <input
          id="input-code-confirmation"
          v-model.trim="confirmCodePhone"
          autocomplete="one-time-code"
          type="number"
          pattern="\d+"
          inputmode="numeric"
          :placeholder="$t('registerPage.smsCodeSentPlaceholder')"
          class="form-control rounded-3 mt-3"
          required
        >

        <div class="mt-2">
          <small
            v-if="waitSMS <= 0"
            role="link"
            class="text-secondary text-decoration-underline hoverable d-block"
            style="font-size: smaller;"
            @click="validateTel()"
          >{{ $t('registerPage.smsCodeMissing') }}</small>

          <div
            v-else
            class="text-success"
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
            />
            {{ $t('registerPage.smsCodeAvailableIn') }} {{ waitSMS/1000 }}s
          </div>
        </div>

        <small
          role="link"
          class="text-secondary text-decoration-underline hoverable d-block"
          style="font-size: smaller;"
          @click="registerPage.availableCommunication.mail = true; error = null"
        >
          {{ $t('registerPage.backToMail') }}
        </small>

        <button
          class="btn btn-success rounded-5 py-2 mt-3"
          type="submit"
        >
          {{ $t('common.nextStep') }}
        </button>
      </form>

      <form
        v-else-if="registerFormInfos.showUpdateMailBlock && !registerFormInfos.showErrorBlock"
        @submit.prevent="updateMail()"
      >
        <div class="text-primary text-decoration-underline fw-bold">
          {{ $t('registerPage.smsCodeCorrect') }}
        </div>

        <span> {{ $t('registerPage.registerMail') }}</span>

        <input
          id="input-mail-contrat"
          v-model.trim="mailContrat"
          v-amsom-formater-lower-case
          class="form-control rounded-3 mt-3"
          type="email"
          inputmode="email"
          :placeholder="$t('registerPage.monMail')"
          required
          aria-required="true"
          maxlength="100"
          :pattern="regexMail"
        >

        <small
          role="link"
          class="text-secondary text-decoration-underline d-block hoverable"
          style="font-size: smaller;"
          @click="registerPage.availableCommunication.telephone = false; error = null;"
        >
          {{ $t('registerPage.cantUseMail') }}
        </small>

        <button
          class="btn btn-success rounded-5 py-2 mt-3"
          type="submit"
        >
          {{ $t('common.nextStep') }}
        </button>
      </form>

      <div v-else>
        <register-error-block />
      </div>
    </div>

    <div v-else>
      <register-error-block />
    </div>

    <div
      v-if="error"
      class="text-center text-danger my-2"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import RegisterErrorBlock from "@/components/RegisterPage/ErrorBlock.vue";

export default {
  name: "RegisterStep2Block",
  components: {RegisterErrorBlock},

  data() {
    return {
      mailContrat: null,
      telContrat: null,
      confirmCodePhone: null,

      registerFormInfos: {
        showConfirmPhoneBlock: false,
        showUpdateMailBlock: false,
        showErrorBlock: false
      },

      regexMail: process.env.VUE_APP_REGEX_EMAIL,

      error: null,
      waitSMS: 0
    }
  },


  computed: {
    ...mapState(['registerPage']),

    registerStep: {
      get() {
        return this.registerPage.registerStep
      },

      set(value) {
        this.$store.commit('registerPage/setRegisterStep', value)
      }
    }
  },

  watch: {
    'waitSMS': function (newvalue) {
      if (newvalue >= 1000) {
        setTimeout(() => {
          this.waitSMS = newvalue - 1000
        }, 1000)
        return;
      }

      if (newvalue < 1000) {
        setTimeout(() => {
          this.waitSMS = 0
        }, newvalue)
        return;
      }

      return;
    }
  },

  methods: {
    validateMail() {
      const self = this

      this.$store.dispatch('registerPage/checkMailForContrat', {
        contrat: this.registerPage.contrat,
        mail: this.mailContrat
      })
        .then(() => {
          self.error = null
          self.$store.commit('registerPage/setMail', self.mailContrat)
          self.registerStep++
        })
        .catch((error) => {
          self.error = error
        })
    },

    validateTel() {
      const self = this

      this.$store.dispatch('registerPage/checkTelForContrat', {
        contrat: this.registerPage.contrat,
        telephone: this.telContrat
      })
        .then(() => {
          self.error = null
          self.registerFormInfos.showConfirmPhoneBlock = true
          this.waitSMS = process.env.VUE_APP_TTL_SMS
        })
        .catch((error) => {
          self.error = error
        })
    },

    validateCode() {
      const self = this

      this.$store.dispatch('registerPage/checkCodeTel', {
        code: this.confirmCodePhone,
        telephone: this.telContrat
      })
        .then(() => {
          self.error = null
          self.registerFormInfos.showUpdateMailBlock = true
          self.mailContrat = null
        })
        .catch((error) => {
          self.error = error
        })
    },

    updateMail() {
      const self = this

      this.$store.dispatch('registerPage/updateMail', {
        code: this.confirmCodePhone,
        telephone: this.telContrat,
        mail: this.mailContrat
      })
        .then(() => {
          self.error = null
          self.$store.commit('registerPage/setMail', self.mailContrat)
          self.registerStep++
        })
        .catch((error) => {
          self.error = error
        })
    }
  }
}
</script>

<style scoped>
/* Disable arrow from input[number] */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
