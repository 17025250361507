<template>
  <form
    @submit.prevent="onSubmit"
  >
    <div
      class="row py-2 gy-3 justify-content-center"
    >
      <div
        class="px-1"
        :class="[enqueteContrat.type==='SLS' ? 'col-12 col-md-5' : 'col-12']"
      >
        <fiscalite-revenus-block
          v-model="fiscalite"
          :available-individus="availableIndividusForFiscalite"
          @file-loading="updateFileLoading"
        />
      </div>

      <div
        v-if="enqueteContrat.type==='SLS'"
        class="col-12 col-md-5 px-1"
      >
        <fiscalite-baisse-block
          v-model="fiscalite"
          @file-loading="updateFileLoading"
        />
      </div>

      <div
        class="text-start col-6"
      >
        <button
          v-if="canPrev"
          type="button"
          class="btn btn-primary rounded-5"
          :disabled="fileLoadingCount > 0"
          @click="previous"
        >
          {{ $t("common.previous") }}
        </button>
      </div>

      <div
        class="text-end col-6"
      >
        <button
          type="submit"
          class="btn btn-success rounded-5"
          :disabled="fileLoadingCount > 0"
        >
          {{ $t("common.validate") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>

import FiscaliteRevenusBlock from "@/components/EnqueteEntryPage/DataBlocks/FiscaliteRevenusBlock.vue";
import FiscaliteBaisseBlock from "@/components/EnqueteEntryPage/DataBlocks/FiscaliteBaisseBlock.vue";
import {mapState, mapGetters} from "vuex";
import { toast } from "vue3-toastify";

export default {
  name: "FiscaliteFoyerEnqueteBlock",
  components: {FiscaliteBaisseBlock, FiscaliteRevenusBlock},

  props: {
    canPrev: {
      type: Boolean,
      default: true
    }
  },

  emits: ['prev', 'next'],

  data() {
    return {
      fiscalite: {
        revenusIndividus:{},
        rfr: null
      },

      fileLoadingCount: 0
    }
  },

  computed: {
    ...mapState(['enquete']),

    ...mapGetters({
      getRejetsGrouped: 'enquete/getRejetsGrouped',
    }),

    enqueteContrat() {
      return this.enquete.enqueteContrat;
    },

    availableIndividusForFiscalite() {
      if(!this.enquete.enqueteIndividus)
        return [];

      let individus = Array.from(this.enquete.enqueteIndividus).filter(individu => !individu.dateDepart)

      // Trier les locataires selon le type et, en cas d'égalité de type, selon l'ancienneté
      individus.sort((a, b) => {
        // Tri par type : signataire, cosignataire, occupant
        const typeOrder = {
          signataire: 1,
          cosignataire: 2,
          occupant: 3
        }


        if (typeOrder[a.type] !== typeOrder[b.type]) {
          return typeOrder[a.type] - typeOrder[b.type];
        } else {
          // Si les types sont identiques, tri par la propriété "debut"
          // if (a.debut !== b.debut) {
          //     return a.debut - b.debut;
          // }
          if (a.dateNaissance !== b.dateNaissance) {
            return a.dateNaissance - b.dateNaissance;
          }
          return b.idIndividu - a.idIndividu;
        }
      });

      return individus
    },

    currentRejets() {
      if(!this.getRejetsGrouped || !this.getRejetsGrouped['contrat']) return null

      return this.getRejetsGrouped['contrat']
    },
  },

  mounted() {
    for(let individu of this.availableIndividusForFiscalite){
      if(individu?.revenusAnnuels)
        this.fiscalite.revenusIndividus[individu.id] = individu.revenusAnnuelsCorriges ?? individu.revenusAnnuelsModifies ?? individu.revenusAnnuels
    }
    this.fiscalite.rfr = this.enqueteContrat.rfr
  },

  methods: {
    previous() {
      if(this.fileLoadingCount > 0) return;
      this.$emit('prev')
    },

    onSubmit() {
      if(this.fileLoadingCount > 0) return;

      let data = Object.assign({}, this.fiscalite);

      data.idContrat= this.enqueteContrat.idContrat
      data.idEnquete= this.enqueteContrat.idEnquete

      this.$store.dispatch('enquete/submitFiscalite', data)
        .then(() => {
          this.$emit('next')
        })
        .catch((error) => {
          toast.error(error);
        })
        .finally(()=>{
        })
    },

    updateFileLoading(value) {
      if (value) this.fileLoadingCount++;
      else this.fileLoadingCount--;
    },

  },
}
</script>
