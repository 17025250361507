<template>
  <div
    v-for="(item, idx) in config"
    :key="idx"
    class="row placeholder-wave mt-1"
  >
    <div
      class="placeholder"
      :class="[defaultClass, 'col-'+item, centered?'mx-auto':'']"
    />
  </div>
</template>

<script>
export default {
  name: 'AmsomSkeleton',

  props:{
    /**
     * @type {Array}
     * @description Nombre et taille (col-*) des placeholders
     * @default [8, 10, 12]
     */
    config:{
      type: Array,
      default: () => [8, 10, 12],
    },

    centered:{
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      defaultClass: 'text-gray rounded-2',
    }
  },

}
</script>

<style lang="scss" scoped>

</style>
