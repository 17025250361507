<template>
  <!-- Fixe -->
  <div
    id="input-group-fixe"
    class="col-12 col-md-6"
    role="group"
  >
    <label
      for="input-fixe"
      class="form-label d-block"
    >
      {{ $t('tenants.informations.landlinePhone') }}
      <span
        v-if="requiredFixe"
        class="text-danger"
      >*</span>
    </label>

    <input
      id="input-fixe"
      v-model.trim="individu.fixe"
      class="form-control"
      name="telFixe"
      type="tel"
      inputmode="tel"
      :placeholder="$t('tenants.informations.landlinePhonePlaceholder')"
      :pattern="regexFixe"
      :required="requiredFixe"
    >
  </div>

  <!-- Portable -->
  <div
    id="input-group-portable"
    class="col-12 col-md-6"
    role="group"
  >
    <label
      for="input-portable"
      class="form-label d-block"
    >
      {{ $t('tenants.informations.mobilePhone') }}
      <span
        v-if="requiredMobile"
        class="text-danger"
      >*</span>
    </label>

    <input
      id="input-portable"
      v-model.trim="individu.mobile"
      class="form-control"
      name="telMobile"
      type="tel"
      inputmode="tel"
      :placeholder="$t('tenants.informations.mobilePhonePlaceholder')"
      :pattern="regexMobile"
      :required="requiredMobile"
    >
  </div>

  <!-- Mail -->
  <div
    id="input-group-mail"
    class="col-12"
    role="group"
  >
    <label
      for="input-mail"
      class="form-label d-block"
    >
      {{ $t('tenants.informations.mail') }}
      <span
        v-if="requiredMail"
        class="text-danger"
      >*</span>
    </label>

    <input
      id="input-mail"
      v-model.trim="individu.mail"
      v-amsom-formater-lower-case
      class="form-control"
      name="mail"
      type="email"
      inputmode="email"
      :placeholder="$t('tenants.informations.mailPlaceholder')"
      :pattern="regexMail"
      :required="requiredMail"
    >
  </div>
</template>

<script>
export default {
  name: "LocataireContactBlock",

  props: {
    modelValue: {
      type: Object,
      required: true,
    },

    requiredFixe: {
      type: Boolean,
      default: false,
    },

    requiredMobile: {
      type: Boolean,
      default: false,
    },

    requiredMail: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update:modelValue"],

  data() {
    return {
      regexMail: process.env.VUE_APP_REGEX_EMAIL,
      regexMobile: process.env.VUE_APP_REGEX_MOBILE,
      regexFixe: process.env.VUE_APP_REGEX_FIXE,

      individu: this.modelValue,
    };
  },

  watch: {
    individu: {
      handler(value) {
        this.$emit("update:modelValue", value);
      },

      deep: true,
    },

    // modelValue: {
    //   handler(value) {
    //     this.individu = value;
    //   },

    //   deep: true,
    // },
  },
};
</script>
