<template>
  <div class="card w-100">
    <div class="card-body card-text">
      <div
        v-for="s in stepsToDisplay"
        :key="s.numero"
      >
        <div class="row">
          <div class="col-3 col-md-2 text-center">
            <div
              v-if="s.etatChecked.includes(etatEnquete)"
              class="avatar bg-success text-white fs-5 shadow rounded-circle mx-auto"
            >
              <font-awesome-icon icon="fa-solid fa-check" />
            </div>

            <div
              v-else
              class="avatar text-white fs-5 shadow rounded-circle mx-auto"
              :class="['bg-' + s.variant]"
            >
              <small class="text-light"><font-awesome-icon :icon="s.icon" /></small>
            </div>

            <p
              class="p-0 m-0"
              :class="[s.etatCurrentStep == etatEnquete ? 'fw-bold' : '']"
            >
              Étape {{ s.numero }}
            </p>

            <div
              v-if="s.numero != stepsToDisplay.length"
              class="borderBetween py-3 my-1 mx-auto"
              :class="[s.etatChecked.includes(etatEnquete) ? 'valid' : 'opacity-50']"
            />
          </div>

          <div class="col-9 col-md-10 fs-3">
            <span
              :class="
                s.etatCurrentStep == etatEnquete
                  ? 'text-decoration-underline'
                  : ''
              "
            >{{ s.texte }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "WorkflowBlock",

  data() {
    return {};
  },

  computed: {
    ...mapState(["enquete"]),

    enqueteContrat() {
      return this.enquete.enqueteContrat;
    },

    etatEnquete() {
      return this.enqueteContrat?.etat;
    },

    stepsToDisplay() {
      return this.steps.filter((s) =>
        s.etatDisplayed.includes(this.etatEnquete)
      );
    },

    steps() {
      let steps = [
        {
          numero: 1,
          texte: this.$t('surveyHome.informationStep'),
          slsOnly: false,
          etatChecked: ["E", "V", "D", "S", "I", "F"], // Liste les états où l'étape apparaît cochée
          etatDisplayed: ["E", "V", "D", "S", "I", "F"], // Liste les états où l'étape apparaît
          etatCurrentStep: "N", // Etat correspondant à cette étape
          variant: "primary",
          icon: "fa-solid fa-pen",
        },
        {
          numero: 2,
          texte: this.$t('surveyHome.treatmentStep'),
          slsOnly: false,
          etatChecked: ["V", "S", "D", "F"],
          etatDisplayed: ["E", "V", "D", "S", "F"],
          etatCurrentStep: "E",
          variant: "primary",
          icon: "fa-solid fa-file-circle-question",
        },
        {
          numero: 2,
          texte: this.$t('surveyHome.correctionWaitingStep'),
          slsOnly: false,
          etatChecked: [],
          etatDisplayed: ["I"],
          etatCurrentStep: "I",
          variant: "danger",
          icon: "fa-solid fa-file-circle-exclamation",
        },
        {
          numero: 3,
          texte: this.$t('surveyHome.validationStep'),
          slsOnly: false,
          etatChecked: ["V", "D", "S"],
          etatDisplayed: ["E", "V", "D", "S", "I", "F"],
          etatCurrentStep: "F",
          variant: "primary",
          icon: "fa-solid fa-file-circle-check",
        }
      ];

      if (this.enqueteContrat.type === "SLS") {
        steps.push(
          {
            numero: 4,
            texte: this.$t('surveyHome.amountNotCalcultaed'),
            slsOnly: true,
            etatChecked: ["D"],
            etatDisplayed: ["E", "V", "D", "I", "F"],
            etatCurrentStep: "D",
            variant: "primary",
            icon: "fa-solid fa-euro-sign",
          },
          {
            numero: 4,
            texte: this.$t('surveyHome.amountCalcultaed', {'montant': (this.enqueteContrat?.slsDefinitif?.toFixed(2) ?? 0)}),
            slsOnly: true,
            etatChecked: ["S"],
            etatDisplayed: ["S"],
            etatCurrentStep: "S",
            variant: "primary",
            icon: "fa-solid fa-euro-sign",
          });
      }

      return steps;
    },
  },

  mounted() {},
  methods: {},
};
</script>

<style scoped>
.borderBetween {
  border: 2px solid var(--bs-secondary);
  background-color: var(--bs-secondary);
  opacity: 0.5;
  width: 1px;
}
.borderBetween.valid {
  border-color: var(--bs-success);
  background-color: var(--bs-success);
}
</style>
