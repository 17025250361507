<template>
  <div class="card">
    <div class="card-body card-text">
      <h5>{{ $t('workflowBlock.header') }}</h5>

      <p>
        {{ processList[process].description }}
      </p>

      <div class="pt-4">
        <div
          v-for="s in processList[process].steps"
          :key="s.numero"
          class="d-flex"
        >
          <div>
            <div
              class="avatar bg-secondary text-white fs-5 shadow rounded-circle position-relative"
              style="z-index: 10"
            >
              {{ s.numero }}
            </div>

            <div
              v-if="s.numero !== processList[process].steps.length"
              class="line mx-auto position-relative"
              style="min-height: 50px; height: 100%; z-index: 1"
            />
          </div>

          <div class="pt-1 ps-2 pb-3">
            <span class="lead mt-1">{{ s.texte }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "WorkflowAssuranceBlock",
  props: ['process'],

  computed: {
    processList() {
      return {
        assurance: {
          description: this.$t('insuranceWorkflow.description'),
          steps: [
            {
              numero: 1,
              texte: this.$t('insuranceWorkflow.step1'),
            },
            {
              numero: 2,
              texte: this.$t('insuranceWorkflow.step2'),
            },
            {
              numero: 3,
              texte: this.$t('insuranceWorkflow.step3'),
            }
          ]
        },

        prelevementSubscription: {
          description: this.$t('prelevementWorkflow.subscribe.description'),
          steps: [
            {
              numero: 1,
              texte: this.$t('prelevementWorkflow.subscribe.step1'),
            },
            {
              numero: 2,
              texte: this.$t('prelevementWorkflow.subscribe.step2'),
            },
            {
              numero: 3,
              texte: this.$t('prelevementWorkflow.subscribe.step3'),
            }
          ]
        },

        prelevementEdition: {
          description: this.$t('prelevementWorkflow.edition.description'),
          steps: [
            {
              numero: 1,
              texte: this.$t('prelevementWorkflow.edition.step1'),
            },
            {
              numero: 2,
              texte: this.$t('prelevementWorkflow.edition.step2'),
            },
            {
              numero: 3,
              texte: this.$t('prelevementWorkflow.edition.step3'),
            }
          ]
        },
      }
    }
  },
}
</script>

<style scoped>
.line {
  background-color: var(--bs-secondary);
  opacity: 0.5;
  width: 3px;
}
</style>
