<template>
  <div
    id="sollicitationList"
    class="card no-scroll-bar"
  >
    <div class="card-body">
      <div
        v-if="loading"
        class="flex-grow-1"
      >
        <amsom-skeleton :config="[12, 10, 12]" />
      </div>

      <div
        v-else
        class="card-text"
      >
        <h5>{{ showInactive ? $t('requestList.defaultHeader') : $t('requestList.headerForCurrent') }}</h5>

        <div v-if="sollicitationsShowed.length===0">
          {{ showInactive ? $t('requestList.noRequestMessage') : $t('requestList.noCurrentRequestMessage') }}
          <div
            v-if="sollicitationList.length>0"
            class="mt-3"
          >
            <button
              class="btn btn-success rounded-5"
              @click="$router.push({ name: 'sollicitation' })"
            >
              {{ $t('requestList.showAll') }}
            </button>
          </div>
        </div>

        <div id="sollicitation-list">
          <div
            v-for="(sollicitation, index) in sollicitationsShowed"
            :key="index"
            role="button"
            class="d-flex pb-1 sollicitationItem rounded-3 ps-1"
            :class="[{'bg-gray bg-opacity-50 my-1': !sollicitation.actif}]"
            @click="openDetail(sollicitation.id)"
          >
            <span class="border border-1 border-gray rounded flex-shrink-1 me-2 my-2" />

            <div>
              <div
                class="badge rounded-5 bg-primary text-gray shadow"
              >
                <font-awesome-icon
                  :icon="['fa-solid', sollicitation.icone??'fa-gear']"
                  class="pe-1"
                />

                <small>{{ sollicitation.type }}</small>
              </div>

              <div><strong>{{ $t('requestList.requestTitle', {date: sollicitation.date}) }}</strong></div>

              <small
                v-if="sollicitation.actif"
                class="text-muted"
                style="transform: translateY(-10px);"
              >
                {{ $t('requestList.lastMessage', {eleapsedTime: sollicitation.lastMessage}) }}
              </small>

              <small
                v-else
                class="text-muted"
              >
                {{ $t('requestList.closedFrom', {eleapsedTime: sollicitation.lastMessage}) }}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import AmsomSkeleton from '../UI/AmsomSkeleton.vue'

export default {
  name: 'SollicitationList',

  components: {
    AmsomSkeleton
  },

  props: {
    showInactive: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapState(['sollicitation']),
    ...mapGetters({'selectedContratId': "user/getSelectedContrat"}),

    sollicitationsShowed() {
      return this.sollicitationList.filter(sollicitation => sollicitation.actif || this.showInactive)
    },

    loading() {
      return this.sollicitation.loading > 0
    },

    sollicitationList() {
      return this.sollicitation.sollicitations;
    }
  },

  watch: {
    'selectedContratId': function () {
      this.$store.commit('sollicitation/resetState')
      this.loadDataOfContrat()
    },
  },

  mounted() {
    this.loadDataOfContrat()
  },

  methods: {
    loadDataOfContrat() {
      if (this.selectedContratId) {
        this.$store.dispatch('sollicitation/getSollicitationsByContratId', this.selectedContratId)
      }
    },

    openDetail(id) {
      this.$router.push({name: 'sollicitationDetail', params: {sollicitationId: id}})
    }
  }
}
</script>

<style scoped>
#sollicitationList {
  overflow-y: scroll;
}

.sollicitationItem:hover {
  background-color: var(--bs-primary) !important;
  color: var(--bs-gray) !important;
  margin-left: 0.5em;
  transition: all 0.15s ease-in-out;
}

.sollicitationItem:hover .text-muted {
  color: var(--bs-gray) !important;
}

.sollicitationItem:hover .badge {
  background-color: var(--bs-gray) !important;
  color: var(--bs-primary) !important;
  transition: all 0.15s ease-in-out;
}
</style>
