import store from "@/store";
import router from "@/router";
import pageUtils from "@/js/pageUtils.js";

const firebaseSetup = function () {
    document.addEventListener(
        "deviceready",
        () => {
            cordova.plugins.firebase.messaging.onMessage(function (payload) {
                // handleFirebaseNotification(payload);
            });

            cordova.plugins.firebase.messaging.onBackgroundMessage(function (payload) {
                document.addEventListener(
                    "deviceready",
                    () => {
                        handleFirebaseNotification(payload);
                    }, false);
            });

            cordova.plugins.firebase.messaging.onTokenRefresh(function () {
                console.log("Device token updated");
                //todo
                // alert('token refresh')
            });
        }, false)
}

const handleFirebaseNotification = function (payload) {
    if (payload.query) {
        let query = JSON.parse(payload.query);
        if (query.idNotification && query.idContrat) {
            store.dispatch('notification/readNotification', { idContrat: query.idContrat, idNotification: query.idNotification })
            store.commit('user/setSelectedContrat', query.idContrat)
        }

        if (query?.route) {
            let route = JSON.parse(query?.route);
            router.push(route).then(() => {
                // setTimeout(() => {
                //     pageUtils.autoScroll("body-block", "end");
                // }, 500);
            });
        }
    }
}

const requestPermission = function () {
    document.addEventListener(
        "deviceready",
        () => {
            cordova.plugins.firebase.messaging.requestPermission({ forceShow: true })
            window.localStorage.setItem("fcmAsked", "true");
        }, false)
}

export default { firebaseSetup, requestPermission, handleFirebaseNotification }
