<template>
  <div class="container-fluid py-2 px-4">
    <amsom-overlay
      :loading="isLoading"
      class="mx-auto"
    >
      <div class="row gy-2">
        <h5 class="mb-1 ps-3">
          {{ $t('contactPage.amsomContact') }}
        </h5>

        <div
          v-for="contact in partenaireList"
          :key="contact.id"
          class="col-12 col-md-6 col-lg-4"
        >
          <contact-item :contact="contact" />
        </div>
      </div>

      <div class="row mt-3 gy-2">
        <h5 class="mb-1 ps-3">
          {{ $t('contactPage.enterprise') }}
        </h5>

        <div
          v-for="contact in entrepriseList"
          :key="contact.id"
          class="col-12 col-md-6 col-lg-4"
        >
          <contact-item :contact="contact" />
        </div>
      </div>
    </amsom-overlay>
  </div>
</template>

<script>
import ContactItem from "@/components/ContactPage/ContactItem.vue";
import { mapGetters } from "vuex";
import entrepriseImage from '@/assets/images/contactPage/entreprise.svg'
import crcImage from '@/assets/images/contactPage/crc.svg'
import gardienImage from '@/assets/images/contactPage/gardien.svg'
import AmsomOverlay from "@/components/UI/AmsomOverlay.vue";

export default
  {
    name: "ContactPage",

    components: {
      ContactItem,
      AmsomOverlay
    },

    data() {
      return {
        moduleId: null,
        //moduleId:'000ALB0041',
        innerWidth: null,

        entrepriseImage: entrepriseImage,
        crcImage: crcImage,
        gardienImage: gardienImage,
      }
    },

    computed: {
      ...mapGetters({
        isLoading: 'contact/listLoading',
        contacts: 'contact/getContactList',
        getSelectedContrat: 'user/getSelectedContrat',
        mainModule: 'moduleContrat/getMainModule',
      }),

      partenaireList() {
        return this.contacts.filter(contact => contact.type === 'partenaire').map(agence => {
          agence.photo = agence.idNature === 'CRC' ? this.crcImage : this.gardienImage
          return agence
        })
      },

      entrepriseList() {
        return this.contacts.filter(contact => contact.type === 'entreprise').map(agence => {
          agence.photo = this.entrepriseImage
          return agence
        })
      },

    },

    watch: {
      'getSelectedContrat': function () {
        this.initComponent()
      },
    },

    mounted() {
      this.$store.commit('topbar/setBreadcrumb',
                         [
                           { icon: 'fa-solid fa-house', to: { name: 'home' } },
                           { text: this.$t('breadcrumb.contacts'), active: true }
                         ]
      )

      this.initComponent();
    },

    methods: {
      initComponent() {
        if (this.getSelectedContrat) {
          this.$store.dispatch('moduleContrat/getModuleByContratId', this.getSelectedContrat).then(() => {
            this.$store.dispatch('contact/getContactsByModule', this.mainModule.idModule)
          })
        }
      },
    },


  }
</script>
