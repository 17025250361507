<template>
  <div
    class="container-fluid py-2 px-4"
  >
    <div class="row gy-3">
      <div class="col-12 d-lg-none">
        <cb-widget style="min-height: 20vh;" />
      </div>

      <div class="col-12 col-lg-8">
        <lignes-compte-panel
          style="max-height: 80vh;"
          class="d-none d-lg-block"
        />

        <lignes-compte-panel
          style="max-height: 48vh;"
          class="d-block d-lg-none"
        />
        <!-- Désactivation suite à un atelier DAP -->
        <!-- <solde-positif-widget v-if="solde>0" /> -->
      </div>

      <div class="col-12 col-lg-4">
        <div class="row gy-3">
          <div class="col-12 d-none d-lg-block">
            <cb-widget style="min-height: 20vh;" />
          </div>

          <div class="col-12">
            <rib-widget
              id="rib-widget"
              style="min-height: 20vh;"
            />
          </div>

          <div class="col-12">
            <!--            <document-list -->
            <!--              style="max-height: 35vh;" -->
            <!--              titre="Mes derniers avis d'échéance" -->
            <!--              :length="-1" -->
            <!--              :categories="[ -->
            <!--                'Avis d\'échéance', -->
            <!--                'Avis d\'échéance complémentaires' -->
            <!--              ]" -->
            <!--            /> -->
            <documents-block />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CbWidget from '@/components/ComptePage/CbWidget.vue'
import RibWidget from '@/components/ComptePage/RibWidget.vue'
import LignesComptePanel from '@/components/ComptePage/LignesComptePanel.vue'
import {mapGetters, mapState} from "vuex";
import DocumentsBlock from "@/components/ComptePage/DocumentsBlock.vue";

export default {
  name: 'ComptePage',

  components: {
    DocumentsBlock,
    CbWidget,
    RibWidget,
    LignesComptePanel,
  },

  computed: {
    ...mapGetters({
      selectedContratId: 'user/getSelectedContrat',
    }),
  },

  watch: {
    'selectedContratId': function () {
      this.loadDataOfContrat()
    }
  },

  mounted() {
    this.$store.commit('topbar/setBreadcrumb',
                       [
                         {icon: 'fa-solid fa-house', to: {name: 'home'}},
                         {text: this.$t('breadcrumb.account'), active: true}
                       ]
    )

    this.loadDataOfContrat()
  },

  methods: {
    loadDataOfContrat() {
      if (this.selectedContratId) {
        this.$store.dispatch('contrat/loadPrelevementInfos', this.selectedContratId)
        this.$store.dispatch('ged/loadGedMensuelByContratId', this.selectedContratId)
      }
    }
  }
}
</script>
