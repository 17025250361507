<template>
  <div>
    <div class="card">
      <div
        v-if="!!contrat.loading || contrat.individusCurrentContrat === null"
        class="card-body card-text"
      >
        <amsom-skeleton />
      </div>

      <div
        v-else
        class="card-body card-text"
      >
        <!-- En tête -->
        <transition
          name="slide-fade"
          mode="out-in"
        >
          <!-- Création  -->
          <div
            v-if="currentLocataire || locatairePanel.mode === 'create'"
            class="d-flex justify-content-between align-items-center mb-2"
          >
            <font-awesome-icon
              role="button"
              icon="fa-solid fa-arrow-left"
              class="me-2"
              size="2x"
              @click="goBack()"
            />

            <template v-if="locatairePanel.mode !== 'view'">
              <div
                v-if="currentLocataire"
                class="text-end lh-sm"
              >
                <span class="fw-bold">
                  {{ (currentLocataire.nom ?? currentLocataire.nomNaissance).toUpperCase() }} <span class="text-capitalize">{{ currentLocataire.prenom.toLowerCase() }} </span>
                </span>

                <span
                  class="d-block text-success"
                >
                  {{ $te(('common.'+currentLocataire.type.toLowerCase())) ? $t(('common.'+currentLocataire.type.toLowerCase())) : currentLocataire.type }}
                </span>
              </div>

              <div
                v-else
                class="text-end lh-sm"
              >
                <span class="d-block fw-bold">
                  {{ $t('common.newTenant') }}
                </span>

                <span class="d-block text-success">
                  {{ $t('common.occupant') }}
                </span>
              </div>
            </template>
          </div>
          <!-- Liste -->
          <div v-else-if="locatairePanel.mode === 'list'">
            <h5>{{ $t('tenants.list.header') }}</h5>
          </div>
        </transition>
        <!-- Contenu -->
        <transition
          name="slide-fade"
          mode="out-in"
        >
          <div
            v-if="!currentLocataire && locatairePanel.mode === 'list'"
            class="no-scroll-bar"
            style="max-height: 70vh; overflow-y: auto;"
          >
            <div
              id="locataire-list"
              class="rounded pb-3"
            >
              <amsom-card
                v-for="locataire in locataires"
                :key="locataire.id"
                no-body
                class="my-1 lh-sm mx-2 "
                role="button"
                @click="selectLocataire(locataire.id)"
              >
                <div class="card-body card-text d-flex align-items-center ">
                  <div class="flex-grow-1">
                    <span class="fw-bold">
                      {{ (locataire.nom ?? locataire.nomNaissance).toUpperCase() }} <span class="text-capitalize">{{ locataire.prenom.toLowerCase() }} </span>
                    </span>

                    <div>
                      <!-- locataire dont les modifications sont en cours de traitement -->
                      <template v-if="!validLocataireState.includes(locataire.etat)">
                        <span
                          v-if="locataire.etat === 'N'"
                          class="text-danger"
                        >
                          {{ $t('tenants.modificationWaiting') }}
                        </span>

                        <span
                          v-else-if="locataire.etat === 'I'"
                          class="text-danger"
                        >
                          {{ $t('tenants.correctionWaiting') }}
                        </span>

                        <span
                          v-else
                          class="text-warning"
                        >
                          {{ $t('tenants.validationWaiting') }}
                        </span>
                      </template>
                      <!-- locataire présent -->
                      <span
                        v-else-if="!locataire.fin"
                        class="text-capitalize text-success locataire-type"
                      >
                        {{ $te(('common.'+locataire.type.toLowerCase())) ? $t(('common.'+locataire.type.toLowerCase())) : locataire.type }}
                      </span>
                      <!-- locataire sorti -->
                      <span
                        v-else
                        class="text-secondary"
                      >
                        <template v-if="!isLocataireAlreadyExited(locataire)">{{ $t('tenants.informations.departureDateComming', {date: unixToDateString(getLocataireDateSortie(locataire))}) }}</template>

                        <template v-else>{{ $t(locataire.titre === 'Mme' ? 'tenants.informations.departureDateWoman' : 'tenants.informations.departureDateMan', {date: unixToDateString(getLocataireDateSortie(locataire))}) }}</template>
                      </span>
                    </div>
                  </div>

                  <font-awesome-icon
                    icon="fa-solid fa-user-edit"
                    class="text-secondary opacity-25"
                    size="2x"
                  />
                </div>
              </amsom-card>
            </div>

            <div
              v-if="!currentContratInfos.hasExpired && !currentContratInfos.enqueteEnCours"
              class="pt-2 text-center bg-body"
            >
              <button
                id="btn-add-locataire"
                class="btn btn-success rounded-5"
                role="button"
                @click="createLocataire()"
              >
                <font-awesome-icon
                  icon="fa-solid fa-user-plus"
                  class="me-1"
                />
                {{ $t('tenants.list.addTenant') }}
              </button>
            </div>

            <div
              v-else-if="currentContratInfos.enqueteEnCours"
              class="text-end text-secondary"
            >
              <span>
                {{ $t('tenants.surveyInProgressWarning') }}
              </span>
            </div>
          </div>

          <div
            v-else-if="locatairePanel.mode === 'edit'"
            class="flex-grow-1 text-center mx-auto"
          >
            <edit-locataire-block :locataire="currentLocataire" />
          </div>

          <div
            v-else-if="locatairePanel.mode === 'correctEdit'"
            class="flex-grow-1 text-center mx-auto"
          >
            <edit-locataire-block
              :locataire="currentLocataire"
              :old-edited-locataire="editedLocataire"
            />
          </div>

          <div
            v-else-if="locatairePanel.mode === 'create'"
            class="flex-grow-1 text-center mx-auto"
          >
            <edit-locataire-block />
          </div>

          <div
            v-else-if="locatairePanel.mode === 'correctExit'"
            class="flex-grow-1 text-center mx-auto"
          >
            <exit-locataire-block
              :locataire="currentLocataire"
              :old-edited-locataire="editedLocataire"
            />
          </div>

          <div
            v-else-if="locatairePanel.mode === 'exit'"
            class="flex-grow-1 text-center mx-auto"
          >
            <exit-locataire-block :locataire="currentLocataire" />
          </div>

          <div
            v-else-if="locatairePanel.mode === 'view' && currentLocataire"
            class="flex-grow-1 text-center mx-auto"
          >
            <display-locataire-block
              :locataire="currentLocataire"
              :valid-locataire-state="validLocataireState"
              class="mt-3"
            />
          </div>

          <div
            v-else-if="locatairePanel.mode === 'confirm' && currentLocataire"
            class="flex-grow-1 text-center mx-auto"
          >
            <confirm-locataire-modification-block :locataire="currentLocataire" />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { unixToDateString } from "@/js/text.js";
import DisplayLocataireBlock from "@/components/ContratPage/LocatairePanel/DisplayLocataireBlock.vue";
import EditLocataireBlock from "@/components/ContratPage/LocatairePanel/EditLocataireBlock.vue";
import ExitLocataireBlock from "@/components/ContratPage/LocatairePanel/ExitLocataireBlock.vue";
import ConfirmLocataireModificationBlock
  from "@/components/ContratPage/LocatairePanel/ConfirmLocataireModificationBlock.vue";
import AmsomSkeleton from "../UI/AmsomSkeleton.vue";
import {AmsomCard} from "@amsom-habitat/ui"

export default {
  name: 'LocatairePanel',
  components: { ConfirmLocataireModificationBlock, ExitLocataireBlock, EditLocataireBlock, DisplayLocataireBlock, AmsomSkeleton, AmsomCard },

  data() {
    return {
      validLocataireState: ['C', 'D', 'A']
    }
  },

  computed: {
    ...mapState(['contrat', 'locatairePanel']),

    ...mapGetters({
      'getAvailableLocataires': "contrat/getAvailableLocataires",
      'currentLocataire': "locatairePanel/getCurrentLocataire",
      'getLocataireDateSortie': 'contrat/getLocataireDateSortie',
      'isLocataireAlreadyExited': 'contrat/isLocataireAlreadyExited'
    }),

    currentContratInfos() {
      return this.contrat.currentContratInfos
    },

    contratIsAlreadyExited() {
      return !!this.currentContratInfos?.dateSortie
    },

    locataires() {
      return this.getAvailableLocataires
    },

    editedLocataire() {
      let modification = this.locatairePanel.editedLocataire
      let individu = {}
      Object.assign(individu, this.currentLocataire)

      for (let key of Object.keys(modification)) {
        if (key !== 'id' && modification[key] !== null && modification[key] !== undefined) {
          individu[key] = modification[key]
        }
      }

      individu.idModification = modification.id

      return individu
    }
  },

  mounted() {
    this.selectLocataire(null)

  },

  methods: {
    unixToDateString,

    goBack() {
      if (['edit', 'exit'].includes(this.locatairePanel.mode)) {
        this.setMode('view')
        return
      }
      this.setMode('list')
      this.$store.commit('locatairePanel/setSelectedLocataireId', null)
    },

    selectLocataire(id) {
      if (id === null) {
        this.setMode('list')
        this.$store.commit('locatairePanel/setSelectedLocataireId', null)
        return
      }
      const selectedLocataire = this.locataires.find((locataire) => locataire.id === id)
      if (selectedLocataire) {
        if (selectedLocataire.etat !== 'N') {
          this.setMode('view')
        }
        else {
          this.setMode('confirm')
        }
        this.$store.commit('locatairePanel/setSelectedLocataireId', id)
      }
    },

    createLocataire() {
      this.setMode('create')
    },

    setMode(val) {
      this.$store.commit('locatairePanel/setMode', val)
    }
  }
}
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
#locataire-list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#locataire-list {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>
